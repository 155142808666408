import React from 'react'
import { PlinthDate } from '@oneplan/common/src/types'
import { calendarPlinthRow } from '@oneplan/common/src/testids'
import {
  CalendarPlinth,
  CalendarCellDayPlinthWrapper,
  CalendarCellDay,
} from './Calendar.styles'
import Tooltip from '../Tooltip'
import formatReadableDate from '../../utils/formatReadableDate'
import { PlinthProps } from '../../../types'
import useCalendarStore from '../../state/client/calendarStore'

const CalendarPlinthDays: React.FC<PlinthProps> = ({
  name,
  dates,
  isHidden,
}) => {
  const weekView = useCalendarStore(state => state.weekView)

  return (
    <>
      <CalendarPlinth
        data-testid={calendarPlinthRow}
        key={name}
        isHidden={isHidden}
        weekView={weekView}
      >
        {dates.map(
          ({
            date,
            label,
            plinthNumber,
            daysSinceStartOfFinancialYear,
          }: PlinthDate) => {
            return (
              <Tooltip
                key={date.toString()}
                label={`${formatReadableDate(date.toString())}${
                  label ? ` - ${label}` : ''
                }`}
              >
                <CalendarCellDayPlinthWrapper
                  startsAt={daysSinceStartOfFinancialYear}
                  weekView={weekView}
                  data-testid={name}
                >
                  <CalendarCellDay>{plinthNumber}</CalendarCellDay>
                </CalendarCellDayPlinthWrapper>
              </Tooltip>
            )
          }
        )}
      </CalendarPlinth>
    </>
  )
}

export default React.memo(CalendarPlinthDays)
