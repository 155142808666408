import React, { useState } from 'react'
import {
  sectionContainer,
  dashboardSections,
  sectionForm,
} from '@oneplan/common/src/testids'
import { SectionsContainer, SectionsWrapper } from './Sections.style'
import { SectionButtonWrapper } from '../../components/Buttons/Buttons.styles'
import Sidepanel from '../../components/Sidepanel'
import SectionData from './SectionData'
import SectionContextMenu from './SectionContextMenu'
import useSubsectionReorderStore from '../../state/client/subsectionReorder'
import Loader from '../../components/Loader'
import { ErrorBoundary, FormError } from '../../components/Error'

export const lazyAddSectionForm = () =>
  import('../../components/Forms/AddSectionForm')
const lazyReorder = () => import('./SectionReorder')

const AddSectionForm = React.lazy(lazyAddSectionForm)
const Reorder = React.lazy(lazyReorder)

const Sections: React.FC = () => {
  const [addIsOpen, toggleAdd] = useState(false)
  const isReorderMode = useSubsectionReorderStore(store => store.isReorderMode)
  const handleClose = () => toggleAdd(false)

  return (
    <>
      <SectionContextMenu onAddSectionClick={toggleAdd} />
      <SectionsWrapper data-testid={dashboardSections}>
        <SectionsContainer id={sectionContainer}>
          <React.Suspense fallback={<Loader />}>
            {isReorderMode ? <Reorder /> : <SectionData />}
          </React.Suspense>
        </SectionsContainer>
        <SectionButtonWrapper>
          <Sidepanel isOpen={addIsOpen} handleClose={handleClose}>
            <ErrorBoundary
              CustomErrorView={FormError}
              errorProps={{ handleClose }}
            >
              <React.Suspense fallback={<Loader />}>
                <AddSectionForm
                  handleClose={handleClose}
                  data-testid={sectionForm}
                />
              </React.Suspense>
            </ErrorBoundary>
          </Sidepanel>
        </SectionButtonWrapper>
      </SectionsWrapper>
    </>
  )
}

export default Sections
