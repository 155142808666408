import { gql } from 'graphql-request'

const GET_FILTER_VIEW_EXPORT = gql`
  query ExportFilterView(
    $financialYearId: String!
    $tagIds: [String]
    $sectionIds: [String]
    $subSectionIds: [String]
    $brandIds: [String]
    $colorIds: [String]
  ) {
    exportFilterView(
      financialYearId: $financialYearId
      tagIds: $tagIds
      sectionIds: $sectionIds
      subsectionIds: $subSectionIds
      brandIds: $brandIds
      colorIds: $colorIds
    ) {
      url
    }
  }
`

export default GET_FILTER_VIEW_EXPORT
