import styled from '@emotion/styled/macro'
import { ArrowRight, ZoomIn, ZoomOut } from '@jsluna/icons'
import { Select } from '@jsluna/react'

const Button = styled.button`
  background-color: transparent;
  border-color: transparent;
  border-style: solid;
  border-width: 0;
  border-bottom-width: 2px;
  font-family: 'MaryAnn';
  font-size: 20px;
  font-weight: bold;
  padding: 10px 20px;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.color.primary};
    border-color: ${({ theme }) => theme.color.primary};
  }
`

const ButtonQuarter = styled(Button)`
  display: none;

  @media (min-width: 1100px) {
    display: block;
  }
`

const ButtonArrow = styled.button`
  background-color: transparent;
  border-color: transparent;
  border-style: solid;
  border-width: 0;
  font-size: 20px;
  height: 40px;
  width: 40px;
  padding: 10px;
  cursor: pointer;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;

  &:hover {
    color: ${({ theme }) => theme.color.primary};
    background-color: ${({ theme }) => theme.color.grey};
  }
`

const Arrow = styled(ArrowRight)`
  height: 20px;
  transform: ${(props: { rotate: boolean }) =>
    props.rotate ? 'rotate(180deg)' : 'rotate(0)'};
`
const ZoomIns = styled(ZoomIn)`
  height: 20px;
  background-color: transparent;
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.color.primary};
    background-color: ${({ theme }) => theme.color.grey};
  }
`
const ZoomOuts = styled(ZoomOut)`
  height: 20px;
  background-color: transparent;
  margin-left: 10px;
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.color.primary};
    background-color: ${({ theme }) => theme.color.grey};
  }
`

const YearSelect = styled(Select)`
  &.ln-c-select {
    width: 135px;
    border: none;
    font-weight: bold;
    font-size: 22px;

    &:hover {
      border: none;
    }

    option:first-of-type {
      display: none;
    }
  }
`

export {
  Button,
  ButtonQuarter,
  ButtonArrow,
  Arrow,
  YearSelect,
  ZoomIns,
  ZoomOuts,
}
