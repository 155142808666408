import React from 'react'
import { LogoutSvg } from './Icon.styles'

const LogoutIcon = () => {
  return (
    <LogoutSvg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 13"
      aria-hidden
    >
      <defs>
        <path
          id="a"
          d="M9.289 0a.81.81 0 1 1 0 1.621H3.445c-.895 0-1.621.726-1.621 1.622v5.674c0 .896.726 1.622 1.621 1.622H9.29c.5 0 .863.391.863.802 0 .411-.363.819-.863.819H3.445A3.243 3.243 0 0 1 .203 8.917V3.243A3.243 3.243 0 0 1 3.445 0zm1.62 2.733a.81.81 0 0 1 1.147 0l2.866 2.866a.826.826 0 0 1 .217.393l.006.03c.01.049.014.1.014.15l-.003-.072a.815.815 0 0 1 .003.039v.033a.825.825 0 0 1-.02.182.807.807 0 0 1-.217.392l.061-.07a.815.815 0 0 1-.044.051l-.017.019-2.866 2.866a.81.81 0 0 1-1.147-1.147l1.482-1.482H6.323a.81.81 0 0 1-.804-.709l-.006-.102a.81.81 0 0 1 .81-.81l6.068-.001-1.482-1.482a.81.81 0 0 1-.078-1.055z"
        />
      </defs>
      <g fillRule="evenodd" transform="translate(.027 .053)">
        <mask id="b">
          <use xlinkHref="#a" />
        </mask>
        <use fillRule="nonzero" xlinkHref="#a" />
        <g mask="url(#b)">
          <path d="M-3-4h18.828v18.828H-3z" />
        </g>
      </g>
    </LogoutSvg>
  )
}

export default LogoutIcon
