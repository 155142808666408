import styled from '@emotion/styled/macro'

const Wrapper = styled.header`
  border-color: ${({ theme }) => theme.color.whiteSmoke};
  border-style: solid;
  border-bottom-width: 1px;
  display: flex;
  font-family: 'MaryAnn';
  font-size: 20px;
  font-weight: bold;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  width: 100%;
`

const Logo = styled.div`
  display: inline-block;
  min-width: 200px;
  max-width: 250px;
  width: 100%;
`

const Menu = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
`

export { Wrapper, Logo, Menu }
