const accountMenu = 'account-menu'
const accountMenuButton = 'account-menu-button'
const accountMenuExportButton = 'account-menu-export-button'
const accountMenuNewFeaturesButton = 'account-menu-new-features-button'

const buttonPulseExport = 'button-pulse-export'
const buttonQuarterExport = 'button-quarter-export'
const buttonChangeLogExport = 'button-changelog-export'
const quarterSelect = 'quarter-select'
const buttonClose = 'button-close'
const buttonSubmit = 'button-submit'
const buttonEdit = 'button-edit'
const buttonDelete = 'button-delete'
const buttonToggle = 'button-toggle'
const buttonTryAgain = 'button-try-again'

const genericErrorMessage = 'error-message'
const quarterSectionsErrorMessage = 'quarter-sections-error-message'

const addActivityForm = 'add-activity-form'
const activityTitle = 'actitvity-title'
const activityHrs = 'actitvity-hours'
const activityPublished = 'activity-published'
const activityDescription = 'activity-description'
const editActivityForm = 'edit-activity-form'
const keyActivityTestId = 'key-activity'
const keyActivityTitle = 'linked-key-activity-title'
const impActivityTitle = 'linked-imp-activity-title'
const activityGroup = 'activity-group'
const activitiesWrapper = 'activities-wrapper'

const subsectionForm = 'subsection-form'
const subsectionContainer = 'subsection-container-div'
const subsectionTitle = 'subsection-title'
const subsectionPrivate = 'subsection-private'
const subsectionDepartments = 'subsection-departments'
const subsectionBrands = 'subsection-brands'
const subsectionTitleInput = 'subsection-title-input'
const subsectionDescriptionInput = 'subsection-description-input'

const sectionContainer = 'section-container-div'
const sectionForm = 'add-section-form'
const sectionTitleInput = 'section-title-input'
const sectionDescriptionInput = 'section-description-input'
const sectionTitle = 'section-title'
const sectionWrapper = 'section-wrapper'
const tagFilterInput = 'tag-filter-input'
const tagActivityInput = 'tag-activity-input'
const yearSelectInput = 'year-select-input'
const keyActivityInput = 'key-activity-input'
const impActivityInput = 'key-activity-input'
const keyActivityMarker = 'key-activity-marker'
const sectionGroupSelect = 'sectionGroup'

const managerAddSection = 'manager-add-section'
const managerEditSection = 'manager-edit-section'
const managerAddSubsection = 'manager-add-subsection'
const managerEditSubsection = 'manager-edit-subsection'
const managerAddActivity = 'manager-add-activity'

const loadingState = 'loading-state'
const skeletonLoading = 'skeleton-loading'

const sidepanelContainer = 'sidepanel-container'
const sidepanelBackground = 'sidepanel-background'

const iconFrontArrow = 'icon-front-arrow'
const iconBackArrow = 'icon-back-arrow'
const iconPlus = 'icon-plus'
const dashboardSections = 'dashboard-sections'

const calendarSection = 'calendar-section'
const calendarDataError = 'calendar-data-error'
const calendarRowHeader = 'calendar-row-header'
const calendarCellDay = 'calendar-cell-day'
const calendarCellDate = 'calendar-cell-date'
const calendarHeadWrapper = 'calendar-head-wrapper'
const calendarPlinthRow = 'calendar-plinth-row'

const calendarPeriodsPin = 'calendar-periods-pin'

const plinthsArrowCollapse = 'plinths-arrow-collapse'

const activityTitleDiv = 'activity-title-div'
const activityButton = 'activity-button'

const confirmedAddActivity = 'confirmed-add-activity'
const confirmedEditActivity = 'confirmed-edit-activity'
const confirmedDeleteActivity = 'confirmed-delete-activity'
const confirmedEditSection = 'confirmed-edit-section'
const confirmedDeleteSection = 'confirmed-delete-section'

const confirmedAddSubsection = 'confirmed-add-subsection'
const confirmedEditSubsection = 'confirmed-edit-subsection'
const confirmedDeleteSubsection = 'confirmed-delete-subsection'

const confirmedAddSection = 'confirmed-add-section'

const activityDeleteWarning = 'activity-delete-warning'
const activityDetailsTitle = 'activity-details-title'
const activityDetailsDateDiv = 'activity-details-date'
const activityDetailsChangeLog = 'activity-details-changelog'
const activityChangelogTitle = 'activity-changelog-title'
const activityDetailsDescription = 'activity-details-description'
const activityDetailsBrands = 'activity-details-brands'
const activityDetailsTags = 'activity-details-tags'
const activityDetailsKeyActivity = 'activity-details-key-activity'
const activityDetailsImpActivity = 'activity-details-imp-activity'
const activityDetailsNonKeyActivities = 'activity-details-non-key-activities'

const buttonCollapse = 'button-collapse'
const accordion = 'accordion'
const noFilterFound = 'no-filter-found'
const noDataFound = 'no-data-found'
const showMoreBtn = 'show-more-button'
const filterSectionGroupSubTitle = 'filter-section-group-subtitle'
const checkedIcon = 'checked-icon'
const expandCloseIcon = 'expand-close-icon'
const expandOpenIcon = 'expand-open-icon'
const clearFilterButton = 'button-clear-filter'

const timelineDetails = 'timeline-details'

const contextMenu = 'context-menu'
const contextMenuTrigger = 'context-menu-trigger'
const contextSubsectionMenuTrigger = 'context-subsection-menu-trigger'

const contactsAccordion = 'contacts-accordion'
const changeLogAccordion = 'changelog-accordion'
const filterAccordion = 'filter-accordion'

const reorderButton = 'reorder-button'
const reorderApplyButton = 'reorder-apply-button'
const reorderCancelButton = 'reorder-cancel-button'
const dragIconButton = 'drag-icon-button'
const filterExportButton = 'filter-export-button'

const notificationMsg = 'notification-message'

const newFeaturesHeader = 'new-features-header'
const newFeatureNotification = 'newFeatureNotification'

const pulseViewTab = 'pulse-view-tab'
const quarterViewTab = 'quarter-view-tab'
const changeLogTab = 'change-log-tab'

const tagColorInput = 'tag-color-input'
export {
  accountMenu,
  accountMenuButton,
  accountMenuExportButton,
  accountMenuNewFeaturesButton,
  activityChangelogTitle,
  activityButton,
  activityDeleteWarning,
  activityDescription,
  activityDetailsBrands,
  activityDetailsChangeLog,
  activityDetailsDateDiv,
  activityDetailsDescription,
  activityDetailsTags,
  activityDetailsTitle,
  activityDetailsKeyActivity,
  activityDetailsImpActivity,
  activityDetailsNonKeyActivities,
  activityGroup,
  activityPublished,
  activitiesWrapper,
  activityTitle,
  activityHrs,
  activityTitleDiv,
  addActivityForm,
  subsectionForm,
  buttonChangeLogExport,
  buttonClose,
  buttonCollapse,
  buttonDelete,
  buttonEdit,
  buttonPulseExport,
  buttonQuarterExport,
  buttonSubmit,
  buttonToggle,
  calendarDataError,
  calendarSection,
  calendarRowHeader,
  calendarCellDay,
  calendarCellDate,
  calendarPlinthRow,
  calendarHeadWrapper,
  calendarPeriodsPin,
  checkedIcon,
  clearFilterButton,
  confirmedAddActivity,
  confirmedAddSection,
  confirmedAddSubsection,
  confirmedDeleteActivity,
  confirmedDeleteSection,
  confirmedDeleteSubsection,
  confirmedEditActivity,
  confirmedEditSection,
  confirmedEditSubsection,
  contactsAccordion,
  changeLogAccordion,
  contextMenu,
  contextSubsectionMenuTrigger,
  dashboardSections,
  dragIconButton,
  editActivityForm,
  expandCloseIcon,
  expandOpenIcon,
  accordion,
  iconBackArrow,
  iconFrontArrow,
  keyActivityTestId,
  keyActivityTitle,
  impActivityTitle,
  loadingState,
  skeletonLoading,
  managerAddActivity,
  managerAddSection,
  managerAddSubsection,
  managerEditSection,
  managerEditSubsection,
  noDataFound,
  noFilterFound,
  plinthsArrowCollapse,
  quarterSelect,
  sectionContainer,
  sectionForm,
  sectionDescriptionInput,
  sectionTitle,
  sectionTitleInput,
  sectionWrapper,
  showMoreBtn,
  sectionGroupSelect,
  filterSectionGroupSubTitle,
  sidepanelContainer,
  sidepanelBackground,
  subsectionBrands,
  subsectionContainer,
  subsectionDepartments,
  subsectionDescriptionInput,
  subsectionPrivate,
  subsectionTitle,
  subsectionTitleInput,
  tagFilterInput,
  tagActivityInput,
  keyActivityInput,
  impActivityInput,
  keyActivityMarker,
  timelineDetails,
  yearSelectInput,
  reorderButton,
  reorderApplyButton,
  reorderCancelButton,
  contextMenuTrigger,
  notificationMsg,
  iconPlus,
  newFeaturesHeader,
  newFeatureNotification,
  filterAccordion,
  genericErrorMessage,
  buttonTryAgain,
  quarterSectionsErrorMessage,
  pulseViewTab,
  quarterViewTab,
  changeLogTab,
  tagColorInput,
  filterExportButton,
}
