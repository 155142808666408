import React from 'react'
import { ActivityProp, ActivitiesRowComponentProps } from '../../../types'
import { ActivitiesRow } from '../Section/Section.styles'
import Activity from './Activity'

const ActivitiesRowComponent: React.FC<ActivitiesRowComponentProps> = ({
  row,
  weekView,
  subsectionBrand,
  subsectionTitle,
  subsectionId,
  sectionTitle,
  financialYearDateRange,
}) => {
  return (
    <ActivitiesRow weekView={weekView}>
      {row.activities.map((activity: ActivityProp) => (
        <Activity
          key={activity.id}
          id={activity.id}
          title={activity.title}
          startDate={activity.startDate}
          endDate={activity.endDate}
          days={activity.days}
          startsAt={activity.daysSinceStartOfFinancialYear || 0}
          draft={activity.draft}
          weekView={weekView}
          isKeyActivity={activity.isKeyActivity}
          subsectionBrand={subsectionBrand}
          subsectionTitle={subsectionTitle}
          subsectionId={subsectionId}
          sectionTitle={sectionTitle}
          financialYearDateRange={financialYearDateRange}
          color={activity.color}
          activityHours={activity.activityHours}
          isImpActivity={activity.isImpActivity}
        />
      ))}
    </ActivitiesRow>
  )
}

export default React.memo(ActivitiesRowComponent)
