import React from 'react'
import { ScrollSyncPane } from 'react-scroll-sync'
import { BodyWrapperOuter, BodyWrapper } from './Calendar.styles'

interface VisibleScrollProps {
  visibleScroll?: boolean
}

const BodyScroll: React.FC<VisibleScrollProps> = ({
  visibleScroll,
  children,
}) => {
  return (
    <BodyWrapperOuter>
      <ScrollSyncPane>
        <BodyWrapper visibleScroll={visibleScroll} tabIndex={0}>
          {children}
        </BodyWrapper>
      </ScrollSyncPane>
    </BodyWrapperOuter>
  )
}

export default BodyScroll
