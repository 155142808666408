import create from 'zustand'
import { Tags, ColorFilter } from '@oneplan/common/src/types'
import Brands from '@oneplan/common/src/brands'
import persistFilters from './filterMiddleware'

type State = {
  selectedTags: Tags[]
  selectedColors: ColorFilter[]
  sectionIds: string[]
  subSectionIds: string[]
  subSectionBrandIds: string[]
  setSelectedTags: (selectedTags: Tags[]) => void
  setSelectedColors: (selected: Tags[]) => void
  setSectionIds: (sectionIds: string[]) => void
  setSubSectionIds: (subSectionIds: string[]) => void
  setSubSectionBrandIds: (subSectionBrandIds: string[]) => void
  deleteSectionId: (sectionId: string) => void
  resetFilters: () => void
  clearFilters: () => void
}

const defaultBrandsList = [Brands.Sainsburys.id, Brands.Argos.id, Brands.Tu.id]

export const defaultState = {
  selectedTags: [],
  selectedColors: [],
  sectionIds: [],
  subSectionIds: [],
  subSectionBrandIds: defaultBrandsList,
}

const useFilterStore = create<State>(
  persistFilters((set, get) => ({
    ...defaultState,
    setSelectedTags: selectedTags => set(() => ({ selectedTags })),
    setSelectedColors: (selectedColors: any) => set(() => ({ selectedColors })),
    setSectionIds: sectionIds => set(() => ({ sectionIds })),
    setSubSectionIds: subSectionIds => set(() => ({ subSectionIds })),
    setSubSectionBrandIds: subSectionBrandIds =>
      set(() => ({ subSectionBrandIds })),
    deleteSectionId: sectionId =>
      get().setSectionIds(get().sectionIds.filter(id => id !== sectionId)),
    resetFilters: () =>
      set(() => ({
        selectedTags: [],
        selectedColors: [],
        sectionIds: [],
        subSectionIds: [],
        subSectionBrandIds: defaultBrandsList,
      })),
    clearFilters: () =>
      set(() => ({
        selectedTags: [],
        selectedColors: [],
        sectionIds: [],
        subSectionIds: [],
        subSectionBrandIds: [],
      })),
  }))
)

export default useFilterStore
