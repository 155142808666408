import React, { useState } from 'react'
import Sidepanel from '../Sidepanel'
import { ManagerSubSectionProps } from '../../../types'
import SubsectionContextMenu from './SubsectionContextMenu'
import Loader from '../Loader'
import ErrorBoundary from '../Error/ErrorBoundary'
import FormError from '../Error/FormError'

export const lazyEditSubsectionForm = () =>
  import('../Forms/EditSubsectionForm')
export const lazyAddActivityForm = () => import('../Forms/AddActivityForm')

const EditSubsectionForm = React.lazy(lazyEditSubsectionForm)
const AddActivityForm = React.lazy(lazyAddActivityForm)

const ManagerSubsection: React.FC<ManagerSubSectionProps> = ({
  id,
  canEdit,
  canCreate,
  subsectionTitle,
  brandName,
}) => {
  const [editIsOpen, toggleEdit] = useState(false)
  const [addIsOpen, toggleAdd] = useState(false)

  if (!canEdit && !canCreate) return null

  const handleAddActivity = (isAddActivity: boolean) => {
    if (isAddActivity) {
      toggleAdd(!addIsOpen)
    }
  }
  const handleEditSubsection = (isEditSubsection: boolean) => {
    if (isEditSubsection) {
      toggleEdit(!editIsOpen)
    }
  }

  const handleAddActivityClose = () => toggleAdd(false)
  const handleEditSubsectionClose = () => toggleEdit(false)

  return (
    <>
      <SubsectionContextMenu
        onAddActivityClick={handleAddActivity}
        onEditSubsectionClick={handleEditSubsection}
        subsectionTitle={subsectionTitle}
      />
      <Sidepanel isOpen={editIsOpen} handleClose={handleEditSubsectionClose}>
        <ErrorBoundary
          CustomErrorView={FormError}
          errorProps={{ handleClose: handleEditSubsectionClose }}
        >
          <React.Suspense fallback={<Loader />}>
            <EditSubsectionForm
              handleClose={handleEditSubsectionClose}
              subsectionId={id}
            />
          </React.Suspense>
        </ErrorBoundary>
      </Sidepanel>
      <Sidepanel isOpen={addIsOpen} handleClose={handleAddActivityClose}>
        <ErrorBoundary
          CustomErrorView={FormError}
          errorProps={{ handleClose: handleAddActivityClose }}
        >
          <React.Suspense fallback={<Loader />}>
            <AddActivityForm
              handleClose={handleAddActivityClose}
              subsectionId={id}
              subsectionTitle={subsectionTitle}
              brandName={brandName}
            />
          </React.Suspense>
        </ErrorBoundary>
      </Sidepanel>
    </>
  )
}

export default React.memo(ManagerSubsection)
