import React, { useLayoutEffect } from 'react'
import { RouteComponentProps, withRouter, useHistory } from 'react-router-dom'
import routes from '../../routes'
import Loader from './Auth.styles'
import useUserAndYears from '../../state/server/userAndYears'

const Auth: React.FC<RouteComponentProps> = ({ children }) => {
  const { isLoading, error, data } = useUserAndYears()
  const history = useHistory()

  useLayoutEffect(() => {
    if (data?.userInfo.permissions.userEnabled) {
      history.push(routes.dashboard)
    } else {
      history.push(routes.home)
    }
  }, [history, data, error])

  if (isLoading) return <Loader />

  return <>{children}</>
}

export default withRouter(Auth)
