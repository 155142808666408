import React from 'react'
import { tagFilterInput, filterAccordion } from '@oneplan/common/src/testids'
import { filterTagsId } from '@oneplan/common/src/formIds'
import { Tags } from '@oneplan/common/src/types'
import { FilterTagsProps } from '../../../types'
import { TagInput } from '../Form'
import { TagWrapper, FilterAccordion } from './Filter.styles'
import useFilterStore from '../../state/client/filterStore'

const FilterTags: React.FC<FilterTagsProps> = ({ tags, isLoading }) => {
  const selectedTags = useFilterStore(store => store.selectedTags)
  const setSelectedTags = useFilterStore(store => store.setSelectedTags)

  const handleTagChange = (tagsSelection?: Tags[]) =>
    setSelectedTags(tagsSelection || [])

  return (
    <FilterAccordion
      id={filterTagsId}
      title="Activity Tags"
      className={filterAccordion}
      testId={filterAccordion}
      titleAsLabel
    >
      <TagWrapper>
        <TagInput
          id={filterTagsId}
          name={filterTagsId}
          options={tags}
          onChange={handleTagChange}
          isLoading={isLoading}
          value={selectedTags}
          testId={tagFilterInput}
        />
      </TagWrapper>
    </FilterAccordion>
  )
}

export default React.memo(FilterTags)
