import { gql } from 'graphql-request'

const GET_SUBSECTION_ACTIVITIES = gql`
  query ActivityRows(
    $subsectionId: String!
    $financialYearId: String!
    $tagIds: [String]
    $brandIds: [String]
    $colorIds: [String]
  ) {
    activityRows(
      subsectionId: $subsectionId
      financialYearId: $financialYearId
      tagIds: $tagIds
      brandIds: $brandIds
      colorIds: $colorIds
    ) {
      id
      activities {
        id
        title
        startDate
        endDate
        days
        daysSinceStartOfFinancialYear
        draft
        linkedActivityId
        isKeyActivity
        color
        activityHours
        isImpActivity
      }
    }
  }
`

export default GET_SUBSECTION_ACTIVITIES
