import React from 'react'
import { useTooltip, TooltipPopup, Position } from '@reach/tooltip'
import Portal from '@reach/portal'
import '@reach/tooltip/styles.css'

const distanceFromTrigger = 8
const arrowWidth = 8
const arrowHeight = 6
const tooltipBufferFromEdge = 2
const arrowBufferFromEdge = 8

const getTooltipPosition: Position = (triggerRect, tooltipRect) => {
  if (!triggerRect || !tooltipRect) return {}

  const triggerCenter = triggerRect.left + triggerRect.width / 2
  const left = triggerCenter - tooltipRect.width / 2
  const maxLeft = window.innerWidth - tooltipRect.width - tooltipBufferFromEdge
  return {
    left:
      Math.min(Math.max(tooltipBufferFromEdge, left), maxLeft) + window.scrollX,
    top:
      triggerRect.top -
      tooltipRect.height -
      distanceFromTrigger +
      window.scrollY,
  }
}

const getArrowPosition: Position = triggerRect => {
  if (!triggerRect) return {}

  const left = triggerRect.left - arrowWidth + triggerRect.width / 2
  const maxLeft = window.innerWidth - arrowWidth * 2 - arrowBufferFromEdge
  return {
    left:
      Math.min(Math.max(arrowBufferFromEdge, left), maxLeft) + window.scrollX,
    top: triggerRect.top - distanceFromTrigger + window.scrollY,
  }
}

interface TooltipProps {
  children: React.ReactElement<any>
  label: string
  ariaLabel?: string
}

function Tooltip({ children, label, ariaLabel }: TooltipProps) {
  const [trigger, tooltip] = useTooltip()
  const { isVisible, triggerRect } = tooltip

  return (
    <>
      {React.cloneElement(children, trigger)}
      {isVisible && <Triangle triggerRect={triggerRect} />}
      <TooltipPopup
        {...tooltip} // eslint-disable-line react/jsx-props-no-spreading
        label={label}
        aria-label={ariaLabel}
        style={{
          background: '#222',
          color: 'white',
          border: 'none',
          borderRadius: '3px',
          padding: '0.5em 1em',
          zIndex: 999,
          opacity: 0.9,
        }}
        position={getTooltipPosition}
      />
    </>
  )
}

const Triangle = ({ triggerRect }: { triggerRect: DOMRect | null }) => {
  const { left, top } = getArrowPosition(triggerRect)
  return (
    <Portal>
      <div
        style={{
          position: 'absolute',
          left,
          top,
          width: 0,
          height: 0,
          borderLeft: `${arrowWidth}px solid transparent`,
          borderRight: `${arrowWidth}px solid transparent`,
          borderTop: `${arrowHeight}px solid #222`,
          zIndex: 999,
          opacity: 0.9,
        }}
      />
    </Portal>
  )
}

export default Tooltip
