import React, { useState, useEffect, useCallback } from 'react'
import {
  buttonClose,
  newFeaturesHeader,
  newFeatureNotification,
} from '@oneplan/common/src/testids'
import {
  FixedButtonWrapper,
  OutlinedPrimaryButton,
} from '../Buttons/Buttons.styles'
import {
  NewFeaturesHeader,
  NewFeaturesHeaderWrapper,
  NewFeaturesTitle,
  NewFeaturesDate,
  NewFeaturesDescription,
  NewFeatureWrapper,
  NewFeaturesIcon,
} from './NewFeatures.styles'
import Accordion from '../Accordion'
import newFeatures from '../../data/newFeatures'
import {
  CheckedFeatureProps,
  NewFeaturesProps,
  NewFeaturesDataProps,
} from '../../../types'

const NewFeatures: React.FC<NewFeaturesProps> = ({
  handleClose,
  setIsChecked,
}) => {
  const [checkedFeatures, setCheckedFeatures] = useState<CheckedFeatureProps[]>(
    []
  )
  const getLocalStorageData = useCallback(() => {
    const localStorageData = JSON.parse(
      localStorage.getItem('isNewFeatureChecked') || '[]'
    )
    if (localStorageData.length > 0) {
      setCheckedFeatures(localStorageData)
    }
  }, [])

  const setLocalStorageData = () => {
    if (checkedFeatures.length > 0) {
      checkedFeatures.map((checkedFeature: CheckedFeatureProps) => {
        const feature = checkedFeature
        if (!feature.isChecked) feature.isChecked = true
        return feature
      })
    }
    localStorage.setItem('isNewFeatureChecked', JSON.stringify(checkedFeatures))
  }

  useEffect(() => {
    getLocalStorageData()
  }, [getLocalStorageData])

  const updateCheckedFeatures = () => {
    const newFeaturesArr: CheckedFeatureProps[] = []
    newFeatures.forEach(feature => {
      newFeaturesArr.push({ id: feature.id, isChecked: feature.isChecked })
    })
    setCheckedFeatures(newFeaturesArr)
  }

  const updateIsCheckedNewFeatures = () => {
    return newFeatures?.forEach((newFeature: NewFeaturesDataProps) => {
      const feature = newFeature
      return checkedFeatures?.forEach((checkedFeature: CheckedFeatureProps) => {
        if (feature.id === checkedFeature.id) {
          feature.isChecked = checkedFeature.isChecked
        }
      })
    })
  }

  if (checkedFeatures.length > 0) {
    updateIsCheckedNewFeatures()
    if (checkedFeatures.length !== newFeatures.length) updateCheckedFeatures()
  } else {
    updateCheckedFeatures()
  }

  return (
    <>
      <NewFeaturesHeaderWrapper>
        <NewFeaturesIcon newfeaturestyle="true" aria-hidden />
        <NewFeaturesHeader data-testid={newFeaturesHeader}>
          What&#39;s new?
        </NewFeaturesHeader>
      </NewFeaturesHeaderWrapper>
      {newFeatures.map(feature => {
        return (
          <NewFeatureWrapper key={feature.id}>
            <NewFeaturesTitle
              isChecked={feature.isChecked}
              data-testid={newFeatureNotification}
            >
              {feature.title}
            </NewFeaturesTitle>
            <NewFeaturesDate>{feature.date}</NewFeaturesDate>
            <Accordion nonKeyActivityStyle>
              <NewFeaturesDescription>
                {feature.description}
              </NewFeaturesDescription>
            </Accordion>
          </NewFeatureWrapper>
        )
      })}
      <FixedButtonWrapper>
        <OutlinedPrimaryButton
          type="button"
          color="dark"
          fullWidth
          onClick={() => {
            setIsChecked(true)
            handleClose()
            setLocalStorageData()
          }}
          data-testid={buttonClose}
        >
          Close
        </OutlinedPrimaryButton>
      </FixedButtonWrapper>
    </>
  )
}

export default NewFeatures
