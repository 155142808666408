import create from 'zustand'
import { CalendarRow } from '@oneplan/common/src/types'

type State = {
  scrollPos: number | null
  fixedCalendarRows: CalendarRow[]
  isCalendarCollapsed: boolean
  weekView: boolean
  toggleWeekView: () => void
  setScrollPos: (position: number | null) => void
  toggleIsCalendarCollapsed: () => void
  setFixedCalendarRows: (rows: CalendarRow[]) => void
}

const useCalendarStore = create<State>((set, get) => ({
  scrollPos: 0,
  fixedCalendarRows: [],
  isCalendarCollapsed: false,
  weekView: false,
  toggleWeekView: () => set(() => ({ weekView: !get().weekView })),
  setScrollPos: position => set(() => ({ scrollPos: position })),
  toggleIsCalendarCollapsed: () =>
    set(() => ({ isCalendarCollapsed: !get().isCalendarCollapsed })),
  setFixedCalendarRows: fixedCalendarRows => set(() => ({ fixedCalendarRows })),
}))

export default useCalendarStore
