import React from 'react'
import { Wrapper, Logo, Menu } from './Header.styles'
import onePlanGroupLogo from '../../assets/onePlanGroupLogo.svg'
import NavMenu from '../../components/NavMenu'
import Account from '../../components/Account'
import quarters from '../../data/quarters'

const Header: React.FC = () => {
  return (
    <Wrapper>
      <Logo>
        <img src={onePlanGroupLogo} alt="OnePlan Group Logo" />
      </Logo>
      <Menu>
        <NavMenu quarters={quarters} />
      </Menu>
      <span>
        <Account />
      </span>
    </Wrapper>
  )
}

export default Header
