import React from 'react'
import {
  CalendarRow,
  CalendarCell,
  CalendarCellWeek,
  CalendarCellWeekNumber,
} from './Calendar.styles'
import Tooltip from '../Tooltip'
import { CalendarWeeksProps } from '../../../types'
import * as copy from '../../i18n/copy'
import useCalendarStore from '../../state/client/calendarStore'

const CalendarWeeks: React.FC<CalendarWeeksProps> = ({
  elements,
  isHidden,
  weekView,
  handleWeekView,
}) => {
  const fixedCalendarRows = useCalendarStore(state => state.fixedCalendarRows)
  const isCalendarCollapsed = useCalendarStore(
    state => state.isCalendarCollapsed
  )
  return (
    <>
      <CalendarRow isHidden={isHidden} weekView={weekView}>
        {elements.map((element, index) => {
          const { weekNumber, weekStartingSun, weekStartingWed } = element
          const key = `${weekNumber} ${index}`
          const label = weekView
            ? `${copy.exitWeekView} ${weekNumber}`
            : `${copy.enterWeekView} ${weekNumber}`
          return (
            <Tooltip label={label} key={key}>
              <CalendarCellWeek
                weekView={weekView}
                id={`calendar-row-week-${weekNumber}`}
                data-testid={`calendar-row-week-${weekNumber}`}
                weekCount={1}
                onClick={handleWeekView}
                calendarCellWeekStyle
                fixedCalendarRowsLength={fixedCalendarRows.length}
                isCalendarCollapsed={isCalendarCollapsed}
              >
                <CalendarCell id={`calendar-cell-week-${weekNumber}`}>
                  <CalendarCellWeekNumber>{weekNumber}</CalendarCellWeekNumber>
                  {weekStartingSun} / {weekStartingWed}
                </CalendarCell>
              </CalendarCellWeek>
            </Tooltip>
          )
        })}
      </CalendarRow>
    </>
  )
}

export default React.memo(CalendarWeeks)
