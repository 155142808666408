import styled from '@emotion/styled/macro'

const Calendar = styled.div`
  max-height: 327px;
`

const Wrapper = styled.div`
  display: flex;
`

const HeadWrapper = styled.div`
  max-width: 200px;
  width: 100%;
  display: inline-block;
  flex-shrink: 0;
`

const RowHeader = styled.div`
  background-color: ${({ theme }) => theme.color.grey};
  border-radius: 3px;
  padding: 4.5px 10px;
  height: 20px;
  font-size: 10px;
  color: ${({ theme }) => theme.color.darkGrey};
  margin-bottom: 2px;
  font-weight: 500;
  display: ${(props: { isHidden: boolean }) =>
    props.isHidden ? 'none' : 'flex'};
  position: relative;
  align-items: center;
`
const RowHeaderTitle = styled.span`
  width: 90%;
`

const PinButton = styled.button`
  opacity: ${(props: { checked: boolean }) => (props.checked ? 1 : 0.3)};
  position: relative;
  cursor: pointer;
  outline: none;
  border: none;
  padding: 0;
`

export { Calendar, Wrapper, HeadWrapper, RowHeader, RowHeaderTitle, PinButton }
