import { SectionGroup } from './types'

const WhatPlans: SectionGroup = {
  id: 'whatPlans',
  value: 'What plan(s) do you want to see?',
  label: 'Customer Plans',
  position: 0,
}

const ChannelPlans: SectionGroup = {
  id: 'channelPlans',
  value: 'Channel Plans',
  label: 'Channel Plans',
  position: 1,
}
const AdditionalInformation: SectionGroup = {
  id: 'additionalInformation',
  value: 'Additional Information',
  label: 'Business Plans',
  position: 2,
}
const Other: SectionGroup = {
  id: 'other',
  value: 'Other',
  label: 'Other',
  position: 3,
}

const sectionGroupList: SectionGroup[] = [
  WhatPlans,
  ChannelPlans,
  AdditionalInformation,
  Other,
]

const isValidGroupId = (groupId: string): boolean =>
  sectionGroupList.filter(group => group.id === groupId).length > 0

const sectionGroups = new Map<string, SectionGroup>()
sectionGroupList.forEach(group => {
  sectionGroups.set(group.id, group)
})
const sectionGroupsPosition = new Map<number, string>()
sectionGroupList.forEach(group => {
  sectionGroupsPosition.set(group.position, group.id)
})

export {
  WhatPlans,
  Other,
  sectionGroups,
  isValidGroupId,
  sectionGroupList,
  sectionGroupsPosition,
}
