import create from 'zustand'
import { combine } from 'zustand/middleware'

interface State {
  displayName: string
  email: string
  initials: string
  permissions: {
    userEnabled: boolean
    canCreateSections: boolean
    canExportLogs: boolean
    canExportPulseView: boolean
    canExportQuarterView: boolean
    canReorderSections: boolean
    canReorderActivities: boolean
  }
}

const userInfoStore = create(
  combine(
    {
      displayName: '',
      email: '',
      initials: '',
      permissions: {
        userEnabled: false,
        canCreateSections: false,
        canExportLogs: false,
        canExportPulseView: false,
        canExportQuarterView: false,
        canReorderSections: false,
        canReorderActivities: false,
      },
    },
    set => ({ setUserInfo: (userInfo: State) => set(() => ({ ...userInfo })) })
  )
)

export default userInfoStore
