import styled from '@emotion/styled/macro'
import { css } from '@emotion/react/macro'
import { More, Plus, Tick, Cancel, OverflowMenu, Edit } from '@jsluna/icons'
import { IconButton } from '../Buttons/Buttons.styles'
import { ContextMenuItemButton } from '../../elements/ContextMenu/ContextMenu.styles'

const IconStyle = css`
  width: 17px;
  margin-right: 10px;
`

const PlusIcon = styled(Plus)`
  ${IconStyle}
`
const EditIcon = styled(Edit)`
  ${IconStyle}
`

const ApplyIcon = styled(Tick)`
  ${IconStyle}
`
const CancelIcon = styled(Cancel)`
  ${IconStyle}
`

const MoreIcon = styled(More)`
  ${IconStyle}
`
const ReorderButton = styled(ContextMenuItemButton)``

const ContextMenuWrapperDiv = styled.div`
  position: relative;
`
const ContextMenuTrigger = styled(OverflowMenu)`
  color: ${({ theme }) => theme.color.darkGrey};
  cursor: pointer;
  float: right;
  font-size: 20px;
  font-weight: 900;
  width: 30px;
  position: relative;
  z-index: 1;
`
const ContextMenuTriggerWrapper = styled(IconButton)``

const SubsectionDiv = styled.div`
  &:not(:last-child) {
    margin-bottom: 10px;
  }
`
const SubsectionWrapper = styled.div<{
  color: string
  isPrivate: boolean
  isDragging?: boolean
  isHidden?: boolean
}>`
  background-color: ${props =>
    props.isDragging ? props.theme.color.greySmoke : props.theme.color.grey};
  border-radius: 4px;
  display: ${props => (props.isHidden ? 'none' : 'flex')};
  flex-direction: row;
  position: relative;
  ${props =>
    props.isPrivate &&
    `background-image: repeating-linear-gradient(45deg, transparent, transparent 10px, rgba(226, 224, 224, 0.4) 13px, rgba(226, 224, 224, 0.4) 15px );`}
  &:before {
    content: '';
    position: absolute;
    width: 5px;
    height: 100%;
    border-radius: 4px 0 0 4px;
    background-color: ${props => props.color};
    top: 0;
    left: 0;
  }
  &:not(:last-child) {
    margin-bottom: 10px;
  }
`

const SubsectionTitle = styled.span`
  font-size: 14px;
  font-weight: 700;
  color: ${({ theme }) => theme.color.darkGrey};
  white-space: normal;
  overflow: hidden;
  cursor: default;
`

const SubsectionHeader = styled.div`
  display: flex;
  flex-direction: row;
  padding: 5px 5px 5px 10px;
  align-items: center;
  max-width: 200px;
  width: 100%;
`

const SubsectionMoveIconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 6px;
  align-items: center;
  max-width: 21px;
  width: 100%;
  margin-left: 5px;
`
const SubsectionMoveIcon = styled.img`
  width: 100%;
`

const SubsectionSymbol = styled.div<{
  color: string
  icon: string
  isPrivate: boolean
  isKeyActivity?: boolean
}>`
    background-color: ${props => props.color};
    background-image: url("${props => props.icon}");
    background-size: contain;
    background-repeat: no-repeat;
    color: ${({ theme }) => theme.color.darkGrey};
    border-radius: 50%;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    height: 34px;
    width: 34px;
    font-size: 13px;
    font-weight: bold;
    margin-right: 10px;
    border: 2px solid ${props => props.color};
    position: relative;
    ${props =>
      (props.isPrivate || props.isKeyActivity) &&
      `box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.8);`}
    &::before {
      position: absolute;
      width: 23px;
      height: 4px;
      background-color: rgba(0, 0, 0, 0.1);
      transform: rotate(-45deg);
      transform-origin: 0 0 0;
      bottom: 5px;
      left: 5px;
      border-radius: 50%;
    }
`

const SubsectionPrivateIcon = styled.div<{ icon: string }>`
  background-image: url("${props => props.icon}");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 10px;
  height: 10px;
`

const PrivateIconWrapper = styled.div`
  width: 14px;
  height: 14px;
  background-color: ${({ theme }) => theme.color.white};
  border-radius: 50%;
  position: absolute;
  top: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const SubsectionTitleWrapper = styled.div`
  width: 100%;
  overflow: hidden;
`

export {
  ApplyIcon,
  CancelIcon,
  ContextMenuTrigger,
  ContextMenuTriggerWrapper,
  ContextMenuWrapperDiv,
  PlusIcon,
  MoreIcon,
  ReorderButton,
  EditIcon,
  SubsectionDiv,
  SubsectionHeader,
  SubsectionMoveIcon,
  SubsectionMoveIconWrapper,
  SubsectionPrivateIcon,
  SubsectionSymbol,
  SubsectionTitle,
  SubsectionTitleWrapper,
  SubsectionWrapper,
  PrivateIconWrapper,
}
