import React, { useState, useRef, useEffect } from 'react'
import { useOnClickOutside } from 'use-hooks'
import { logoutEndpoint } from '@oneplan/config/src/uris'
import {
  accountMenu,
  accountMenuButton,
  accountMenuExportButton,
  accountMenuNewFeaturesButton,
} from '@oneplan/common/src/testids'
import {
  NewFeatureNotificationDot,
  NewFeaturesIcon,
} from '../NewFeatures/NewFeatures.styles'
import { AccountWrapper, AccountButton, DownloadIcon } from './Account.styles'
import Sidepanel from '../Sidepanel'
import ContextMenu from '../../elements/ContextMenu'
import {
  ContextMenuItem,
  ContextMenuItemButton,
} from '../../elements/ContextMenu/ContextMenu.styles'
import { LogoutIcon } from '../../icons'
import NewFeatures from '../NewFeatures/NewFeatures'
import newFeatures from '../../data/newFeatures'
import { CheckedFeatureProps } from '../../../types'
import userInfoStore from '../../state/client/userInfo'
import Loader from '../Loader'
import VisuallyHidden from '../VisuallyHidden'

const lazyExports = () => import('../Exports')

const Exports = React.lazy(lazyExports)

const LogoutButton: React.FC = ({ children }) => {
  return (
    <form action={logoutEndpoint} method="POST">
      <input type="hidden" name="logout" value="true" />
      <ContextMenuItemButton type="submit">{children}</ContextMenuItemButton>
    </form>
  )
}

const ExportButton: React.FC = ({ children }) => {
  const [isOpen, toggle] = useState(false)
  return (
    <>
      <ContextMenuItemButton
        onClick={() => toggle(!isOpen)}
        onMouseEnter={lazyExports}
        data-testid={accountMenuExportButton}
      >
        {children}
      </ContextMenuItemButton>
      <Sidepanel
        isOpen={isOpen}
        handleClose={() => toggle(false)}
        withPadding={false}
      >
        <React.Suspense fallback={<Loader />}>
          <Exports handleClose={() => toggle(false)} />
        </React.Suspense>
      </Sidepanel>
    </>
  )
}

const NewFeaturesButton: React.FC<{
  setIsChecked: Function
}> = ({ children, setIsChecked }) => {
  const [isOpen, toggle] = useState(false)
  return (
    <>
      <ContextMenuItemButton
        onClick={() => toggle(!isOpen)}
        data-testid={accountMenuNewFeaturesButton}
      >
        {children}
      </ContextMenuItemButton>
      <Sidepanel isOpen={isOpen} handleClose={() => toggle(false)}>
        <NewFeatures
          handleClose={() => toggle(false)}
          setIsChecked={setIsChecked}
        />
      </Sidepanel>
    </>
  )
}

const MenuAccount: React.FC<{
  open?: boolean
  isChecked: boolean
  setIsChecked: Function
  newFeaturesNumber: number
}> = ({ open = false, isChecked, setIsChecked, newFeaturesNumber }) => {
  const {
    canExportLogs,
    canExportPulseView,
    canExportQuarterView,
  } = userInfoStore(state => state.permissions)
  const canExport = canExportLogs || canExportPulseView || canExportQuarterView

  if (!open) return null
  return (
    <ContextMenu open={open} testId={accountMenu}>
      <ContextMenuItem isDivider>
        <NewFeaturesButton setIsChecked={setIsChecked}>
          <NewFeaturesIcon aria-hidden />
          What&#39;s New
          <NewFeatureNotificationDot
            isChecked={isChecked}
            newFeaturesNumber={newFeaturesNumber}
          />
        </NewFeaturesButton>
      </ContextMenuItem>
      {canExport && (
        <ContextMenuItem isDivider>
          <ExportButton>
            <DownloadIcon aria-hidden />
            Exports
          </ExportButton>
        </ContextMenuItem>
      )}
      <ContextMenuItem>
        <LogoutButton>
          <LogoutIcon />
          Logout
        </LogoutButton>
      </ContextMenuItem>
    </ContextMenu>
  )
}

const Account: React.FC = () => {
  const initials = userInfoStore(state => state.initials)
  const ref = useRef() as React.MutableRefObject<HTMLInputElement>
  const [isOpen, setAccountMenu] = useState(false)
  useOnClickOutside(ref, () => setAccountMenu(false))
  const [isChecked, setIsChecked] = useState(false)
  const localStorageData = JSON.parse(
    localStorage.getItem('isNewFeatureChecked') || '[]'
  )

  const initNewFeaturesNumber = newFeatures.filter(
    feature => !feature.isChecked
  ).length

  const [newFeaturesNumber, setNewFeaturesNumber] = useState(
    initNewFeaturesNumber
  )

  const filteredNewFeatursNumber = newFeatures.filter(feature =>
    localStorageData.every((data: { id: string }) => data.id !== feature.id)
  ).length

  const isAllChecked = localStorageData.every(
    (feature: CheckedFeatureProps) => feature.isChecked === true
  )

  useEffect(() => {
    if (localStorageData.length > 0) {
      setNewFeaturesNumber(filteredNewFeatursNumber)
      if (localStorageData.length === newFeatures.length && isAllChecked) {
        setIsChecked(true)
      }
    }
  }, [localStorageData, filteredNewFeatursNumber, isAllChecked])

  return (
    <AccountWrapper ref={ref}>
      <AccountButton
        data-testid={accountMenuButton}
        type="button"
        onClick={() => setAccountMenu(!isOpen)}
        isChecked={isChecked}
        newFeaturesNumber={newFeaturesNumber}
      >
        <span aria-hidden>{initials}</span>
        <VisuallyHidden>Account menu</VisuallyHidden>
      </AccountButton>
      <MenuAccount
        open={isOpen}
        isChecked={isChecked}
        setIsChecked={setIsChecked}
        newFeaturesNumber={newFeaturesNumber}
      />
    </AccountWrapper>
  )
}

export { MenuAccount, LogoutButton, ExportButton, NewFeaturesButton }
export default React.memo(Account)
