import React from 'react'
import { Header, Sidebar, Calendar, Sections } from '../../sections'
import { DashboardContainer, DashboardContent } from './Dashboard.styles'
import { StyledToastContainer } from '../../components/ToastNotification/ToastNotification.styles'

const Dashboard: React.FC = (): React.ReactElement => (
  <>
    <Header />
    <DashboardContainer>
      <Sidebar />
      <DashboardContent>
        <Calendar />
        <Sections />
      </DashboardContent>
      <StyledToastContainer autoClose={4000} position="top-right" />
    </DashboardContainer>
  </>
)

export default Dashboard
