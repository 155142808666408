import React from 'react'
import { ReorderSvg } from './Icon.styles'

const ReorderIcon = () => {
  return (
    <ReorderSvg xmlns="http://www.w3.org/2000/svg" aria-hidden>
      <defs>
        <path
          id="prefix__a"
          d="M13.2 12.59c.442 0 .8.447.8 1 0 .552-.358 1-.8 1H2.8c-.442 0-.8-.448-.8-1 0-.553.358-1 .8-1zm2.867-4.295c.441 0 .8.448.8 1s-.359 1-.8 1h-10.4c-.442 0-.8-.448-.8-1s.358-1 .8-1zM13.2 4c.442 0 .8.448.8 1s-.358 1-.8 1H2.8C2.358 6 2 5.552 2 5s.358-1 .8-1z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <use fill="currentcolor" fillRule="nonzero" xlinkHref="#prefix__a" />
      </g>
    </ReorderSvg>
  )
}

export default ReorderIcon
