import React, { useState, useEffect } from 'react'
import { calendarDataError } from '@oneplan/common/src/testids'
import CalendarRowData from '@oneplan/common/src/calendar'
import PlinthData from './PlinthData'
import {
  CalendarWeeks,
  CalendarDays,
  CalendarPeriods,
  CalendarTemp,
  CalendarDates,
} from '../../components/Calendar'
import useCalendarData from '../../state/server/calendar'
import useYearStore from '../../state/client/yearStore'
import useCalendarStore from '../../state/client/calendarStore'

const CalendarData: React.FC<{ visibleRows: any }> = ({ visibleRows }) => {
  const [weekView, setWeekView] = useState(false)
  const [scrollPosTarget, setScrollPosTarget] = useState({ targetId: '' })
  const financialYearId = useYearStore(state => state.selectedFinancialYear)

  const isCalendarCollapse = useCalendarStore(
    state => state.isCalendarCollapsed
  )
  const weekViewRedux = useCalendarStore(state => state.weekView)
  const toggleWeekView = useCalendarStore(state => state.toggleWeekView)

  useEffect(() => {
    setWeekView(weekViewRedux)
  }, [weekViewRedux])

  const handleWeekView = (e: React.MouseEvent<HTMLDivElement>) => {
    setWeekView(!weekView)
    toggleWeekView()

    setScrollPosTarget({
      ...scrollPosTarget,
      targetId: e.currentTarget.id.toString(),
    })
  }
  const { data, isLoading, error } = useCalendarData(financialYearId)

  if (isLoading) return null

  if (error) return <div data-testid={calendarDataError}>error...</div>

  const formattedPlinthRowData = [
    CalendarRowData.FFP,
    CalendarRowData.IPSBC,
    CalendarRowData.BHBP,
    CalendarRowData.HBC,
    CalendarRowData.Clothing,
    CalendarRowData.GM,
  ]

  if (!data) return null

  return (
    <>
      <CalendarPeriods
        elements={data?.calendar?.periods}
        isHidden={isCalendarCollapse && !visibleRows.periods}
        weekView={weekView}
      />
      <CalendarWeeks
        elements={data?.calendar.weeks}
        isHidden={isCalendarCollapse && !visibleRows.weeks}
        weekView={weekView}
        handleWeekView={handleWeekView}
      />
      <CalendarDays
        days={data?.calendar.days}
        isHidden={isCalendarCollapse && !visibleRows.weekDays}
        weekView={weekView}
      />
      <CalendarDates
        days={data?.calendar.days}
        isHidden={isCalendarCollapse && !visibleRows.date}
        weekView={weekView}
        dayOfMonth
      />
      <CalendarTemp
        elements={data?.calendar.weeks}
        isHidden={isCalendarCollapse && !visibleRows.avgTemp}
        weekView={weekView}
      />
      {formattedPlinthRowData.map(row => {
        // TODO Split this logic into own component
        const filteredPlinthRow = data?.plinthDates.filter(
          date => date.name.indexOf(row.value) > -1
        )
        if (filteredPlinthRow.length === 0) {
          filteredPlinthRow.push({ dates: [], id: '', name: '' })
        }
        return (
          <PlinthData
            key={row.id}
            elements={filteredPlinthRow}
            isHidden={isCalendarCollapse && !visibleRows[row.id]}
          />
        )
      })}
    </>
  )
}

export default CalendarData
