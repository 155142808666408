const scrollCalendar = (searchId: string) => {
  const element = document.getElementById(searchId)
  const quarters = document.getElementById('quarters')
  if (quarters && element) {
    quarters.scrollLeft = element.offsetLeft - 10
  }
  return quarters
}

export default scrollCalendar
