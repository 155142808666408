import React from 'react'
import { Tags, ColorFilter } from '@oneplan/common/src/types'
import { noDataFound, noFilterFound } from '@oneplan/common/src/testids'
import { Section } from '../../components/Section'
import { Sections, Subsections } from '../../../types'
import { GenericError } from '../../components/Error'
import Loader from '../../components/Loader'
import Subsection from '../../components/Subsection'
import FilterErrorMessage from '../../components/Filter/FilterErrorMessage'
import UseFeature from '../../components/UseFeature'
import * as copy from '../../i18n/copy'
import { useSectionsData } from '../../state/server/sections'
import useYearStore from '../../state/client/yearStore'
import useFilterStore from '../../state/client/filterStore'
import getBrandById from '../../utils/getBrandById'

const SectionData: React.FC<{}> = () => {
  const financialYearId = useYearStore(state => state.selectedFinancialYear)
  const selectedTags = useFilterStore(store => store.selectedTags)
  const selectedColors = useFilterStore(store => store.selectedColors)
  const sectionIds = useFilterStore(store => store.sectionIds)
  const subSectionIds = useFilterStore(store => store.subSectionIds)
  const brandIds = useFilterStore(store => store.subSectionBrandIds)
  const { data, isLoading, isError } = useSectionsData({
    financialYearId,
    tagIds:
      selectedTags?.length > 0
        ? selectedTags.map((tag: Tags) => tag.value)
        : [],
    sectionIds,
    subSectionIds,
    brandIds,
    colorIds:
      selectedColors?.length > 0
        ? selectedColors.map((color: ColorFilter) => color.value)
        : [],
  })

  if (isLoading) {
    return <Loader />
  }

  if (isError) {
    return (
      <div data-newrelic="error">
        <GenericError />
      </div>
    )
  }

  if (data?.sections.length === 0) {
    return (
      <>
        <UseFeature when={selectedTags.length > 0}>
          <FilterErrorMessage
            testId={noFilterFound}
            message={copy.noFilterData}
            tagIds={selectedTags}
          />
        </UseFeature>
        <UseFeature when={selectedTags.length === 0}>
          <FilterErrorMessage testId={noDataFound} message={copy.noDataFound} />
        </UseFeature>
      </>
    )
  }

  return (
    <div data-newrelic="success">
      {data?.sections.map((section: Sections) => (
        <Section
          key={section.id}
          id={section.id}
          title={section.title}
          description={section.description}
          canEdit={section.meta.canEdit}
          canCreate={section.meta.canCreateChild}
          reorderMode={false}
          subsectionLength={section.subsections.length}
        >
          {section.subsections.map((subsection: Subsections) => (
            <Subsection
              key={subsection.id}
              id={subsection.id}
              title={subsection.title}
              description={subsection.description}
              isPrivate={subsection.private}
              brand={subsection.brandId}
              brandName={getBrandById(subsection.brandId).title}
              canEdit={subsection.meta.canEdit}
              canCreate={subsection.meta.canCreateChild}
              numberOfRows={subsection.activityRows.length}
            />
          ))}
        </Section>
      ))}
    </div>
  )
}

export default SectionData
