import React from 'react'
import Loader from '../Loader'
import { AuthorContactDiv, FormTitle } from './AuthorField.styles'

interface AuthorFieldProps {
  email: string | undefined
  loading: boolean
}

const AuthorField: React.FC<AuthorFieldProps> = ({ email, loading }) => {
  return (
    <>
      <FormTitle>Author</FormTitle>
      {loading ? <Loader /> : <AuthorContactDiv>{email}</AuthorContactDiv>}
    </>
  )
}

export default AuthorField
