import { gql } from 'graphql-request'

const GET_USER_AND_YEARS = gql`
  query UserAndYears {
    userInfo {
      initials
      displayName
      email
      permissions {
        userEnabled
        canCreateSections
        canExportLogs
        canExportPulseView
        canExportQuarterView
        canReorderSections: reorderSections
        canReorderActivities: reorderActivities
      }
    }
    financialYears {
      currentFinancialYear {
        id
        title
        startDate
        endDate
      }
      financialYears {
        id
        title
        startDate
        endDate
      }
    }
  }
`

export default GET_USER_AND_YEARS
