const quarters = [
  { title: 'Quarter 1', searchId: 'quarter1', weeks: 16 },

  { title: 'Quarter 2', searchId: 'quarter2', weeks: 12 },

  { title: 'Quarter 3', searchId: 'quarter3', weeks: 15 },

  { title: 'Quarter 4', searchId: 'quarter4', weeks: 9 },
]

export default quarters
