import { useState, useEffect, useCallback } from 'react'
import { SectionNode, FilterSection } from '../../types'
import formatSectionList from '../utils/formatSectionList'
import { useFilterData } from '../state/server/filter'
import { useTagsData } from '../state/server/tags'
import useYearStore from '../state/client/yearStore'
import useFilterStore from '../state/client/filterStore'

const useFilterForm = () => {
  const financialYearId = useYearStore(state => state.selectedFinancialYear)
  const sectionIds = useFilterStore(store => store.sectionIds)
  const subSectionIds = useFilterStore(store => store.subSectionIds)
  const subSectionBrandIds = useFilterStore(store => store.subSectionBrandIds)
  const setSectionIds = useFilterStore(store => store.setSectionIds)
  const setSubSectionIds = useFilterStore(store => store.setSubSectionIds)
  const clearFilters = useFilterStore(store => store.clearFilters)

  const [sectionExpandedList, setSectionExpandedList] = useState<string[]>([])
  const [sectionNodeList, setSectionNodeList] = useState<SectionNode[]>([])

  const {
    data,
    isLoading: filterDataIsLoading,
    isFetching,
    isError,
  } = useFilterData({
    financialYearId,
    brandIds: subSectionBrandIds,
  })

  const { data: tagsData, isLoading: tagsDataIsLoading } = useTagsData()
  const tags = tagsData?.tags || []

  const isLoading = filterDataIsLoading || tagsDataIsLoading

  const existingFilterSettings = localStorage.getItem(financialYearId) !== null

  const setDefaultSectionId = useCallback(() => {
    const defaultSectionId = data?.sections?.find(
      (section: FilterSection) => section.label.toLowerCase() === 'core plan'
    )?.value

    setSectionIds(defaultSectionId ? [defaultSectionId] : [])
  }, [data, setSectionIds])

  // If user has not selected anything then set default section id
  useEffect(() => {
    if (!existingFilterSettings) setDefaultSectionId()
  }, [existingFilterSettings, setDefaultSectionId])

  useEffect(() => {
    if (data && data.sections) {
      const { sections } = data
      const { sectionList, expandedList } = formatSectionList(sections)
      setSectionExpandedList(expandedList)
      setSectionNodeList(sectionList)
    }
  }, [data])

  const handleSectionSubsectionList = (selectedList: string[]) => {
    const sectionsList: string[] = []
    const subsectionsList: string[] = []
    selectedList.forEach((selectedItem: string) => {
      if (selectedItem.match(/-/g)?.length === 2) {
        subsectionsList.push(selectedItem)
      } else {
        sectionsList.push(selectedItem)
      }
    })
    setSectionIds(sectionsList)
    setSubSectionIds(subsectionsList)
  }

  return {
    data,
    isLoading,
    isFetching,
    isError,
    tags,
    sectionIds,
    subSectionIds,
    sectionExpandedList,
    sectionNodeList,
    setSectionExpandedList,
    handleSectionSubsectionList,
    clearFilters,
  }
}

export default useFilterForm
