export const oneplan = 'Oneplan'
export const oneplantool = 'Oneplan Tool'
export const loginsubtitle = 'Sainsbury’s Group colleagues only'
export const pageErrorTitle =
  'We’re very sorry! We’ve run into some technical difficulties.'
export const pageErrorSubtitle =
  'OnePlan is currently experiencing technical difficulties. Our team is working on the issue — please come back soon.'
export const formErrorTitle = 'We’re very sorry! Something went wrong.'
export const genericErrorMsg =
  'Something went wrong, please reload the page and try again.'
export const noFilterData = 'No activities tagged with: '
export const noDataFound = 'No data found'
export const exportsHeader = 'Export'
export const pulseViewData =
  'Export a Pulse view of OnePlan in an Excel file format.'
export const quarterViewData =
  'Select which quarter you’d like to export or pick a date range in weeks.'
export const clearButton = 'Clear'
export const quarterSectionWhatPlans = 'What plan(s) do you want to export?'
export const quarterSectionsErrorMsg =
  'Please select at least one plan to export.'
export const changeLogData =
  'Select a range of dates to export a log of previous OnePlan changes.'
export const enterWeekView = 'Enter week view'
export const exitWeekView = 'Exit week view'
export const section = {
  createSection: 'Section created',
  updateSection: 'Section updated',
  deleteSectionWarning: 'Are you sure you want to proceed?',
  deleteSection: 'Section deleted',
  validation: {
    sectionTitleRequired: 'This is required',
    sectionTitleLength: 'Maximum 100 characters',
    sectionDescription: 'This is required',
  },
}
export const subsection = {
  createSubsection: 'Subsection created',
  updateSubsection: 'Subsection updated',
  deleteSubsectionWarning:
    'Deleting this subsection would also delete all the associated activities. Are you sure you want to proceed?',
  deleteSubsection: 'Subsection deleted',
  validation: {
    subsectionTitleRequired: 'This is required',
    subsectionTitleLength: 'Maximum 100 characters',
    subsectionDescription: 'This is required',
  },
}
export const activity = {
  createActivity: 'Activity created',
  updateActivity: 'Activity updated',
  deleteActivityWarning: 'Are you sure you would like to delete this activity?',
  deleteActivity: 'Activity deleted',
  emailAddressIncorrect: 'Enter a valid email address',
  validation: {
    activityTitleRequired: 'This is required',
    activityTitleLength: 'Maximum 100 characters',
    startDate: 'Start date is required',
    endDate: 'End date is required',
  },
}
