import create from 'zustand'

type State = {
  isReorderMode: boolean
  toggleReorderMode: () => void
  applyReorder: boolean
  setApplyReorder: (arg: boolean) => void
}

const useSubsectionReorderStore = create<State>(set => ({
  isReorderMode: false,
  toggleReorderMode: () =>
    set(state => ({ isReorderMode: !state.isReorderMode })),
  applyReorder: false,
  setApplyReorder: (is: boolean) => set(() => ({ applyReorder: is })),
}))

export default useSubsectionReorderStore
