import React from 'react'
import { yearSelectInput } from '@oneplan/common/src/testids'
import { useIsFetching } from 'react-query'
import { YearSelect } from './NavMenu.styles'
import useYearStore from '../../state/client/yearStore'

interface SelectOptions {
  label: string
  value: string
}

const YearDropdown: React.FC = () => {
  const isFetching = useIsFetching()
  const setSelectedFinancialYear = useYearStore(
    store => store.setSelectedFinancialYear
  )
  const selectedFinancialYear = useYearStore(
    store => store.selectedFinancialYear
  )
  const financialYearsList = useYearStore(state => state.financialYearsList)

  const financialYears: SelectOptions[] = financialYearsList.map(year => {
    return {
      label: year.id,
      value: year.title,
    }
  })

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedFinancialYear(e.target.value)
  }

  return (
    <YearSelect
      aria-label="select financial years"
      data-testid={yearSelectInput}
      options={financialYears}
      name="financial-years"
      onChange={handleChange}
      value={selectedFinancialYear}
      disabled={!!isFetching || financialYears.length === 0}
    />
  )
}

export default YearDropdown
