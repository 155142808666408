import styled from '@emotion/styled/macro'
import { css } from '@emotion/react/macro'
import { More, Plus, Tick, Cancel, Download } from '@jsluna/icons'

const IconStyle = css`
  width: 17px;
  margin-right: 10px;
`

const SectionsWrapper = styled.div`
  flex: 1 1 auto;
  position: relative;
  height: 100%;
  min-height: 0;
`

const SectionsContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
`

const PlusIcon = styled(Plus)`
  ${IconStyle}
`
const ApplyIcon = styled(Tick)`
  ${IconStyle}
`
const CancelIcon = styled(Cancel)`
  ${IconStyle}
`
const DownloadIcon = styled(Download)`
  ${IconStyle}
`
const SectionReorderBackground = styled.div<{ isDraggingOver?: boolean }>`
  transition: background-color 0.2 ease;
  background-color: ${props =>
    props.isDraggingOver
      ? props.theme.color.lightGrey
      : props.theme.color.whiteSmoke};
`
const ContextMenuWrapperDiv = styled.div`
  padding-bottom: 5px;
  margin: 10px;
  width: 100%;
  position: relative;
`
const ContextMenuTrigger = styled(More)`
  color: ${({ theme }) => theme.color.darkGrey};
  cursor: pointer;
  float: right;
  font-size: 20px;
  font-weight: 900;
  width: 30px;
  position: relative;
  z-index: 1;
`

export {
  ApplyIcon,
  CancelIcon,
  ContextMenuTrigger,
  ContextMenuWrapperDiv,
  PlusIcon,
  SectionReorderBackground,
  SectionsContainer,
  SectionsWrapper,
  DownloadIcon,
}
