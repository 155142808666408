import React from 'react'
import { Container, LogoWrapper, Title, Subtitle } from './Error.styles'
import onePlanLogo from '../../assets/onePlanLogo.svg'
import icon from '../../assets/onePlanIcon.svg'
import { pageErrorTitle, pageErrorSubtitle } from '../../i18n/copy'

const PageError = () => {
  return (
    <Container>
      <LogoWrapper>
        <img src={icon} alt="one plan icon" title="one plan icon" />
        <img src={onePlanLogo} alt="one plan logo" title="one plan logo" />
      </LogoWrapper>
      <Title>{pageErrorTitle}</Title>
      <Subtitle>{pageErrorSubtitle}</Subtitle>
    </Container>
  )
}

export default PageError
