import { gql } from 'graphql-request'

const GET_CALENDAR_QUERY = gql`
  query Calendar($financialYearId: String!) {
    calendar(financialYearId: $financialYearId) {
      periods {
        name
        weekCount
      }
      weeks {
        weekNumber
        weekStartingSun
        weekStartingWed
        avgTemp
      }
      days {
        dayOfMonth
        dayOfWeekInitial
        dayOfWeek
        isoDate
      }
    }
    plinthDates(financialYearId: $financialYearId) {
      name
      id
      dates {
        plinthNumber
        daysSinceStartOfFinancialYear
        date
        label
      }
    }
  }
`

export default GET_CALENDAR_QUERY
