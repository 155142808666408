import { State, StateCreator, GetState, SetState, StoreApi } from 'zustand'
import useYearStore from './yearStore'

const persistFilters = <T extends State>(config: StateCreator<T>) => (
  set: SetState<T>,
  get: GetState<T>,
  api: StoreApi<T>
) => {
  const save = () => {
    const year = useYearStore.getState().selectedFinancialYear
    localStorage.setItem(year, JSON.stringify(get()))
  }

  const restore = (year: string, previousYear?: string) => {
    try {
      const savedState = localStorage.getItem(year)

      // reset filters when the year changes and there is no previously saved state
      if (!savedState && year && previousYear && year !== previousYear) {
        const { resetFilters } = get()
        if (typeof resetFilters === 'function') resetFilters()
      }

      // else if saved state exists, restore it
      if (savedState) set(JSON.parse(savedState))
    } catch (e) {
      console.error(new Error(`Unable to get to saved filter state`))
    }
  }

  const state = config(
    payload => {
      set(payload)
      save() // save to local storage each time filter state is updated
    },
    get,
    api
  )

  useYearStore.subscribe(restore, store => store.selectedFinancialYear)

  return state
}

export default persistFilters
