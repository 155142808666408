import { useQuery } from 'react-query'
import { request } from 'graphql-request'
import { FilterQueryResponse } from '../../../types'
import GET_FILTER from '../../graphql/GET_FILTER'
import { endpoint, config } from './query.config'
import { queryClient } from '../../providers'

const KEY = 'filter'

export interface FilterVariables {
  financialYearId: string
  brandIds?: string[]
}

const useFilterData = (variables: FilterVariables) => {
  return useQuery<FilterQueryResponse, Error>(
    [KEY, { ...variables }],
    async () => request(endpoint, GET_FILTER, variables),
    {
      ...config,
      enabled: !!variables.financialYearId,
      keepPreviousData: true,
    }
  )
}

const invalidateFilterData = (variables: FilterVariables) =>
  queryClient.refetchQueries([KEY, { ...variables }])

export { useFilterData, invalidateFilterData }
