import React, { useState } from 'react'
import CreatableSelect from 'react-select/creatable'
import styled from '@emotion/styled/macro'
import validateEmailAddress from '@oneplan/helpers/src/validateEmailAddress'
import { CreateTag } from '../../../types'
import * as copy from '../../i18n/copy'

const components = {
  DropdownIndicator: null,
}

const CreateTagInputStyled = styled(CreatableSelect)`
  .react-creatable__control {
    border-color: ${({ theme }) => theme.color.greySmoke};
    &:hover {
      border-color: ${({ theme }) => theme.color.darkGrey};
      box-shadow: 0 0 0 1px ${({ theme }) => theme.color.darkGrey};
    }
  }
`
const ValidationErrorMessage = styled.div`
  margin-top: -0.5rem;
  margin-bottom: 1.5rem;
  color: red;
  font-size: 14px;
`
const ValidationWrapper = styled.div`
  margin-top: 1rem;
  margin-bottom: -1.5rem;
`

interface CreateTagInputProps {
  id: string
  name: string
  inputValue?: string
  value?: Array<CreateTag>
  onChange?: (value?: any) => void
  onHandleInputChange?: Function
  onKeyDown?: any
  isDisabled: boolean
}

const CreateTagInput: React.FC<CreateTagInputProps> = ({
  id,
  name,
  onChange,
  value = [],
  isDisabled = false,
}) => {
  const [inputValue, setInputValue] = useState('')
  const [displayError, setDisplayError] = useState(false)

  const handleInputChange = (inputText: string) => {
    setDisplayError(false)
    setInputValue(inputText)
  }

  const handleChange = (valueText: CreateTag[]) => {
    return onChange ? onChange(valueText) : []
  }

  const handleError = () => {
    setDisplayError(true)
  }

  const createOption = (label: string) => ({
    label,
    value: label,
  })

  const handleKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    if (!inputValue) return
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        if (validateEmailAddress(inputValue) && inputValue) {
          value.push(createOption(inputValue))
          setInputValue('')
          handleChange(value)
        } else {
          handleError()
        }
        event.preventDefault()
        break
      default:
        break
    }
  }

  return (
    <>
      <CreateTagInputStyled
        inputId={id}
        name={name}
        components={components}
        inputValue={inputValue}
        isClearable
        isMulti
        menuIsOpen={false}
        placeholder="e.g. jen.hill@sainsburys.co.uk and press enter..."
        classNamePrefix="react-creatable"
        onChange={onChange}
        onInputChange={handleInputChange}
        onKeyDown={handleKeyDown}
        value={value}
        isDisabled={isDisabled}
      />
      {displayError && (
        <ValidationWrapper>
          <ValidationErrorMessage>
            {copy.activity.emailAddressIncorrect}
          </ValidationErrorMessage>
        </ValidationWrapper>
      )}
    </>
  )
}
export default CreateTagInput
