import styled from '@emotion/styled/macro'
import { css } from '@emotion/react/macro'
import { Tick, ArrowRight, ArrowDown, Minus } from '@jsluna/icons'
import { darken } from 'polished'

const IconStyle = css`
  width: 17px !important;
  position: relative;
  top: 4px;
  border-radius: 2px;
`
const ArrowIconStyle = css`
  width: 14px !important;
  position: relative;
  top: 3px;
`

const TickIcon = styled(Tick)`
  ${IconStyle}
  background-color: ${({ theme }) => theme.color.primary};
  color: ${({ theme }) => theme.color.white};
`
const ArrowRightIcon = styled(ArrowRight)`
  ${ArrowIconStyle}
`
const ArrowDownIcon = styled(ArrowDown)`
  ${ArrowIconStyle}
`
const MinusIcon = styled(Minus)`
 ${IconStyle}
 background-color: ${({ theme }) => theme.color.primary};
  color: ${({ theme }) => theme.color.white};
`

const UnCheckIcon = styled(Tick)`
  ${IconStyle}
  background-color: ${({ theme }) => theme.color.white};
  color:  ${({ theme }) => theme.color.white};
  border: 1px solid  ${({ theme }) => darken('0.1', `${theme.color.grey}`)};
`
const CheckBoxTreeWrapper = styled.div`
  .react-checkbox-tree {
    display: block;

    label {
      padding: 0 0 2px 2px;
      &:hover {
        background-color: ${({ theme }) => theme.color.lightGrey};

        ${UnCheckIcon} {
          border: solid 1px ${({ theme }) => theme.color.darkGrey};
        }
      }
    }

    .rct-text {
      line-height: 1.8;
      align-items: baseline;
    }

    .rct-title {
      width: 165px;
      display: inline-flex;
      white-space: pre-line;
      word-wrap: break-word;
      line-height: 1.3;
      font-size: 14px;
      font-weight: 400;
      padding-left: 4px;
      color: ${({ theme }) => theme.color.blackSmoke};
    }

    .rct-bare-label .rct-title {
      font-weight: bold;
      font-family: 'MaryAnn';
      color: ${({ theme }) => theme.color.blackSmoke};
      font-size: 15px;
    }

    .rct-collapse {
      padding: 0 0 0 5px;
    }

    > ol > li {
      margin-bottom: 5px;
    }
  }
`

export {
  ArrowRightIcon,
  ArrowDownIcon,
  TickIcon,
  MinusIcon,
  UnCheckIcon,
  CheckBoxTreeWrapper,
}
