import React, { useState, useMemo } from 'react'
import {
  activityTitleDiv,
  activityButton,
  keyActivityMarker,
} from '@oneplan/common/src/testids'
import dayjs from 'dayjs'
import Tooltip from '../Tooltip'
import {
  ActivityButton,
  ActivityDate,
  ActivityDots,
  ActivityTitle,
  ActivityDataWrapper,
} from './Activity.styles'
import { ActivityProp } from '../../../types'
import formatReadableDate from '../../utils/formatReadableDate'
import Loader from '../Loader'
import VisuallyHidden from '../VisuallyHidden'
import { KeyActivityIcon } from '../../icons'
import { ImpActivityIconHome } from '../CustomOptions/CustomOption.styles'
import theme from '../../styles/theme'

export const lazyActivityDetails = () => import('./ActivityDetails')
const ActivityDetails = React.lazy(lazyActivityDetails)

const Activity: React.FC<ActivityProp> = ({
  id,
  title,
  startDate,
  endDate,
  days = 1,
  startsAt,
  draft,
  weekView,
  isKeyActivity,
  isImpActivity,
  subsectionBrand,
  subsectionTitle,
  subsectionId,
  sectionTitle,
  financialYearDateRange,
  color,
}) => {
  const [isActivityOpen, toggleIsActivityOpen] = useState(false)
  const endDateAcrossYear = dayjs(endDate).isAfter(
    financialYearDateRange?.endDate
  )
  const startDateAcrossYear = dayjs(startDate).isBefore(
    financialYearDateRange?.startDate
  )
  const formattedTitle = draft ? `Draft - ${title}` : title
  const diffStartDate = dayjs(startDate, 'DD/MM/YYYY')
  const diffEndDate = dayjs(endDate, 'DD/MM/YYYY')
  const diffDays = diffEndDate.diff(diffStartDate, 'day') + 1

  const diffDaysFormFirstdayOfFinancialYear = diffEndDate.diff(
    financialYearDateRange?.startDate,
    'day'
  )
  const readableStartDate = useMemo(() => formatReadableDate(startDate), [
    startDate,
  ])
  const readableEndDate = useMemo(() => formatReadableDate(endDate), [endDate])

  const formattedDate =
    endDate && startDate !== endDate
      ? `${readableStartDate} - ${readableEndDate}`
      : `${readableStartDate}`

  return (
    <>
      <Tooltip
        label={formattedTitle}
        ariaLabel={`${formattedTitle}, ${formattedDate}`}
      >
        <ActivityButton
          days={days}
          startsAt={startsAt}
          onClick={() => toggleIsActivityOpen(!isActivityOpen)}
          weekView={weekView}
          draft={draft}
          data-testid={`${activityButton}-${formattedTitle}`}
          endDateAcrossYear={endDateAcrossYear}
          startDateAcrossYear={startDateAcrossYear}
          diffDays={diffDays > 4}
          color={color}
        >
          {(diffDaysFormFirstdayOfFinancialYear > 4 && diffDays > 4) ||
          weekView ? (
            <ActivityDataWrapper>
              {isKeyActivity && (
                <KeyActivityIcon
                  color={theme.color.darkGrey}
                  size={12}
                  data-testid={keyActivityMarker}
                />
              )}
              {isImpActivity && <ImpActivityIconHome />}
              <ActivityTitle
                data-testid={`${activityTitleDiv}-${formattedTitle}`}
              >
                {formattedTitle}
                <ActivityDate>{formattedDate}</ActivityDate>
              </ActivityTitle>
            </ActivityDataWrapper>
          ) : (
            <>
              <VisuallyHidden>{`${formattedTitle} ${formattedDate}`}</VisuallyHidden>
              <ActivityDots data-testid={activityTitleDiv} aria-hidden>
                ···
              </ActivityDots>
            </>
          )}
        </ActivityButton>
      </Tooltip>
      {isActivityOpen && (
        <React.Suspense fallback={<Loader />}>
          <ActivityDetails
            isActivityOpen={isActivityOpen}
            toggleIsActivityOpen={toggleIsActivityOpen}
            title={formattedTitle}
            startDate={startDate}
            endDate={endDate}
            id={id}
            subsectionBrand={subsectionBrand}
            draft={draft}
            isKeyActivity={isKeyActivity}
            isImpActivity={isImpActivity}
            subsectionTitle={subsectionTitle}
            subsectionId={subsectionId}
            sectionTitle={sectionTitle}
          />
        </React.Suspense>
      )}
    </>
  )
}

export default React.memo(Activity)
