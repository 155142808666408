import React from 'react'
import { skeletonLoading } from '@oneplan/common/src/testids'
import {
  SkeletonWrapper,
  StyledSkelton,
  calculateSkeletonHeight,
} from './SkeletonLoading.styles'

interface SkeletonLoadingProps {
  numberOfRows: number
}

const SkeletonLoading: React.FC<SkeletonLoadingProps> = ({ numberOfRows }) => {
  return (
    <SkeletonWrapper
      data-testid={skeletonLoading}
      height={calculateSkeletonHeight(numberOfRows)}
    >
      <StyledSkelton />
    </SkeletonWrapper>
  )
}

export default SkeletonLoading
