const theme = {
  color: {
    primary: '#f06c00',
    darkPrimary: '#e55000',
    smokePrimary: '#f5be91',
    lightPrimary: '#f7e6d3',
    error: '#d70000',
    success: '#218234',
    lightGreen: '#0dc74c',
    lightRed: '#ff4242',
    white: '#ffffff',
    whiteSmoke: '#f8f8f8',
    grey: '#eeeeee',
    lightGrey: '#e1e1e1',
    greySmoke: '#cbcbcb',
    wolfGrey: '#8e8e8e',
    darkGrey: '#4c4c4c',
    blackSmoke: '#3d3d3d',
    black: '#0C0C0C',
    lunaGrey: '#404040',
  },
  brands: {
    argos: '#e83123',
    tu: '#0b0b0b',
    sainsburysHome: '#f06c00',
    sainsburysBank: '#88496b',
    sainsburysEnergy: '#ffa358',
    habitat: '#4c4c4c',
    nectar: '#6e1cdb',
    group: '#1a73e8',
    other: '#ff00ff',
  },
}

export default theme
