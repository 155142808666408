import styled from '@emotion/styled/macro'
import { FilledButton } from '@jsluna/button'

const Container = styled.main`
  background-color: ${({ theme }) => theme.color.whiteSmoke};
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  flex: 1 0 auto;
  align-items: center;
  justify-content: center;
`

const Title = styled.h1`
  text-align: center;
`

const Subtitle = styled.div`
  font-family: 'MaryAnn';
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  margin-bottom: 25px;
`
const LoginButton = styled(FilledButton)`
  && {
    display: block;
    width: 270px;
    height: 48px;
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
    font-family: Mary Ann, Arial, sans-serif;
  }
`

const Note = styled.div`
  font-family: Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 26px;
  text-align: center;
  margin-top: 25px;
  a {
    color: #f06c00;
  }
`

export { Container, Title, Subtitle, LoginButton, Note }
