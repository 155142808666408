import React from 'react'
import { Label } from '@jsluna/react'
import styled from '@emotion/styled/macro'
import makeAnimated from 'react-select/animated'
import { SelectStyled } from './TagInput'

const KeyActivitySelectStyled = styled(SelectStyled)`
  .react-select__option {
    padding: 0 0 0 10px;
    background-color: ${({ theme }) => theme.color.white};
    &--is-focused {
      background-color: ${({ theme }) => theme.color.white};
    }
  }
`
interface CustomOptionSelectProps {
  id: string
  name: string
  label: string
  isLoading?: boolean
  isDisabled?: boolean
  onChange?: () => void
  value?: any
  options: any
  testId: string
  isKeyActivity?: boolean
  formattedOption?: any
  className?: string
}

const animatedComponents = makeAnimated()

const CustomOptionSelect: React.FC<CustomOptionSelectProps> = ({
  id,
  name,
  label,
  onChange,
  value = '',
  options,
  isLoading = false,
  isDisabled = false,
  testId,
  formattedOption,
  className,
}) => {
  return (
    <div className={className}>
      <Label htmlFor={id}>{label}</Label>
      <KeyActivitySelectStyled
        id={testId}
        inputId={id}
        options={options}
        name={name}
        value={value}
        onChange={onChange}
        formatOptionLabel={formattedOption}
        isClearable
        components={animatedComponents}
        isLoading={isLoading}
        isDisabled={isDisabled}
      />
    </div>
  )
}

export default CustomOptionSelect
