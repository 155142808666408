import React from 'react'
import { ThemeProvider as Provider, Global } from '@emotion/react/macro'
import { theme, GlobalStyle } from '../styles'

const ThemeProvider: React.FC = ({ children }) => {
  return (
    <Provider theme={theme}>
      <>
        <Global styles={GlobalStyle} />
        {children}
      </>
    </Provider>
  )
}

export default ThemeProvider
