import styled from '@emotion/styled/macro'
import { motion } from 'framer-motion'
import { nonWeekViewWidth, weekViewWidth } from '../Calendar/Calendar.styles'

const weekLineWidth = 2

const SectionWrapper = styled.div<{
  isHidden: boolean
  isDraggingOver?: boolean
}>`
  padding: 10px;
  border-radius: 4px;
  background-color: ${props =>
    props.isDraggingOver ? 'none' : props.theme.color.whiteSmoke};

  &:not(:last-child) {
    margin-bottom: 20px;
  }
  display: ${props => (props.isHidden ? 'none' : 'block')};
`

const SectionTitle = styled.span`
  font-family: 'MaryAnn';
  font-size: 15px;
  font-weight: 800;
  color: ${({ theme }) => theme.color.darkGrey};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: default;
`

const SectionHeader = styled.div`
  padding-bottom: 10px;
`
// MANAGER

const ManagerWrapper = styled(motion.div)`
  display: flex;
`

const ManagerButton = styled.button`
  background: transparent;
  border: none;
  padding: 4px 0;
  cursor: pointer;
  position: relative;
  font-size: 11px;
  color: ${({ theme }) => theme.color.blackSmoke};
  white-space: nowrap;
  transition: all 0.2s ease-in-out;

  &:hover {
    color: ${({ theme }) => theme.color.primary};
    text-decoration: underline;
  }
`

const ButtonManager = styled(ManagerButton)`
  &:first-of-type {
    margin-right: 10px;
    padding-right: 2px;
    &::after {
      content: '';
      display: block;
      width: 1px;
      position: absolute;
      top: 5px;
      height: 11px;
      right: -5px;
      background: ${({ theme }) => theme.color.darkGrey};
    }
  }
`

// SUBSECTION

// ACTIVITIES

const ActivitiesWrapper = styled.div<{ weekView?: boolean }>`
  position: relative;
  width: 100%;
  overflow-x: scroll;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  &:before {
    content: '';
    width: ${props =>
      props.weekView
        ? `${weekViewWidth * 52 - weekLineWidth}px`
        : `${nonWeekViewWidth * 52 - weekLineWidth}px`};
    height: 100%;
    position: absolute;
    top: 0px;
    background: ${props =>
      props.weekView
        ? 'repeating-linear-gradient(to right, transparent 0 162px,white 164px)'
        : 'repeating-linear-gradient(to right, transparent 0 138px, white 140px)'};
  }
`

const ActivitiesRow = styled.div`
  height: 34px;
  width: ${(props: { weekView: boolean }) =>
    props.weekView ? weekViewWidth * 52 : nonWeekViewWidth * 52}px;
  margin: 5px 0;

  &:first-of-type {
    margin-top: 10px;
  }
  &:last-of-type {
    margin-bottom: 10px;
  }
`

const ScrollableRow = styled.div`
  overflow-x: scroll;
  width: ${nonWeekViewWidth * 52}px;
  height: 48px;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`

const BrandName = styled.div`
  text-transform: capitalize;
`

const PrivateIconWrapper = styled.div`
  width: 14px;
  height: 14px;
  background-color: ${({ theme }) => theme.color.white};
  border-radius: 50%;
  position: absolute;
  top: 23px;
`

export {
  ActivitiesRow,
  ActivitiesWrapper,
  BrandName,
  ManagerButton,
  ButtonManager,
  ManagerWrapper,
  ScrollableRow,
  SectionHeader,
  SectionTitle,
  SectionWrapper,
  PrivateIconWrapper,
}
