import React from 'react'
import { StarSvg } from './Icon.styles'

interface KeyActivityIconProps {
  color: string
  size?: number
}

const KeyActivityIcon = ({
  color,
  size = 14,
  ...rest
}: KeyActivityIconProps) => {
  return (
    <StarSvg
      height={size}
      width={size}
      viewBox={`0 0 ${size} ${size}`}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...rest} // eslint-disable-line react/jsx-props-no-spreading
    >
      <defs>
        <path
          id="a"
          d="m10.5469998 3.74584337c-.0696264-.20947487-.259546-.35778056-.4833511-.37759149l-3.05259081-.27111763-1.20640107-2.763111c-.0890702-.20300279-.29173719-.33402325-.51731717-.33402325s-.42832766.13102046-.5168331.33402325l-1.20640107 2.763111-3.05307491.27111763c-.22380504.02020556-.41332126.16851126-.48335109.37759149-.06962644.20947489-.00532486.43923427.16394081.58446177l2.30751534 1.97943502-.6803705 2.93154332c-.04977927.21555234.03574102.43844498.21856085.56772901.09826769.06985127.21372008.10473745.32957589.10473745.09955856 0 .19919779-.02588838.2881873-.07798085l2.63225048-1.53972719 2.6317664 1.53972719c.19306611.11286703.43583083.10252746.61824725-.0267566.18281983-.12928403.26834014-.35217667.21856087-.56772901l-.6803705-2.93154332 2.30751533-1.97943502c.169185-.1452275.2335673-.3745133.1639408-.58446177z"
        />
        <mask id="b" fill={color}>
          <use fill={color} fillRule="evenodd" xlinkHref="#a" />
        </mask>
      </defs>
      <g fill="none" fillRule="evenodd" transform="translate(1.75 2.333333)">
        <use fill={color} fillRule="nonzero" xlinkHref="#a" />
        <g fill={color} mask="url(#b)">
          <path d="m0 0h14v14h-14z" transform="translate(-1.75 -2.333333)" />
        </g>
      </g>
    </StarSvg>
  )
}

export default KeyActivityIcon
