import styled from '@emotion/styled/macro'

const LogoutSvg = styled.svg`
  fill: currentColor;
  height: 14px;
  margin: 3px 4px 0 3px;
`

const ReorderSvg = styled.svg`
  fill: currentColor;
  margin-right: 10px;
  height: 17px;
  width: 20px;
`

const StarSvg = styled.svg`
  display: inline-block;
  flex-shrink: 0;
  vertical-align: middle;
  fill: currentColor;
`

export { ReorderSvg, LogoutSvg, StarSvg }
