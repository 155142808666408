import React from 'react'
import GenericError from './GenericError'
import { Error, CustomErrorViewProps } from '../../../types'

interface ErrorBoundaryState {
  error: Error | null
}

interface ErrorBoundaryProps {
  CustomErrorView?: React.ComponentType<CustomErrorViewProps>
  errorProps?: object
}

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  state: ErrorBoundaryState = { error: null } // eslint-disable-line

  static getDerivedStateFromError(error: Error) {
    return { error }
  }

  resetError = () => this.setState({ error: null })

  // props needed in a CustomErrorView can be passed via an "errorProps" prop
  // eg: <ErrorBoundary CustomErrorView={YourView} errorProps={{ yourProp }}>
  render() {
    const { error } = this.state
    const { children, CustomErrorView, errorProps } = this.props

    if (error) {
      return CustomErrorView ? (
        <CustomErrorView
          error={error}
          resetError={this.resetError}
          errorProps={errorProps}
        />
      ) : (
        <GenericError message={error.message} />
      )
    }

    return children
  }
}

export default ErrorBoundary
