import { sectionGroupList } from '@oneplan/common/src/sectionGroups'
import { SectionGroup } from '@oneplan/common/src/types'
import groupSectionsByGroupId from './groupSectionsByGroupId'
import {
  SectionNode,
  GroupedSections,
  FilterSection,
  QuarterSection,
} from '../../types'
import { quarterSectionWhatPlans } from '../i18n/copy'

const getGroupInfo = (groupId: string) => {
  const groupInfo = sectionGroupList.filter(
    (group: SectionGroup) => group.id === groupId
  )[0]
  return groupInfo || {}
}

const removeEmptyChildren = (section: FilterSection) => {
  if (section?.children?.length) return section
  return {
    value: section.value,
    groupId: section.groupId,
    label: section.label,
  }
}

const formatSectionList = (
  sections: FilterSection[] | QuarterSection[],
  type: 'filter' | 'quarterExport' = 'filter'
) => {
  const expandedList: string[] = []
  const groupedSections: GroupedSections = groupSectionsByGroupId(sections)

  const sectionList: SectionNode[] = Object.keys(groupedSections)
    .map(groupId => {
      const { label: rawLabel, position } = getGroupInfo(groupId)
      expandedList.push(groupId)

      let label = rawLabel
      if (type === 'quarterExport' && groupId === 'whatPlans') {
        label = quarterSectionWhatPlans
      }

      return {
        value: groupId,
        label,
        showCheckbox: false,
        position,
        children: groupedSections[groupId].map(removeEmptyChildren),
      }
    })
    .sort((a, b) => a.position - b.position)

  return { sectionList, expandedList }
}

export default formatSectionList
