import styled from '@emotion/styled/macro'
import { FavouritesFilled } from '@jsluna/icons'
import { KeyActivityIcon as StarIcon } from '../../icons'

const CustomOptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 0;
  position: relative;
`

const CustomOptionsSectionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 480px;
`

const CustomOptionsSectionBrandImg = styled.img`
  max-height: 28px;
  max-width: 28px;
  height: 100%;
  width: 100%;
`

const KeyActivityMarker = styled.div<{ color: string }>`
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  max-height: 28px;
  max-width: 28px;
  border-radius: 50%;
  margin-right: 5px;
  background-color: ${props => props.color};
  border: 2px solid ${props => props.color};
  position: relative;
`

const CustomOptionsSectionTitle = styled.div`
  font-size: 15px;
`

const BoldSectionTitle = styled.span`
  font-weight: bold;
`

const CustomOptionsActivityTitle = styled.div<{ isKeyActivity: boolean }>`
  font-size: 17px;
  line-height: 1.33333;
  font-family: MaryAnn;
  font-weight: 600;
  margin-left: ${props => (props.isKeyActivity ? '36px' : '0')};
`
const MappedActivityTitle = styled.div`
  font-size: 15px;
  line-height: 1;
  font-family: MaryAnn;
  font-weight: 600;
  margin-left: 0px;
  color: #f06c09;
`
const CustomOptionsMapActivityTitle = styled.div`
  font-size: 15px;
  line-height: 1.33333;
  font-family: MaryAnn;
  font-weight: 600;
`

const CustomOptionsDate = styled.div<{ isKeyActivity: boolean }>`
  margin-left: ${props => (props.isKeyActivity ? '36px' : '46px')};
  font-size: 13px;
`

const NonKeyActivityDescription = styled.div`
  margin-left: 36px;
  width: 450px;
  color: ${({ theme }) => theme.color.darkGrey};
`

const KeyActivityIcon = styled(StarIcon)`
  margin: 0 5px 0 30px;
`
const ImpActivityIcon = styled(FavouritesFilled)`
  margin: 0 5px 0 30px;
  width: 17px;
`
const ImpActivityIconHome = styled(FavouritesFilled)`
  width: 12px;
`
const CustomOptionsMapDate = styled.div`
  font-size: 12px;
`

export {
  CustomOptionsWrapper,
  CustomOptionsSectionWrapper,
  CustomOptionsSectionBrandImg,
  CustomOptionsSectionTitle,
  BoldSectionTitle,
  CustomOptionsActivityTitle,
  CustomOptionsDate,
  KeyActivityMarker,
  NonKeyActivityDescription,
  KeyActivityIcon,
  ImpActivityIcon,
  ImpActivityIconHome,
  CustomOptionsMapDate,
  CustomOptionsMapActivityTitle,
  MappedActivityTitle,
}
