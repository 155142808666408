import graphQLEndpoint from '@oneplan/config/src/graphQLEndpoint'

const endpoint = graphQLEndpoint()

const config = {
  retry: false,
  refetchOnWindowFocus: false,
}

export { endpoint, config }
