import { useQuery } from 'react-query'
import { Calendar, Plinths } from '@oneplan/common/src/types'
import { request } from 'graphql-request'
import GET_CALENDAR from '../../graphql/GET_CALENDAR'
import { endpoint, config } from './query.config'

interface CalendarResponse {
  calendar: Calendar
  plinthDates: Plinths[]
}

const KEY = 'calendar'

const useCalendarData = (financialYearId: string) => {
  return useQuery<CalendarResponse, Error>(
    [KEY, financialYearId],
    async () => request(endpoint, GET_CALENDAR, { financialYearId }),
    {
      ...config,
      enabled: financialYearId !== '',
    }
  )
}

export default useCalendarData
