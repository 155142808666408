import { useMutation } from 'react-query'
import { request } from 'graphql-request'
import GET_FILTER_VIEW_EXPORT from '../../graphql/GET_FILTER_VIEW_EXPORT'
import { endpoint } from './query.config'
import { Options } from '../../../types'

interface Variables {
  financialYearId: string
  tagIds: string[]
  sectionIds: string[]
  subSectionIds: string[]
  brandIds: string[]
  colorIds: string[]
}

const useFilterExport = (options: Options) =>
  useMutation(
    async (variables: Variables) =>
      request(endpoint, GET_FILTER_VIEW_EXPORT, variables),
    options
  )

export default useFilterExport
