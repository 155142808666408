import styled from '@emotion/styled/macro'
import { ArrowUp, Plus, Minus } from '@jsluna/icons'
import { motion } from 'framer-motion'
import { css } from '@emotion/react/macro'

const IconStyle = ({ theme }: any) => css`
  height: 100%;
  width: auto;
  padding: 5px;
  border-radius: 50px;
  background-color: transparent;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: ${theme.color.grey};
  }
`

const Heading = styled.div<{ sidepanelStyle: boolean }>`
  font-family: 'MaryAnn';
  font-size: ${props => (props.sidepanelStyle ? '20px' : '17px')};
  font-weight: 800;
  cursor: pointer;
  border-radius: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  height: 31px;
  position: relative;
  background-color: transparent;
  transition: background-color 0.2s ease-in-out;
  color: ${props =>
    props.sidepanelStyle
      ? props.theme.color.black
      : props.theme.color.darkGrey};

  &:hover {
    background-color: ${props => props.theme.color.whiteSmoke};
  }
`

const HeadingWihtoutTitle = styled.div`
  font-family: 'MaryAnn';
  font-size: 17px;
  font-weight: 800;
  cursor: pointer;
`

const Title = styled.div<{ htmlFor: string | null | undefined }>`
  flex: 1 0 auto;
`

const Arrow = styled(ArrowUp)`
  ${IconStyle}
`
const PlusIcon = styled(Plus)`
  ${IconStyle}
`
const MinusIcon = styled(Minus)`
  ${IconStyle}
`
const Content = styled(motion.div)`
  margin-bottom: 10px;
  display: grid;
`

const IconWrapper = styled(motion.div)`
  flex: 0 0 auto;
  display: flex;
  height: ${(props: { nonKeyActivityStyle?: boolean }) =>
    props.nonKeyActivityStyle ? '24px' : '100%'};
  position: ${(props: { nonKeyActivityStyle?: boolean }) =>
    props.nonKeyActivityStyle && 'absolute'};
  right: ${(props: { nonKeyActivityStyle?: boolean }) =>
    props.nonKeyActivityStyle && '4px'};
  top: ${(props: { nonKeyActivityStyle?: boolean }) =>
    props.nonKeyActivityStyle && '22px'};
`

export {
  Heading,
  Title,
  Arrow,
  Content,
  PlusIcon,
  IconWrapper,
  MinusIcon,
  HeadingWihtoutTitle,
}
