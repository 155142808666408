import styled from '@emotion/styled/macro'
import { css } from '@emotion/react/macro'
import { ArrowRight } from '@jsluna/icons'
import { IconButton } from '../Buttons/Buttons.styles'

const expression = /calendar-cell-week-(.*?)/g
const weekViewWidth = 1148
const nonWeekViewWidth = 140

const BorderRadius = css`
  border-radius: 3px;
`

const Wrapper = styled.div`
  display: flex;
`

const HeadWrapper = styled.div`
  max-width: 200px;
  width: 100%;
  display: inline-block;
  flex-shrink: 0;
`

const Title = styled.div`
  color: ${({ theme }) => theme.color.darkGrey};
  font-family: 'MaryAnn';
  font-size: 15px;
  font-weight: 800;
  padding: 10px;
  float: left;
`

const Quarters = styled.div`
  display: flex;
  overflow-x: scroll;
  position: relative;
  color: ${({ theme }) => theme.color.darkGrey};
  font-family: 'MaryAnn';
  font-size: 15px;
  font-weight: 800;
  padding: 10px 0;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`

const Quarter = styled.div`
  min-width: ${(props: { weeks: number; weekView: boolean }) =>
    props.weekView
      ? props.weeks * (weekViewWidth / 7) * 7
      : props.weeks * (nonWeekViewWidth / 7) * 7}px;
  flex: 1 0 auto;
`

const QuarterText = styled.span`
  position: sticky;
  left: 0;
  padding-left: 10px;
`

const RowHeader = styled.div`
  background-color: ${({ theme }) => theme.color.grey};
  ${BorderRadius}
  padding: 10px;
  font-size: 13px;
  color: ${({ theme }) => theme.color.darkGrey};
  margin-bottom: 2px;
`

const BodyWrapper = styled.div`
  display: block;
  position: relative;
  overflow-x: scroll;
  overflow-y: hidden;
  min-height: 100%;
  padding-bottom: ${(props: { visibleScroll?: boolean }) =>
    props.visibleScroll ? '10px' : '0px'};
  &::-webkit-scrollbar {
    height: ${(props: { visibleScroll?: boolean }) =>
      props.visibleScroll ? '5px' : 0};
  }
  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.color.white};
  }
  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.color.wolfGrey};
    border-radius: 10px;
    width: 30px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.color.darkGrey};
  }
`

const BodyWrapperOuter = styled.div`
  position: relative;
  width: 100%;
  padding-left: 10px;
  overflow: hidden;
`

const CalendarRow = styled.div<{ weekView: boolean; isHidden: boolean }>`
  width: ${props =>
    props.weekView ? weekViewWidth * 52 : nonWeekViewWidth * 52}px;
  margin-bottom: ${props => (props.isHidden ? '0' : '2px')};
  display: flex;
  flex-wrap: nowrap;
  height: ${props => (props.isHidden ? '0' : '20px')};
  align-items: center;
  opacity: ${props => (props.isHidden ? '0' : '1')};
  position: relative;
`

const CalendarPlinth = styled(CalendarRow)`
  &:after {
    content: '';
    width: 100%;
    height: 1px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    z-index: 0;
    background-color: ${({ theme }) => theme.color.grey};
  }
`

const calendarCellWidth = (weekView: boolean, weekCount: number) =>
  `${
    weekView
      ? `${weekViewWidth * weekCount}px`
      : `${nonWeekViewWidth * weekCount}px`
  }`

const CalendarCellPeriod = styled.div(
  (props: { weekView: boolean; weekCount: number }) => css`
    width: ${calendarCellWidth(props.weekView, props.weekCount)};
    min-width: ${calendarCellWidth(props.weekView, props.weekCount)};
    max-width: ${calendarCellWidth(props.weekView, props.weekCount)};
    padding: 0 1px;
  `
)

const CalendarCellWeek = styled(CalendarCellPeriod)<{
  weekView: boolean
  weekCount: number
  calendarCellWeekStyle: boolean
  fixedCalendarRowsLength: number
  isCalendarCollapsed?: boolean
}>`
  &:after {
    content: ${props => props.calendarCellWeekStyle && `''`};
    background: ${({ theme }) => theme.color.whiteSmoke};
    width: ${props =>
      props.weekView ? `${weekViewWidth - 2}px` : `${nonWeekViewWidth - 2}px`};
    border-radius: 3px;
    position: absolute;
    height: ${props =>
      props.isCalendarCollapsed
        ? `${props.fixedCalendarRowsLength * 20 +
            props.fixedCalendarRowsLength * 2}px`
        : '240px'};
  }
`

const CalendarCell = styled.div<{ avgTempStyle?: boolean }>`
  background-color: ${props =>
    props.avgTempStyle
      ? `${props.theme.color.whiteSmoke}`
      : `${props.theme.color.grey}`};
  ${BorderRadius}
  padding: 6px 10px;
  font-size: 10px;
  color: ${({ theme }) => theme.color.darkGrey};
  text-align: center;
  width: 100%;
  font-weight: 400;
  max-height: 20px;
  position: relative;
  padding-left: 20px;

  &:hover {
    background-color: ${({ theme, id }) =>
      id?.match(expression) && `${theme.color.lightGrey}`};
    cursor: ${props => (props.id?.match(expression) ? 'pointer' : 'default')};
  }
`

const CalendarCellWeekNumber = styled.span`
  background: ${({ theme }) => theme.color.white};
  border-radius: 50%;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  position: absolute;
  left: 3px;
  top: 2px;
`

const CalendarCellDayWrapper = styled.div`
  width: ${(props: { weekView: boolean }) =>
    props.weekView ? weekViewWidth / 7 : nonWeekViewWidth / 7}px;
  height: 20px;
  padding: 1px;
  flex-shrink: 0;
  margin-top: 1px;
  z-index: 2;
`

const CalendarCellDayPlinthWrapper = styled(CalendarCellDayWrapper)`
  position: absolute;
  left: ${(props: { startsAt: number; weekView: boolean }) =>
    props.weekView
      ? props.startsAt * (weekViewWidth / 7)
      : props.startsAt * (nonWeekViewWidth / 7)}px;
`

const handleColorType = (theme: any, highlight?: string) => {
  switch (highlight) {
    case 'today':
      return `color: ${theme.color.white}; background: ${theme.color.primary};`
    case 'sunday':
      return `color: ${theme.color.darkGrey}; background: ${theme.color.lightGrey};`
    case 'dayOfMonthDefault':
      return `color: ${theme.color.darkGrey}; background: ${theme.color.whiteSmoke};`
    default:
      return `color: ${theme.color.darkGrey}; background: ${theme.color.grey};`
  }
}

const CalendarCellDay = styled.div<{ highlight?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 50px;
  font-size: 10px;
  ${props => handleColorType(props.theme, props.highlight)};
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: ${({ theme }) => theme.color.primary};
    color: white;
  }
`

const ButtonArrowCollapseCalendar = styled(IconButton)``

const Arrow = styled(ArrowRight)`
  height: 20px;
  transform: ${(props: { rotate: boolean }) =>
    props.rotate ? 'rotate(90deg)' : 'rotate(-90deg)'};
`

export {
  Wrapper,
  HeadWrapper,
  Title,
  Quarters,
  Quarter,
  QuarterText,
  RowHeader,
  BodyWrapper,
  BodyWrapperOuter,
  CalendarRow,
  CalendarCellPeriod,
  CalendarCellWeek,
  CalendarCell,
  CalendarCellWeekNumber,
  CalendarCellDayWrapper,
  CalendarCellDayPlinthWrapper,
  CalendarCellDay,
  Arrow,
  ButtonArrowCollapseCalendar,
  CalendarPlinth,
  weekViewWidth,
  nonWeekViewWidth,
}
