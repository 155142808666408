// section
const sectionTitleId = 'sectionTitle'
const sectionDescriptionId = 'sectionDescription'
const sectionGroupId = 'sectionGroup'

// subsection
const subsectionTitleId = 'subsectionTitle'
const subsectionDescriptionId = 'description'
const subsectionPrivateId = 'privateSubsection'

// activity
const activityTitleId = 'activityTitle'
const activityDateId = 'date'
const activityDescriptionId = 'description'
const activityPublishedId = 'publishedActivity'
const keyActivityId = 'isKeyActivity'
const linkedActivityId = 'linkedActivityInputId'
const linkedImpActivityId = 'linkedImpActivityInputId'
const activityGroupId = 'activityGroupId'
const activityTagsId = 'tags'

// filters
const filterTagsId = 'filter-tags-input'
const filterColorsId = 'filter-color-input'

// quarter view export
const quarterCheckboxTreeId = 'quarter-sections-checkbox-tree'

export {
  sectionTitleId,
  sectionDescriptionId,
  sectionGroupId,
  subsectionTitleId,
  subsectionDescriptionId,
  subsectionPrivateId,
  activityTitleId,
  activityDateId,
  activityDescriptionId,
  activityPublishedId,
  keyActivityId,
  linkedActivityId,
  linkedImpActivityId,
  activityGroupId,
  activityTagsId,
  filterTagsId,
  filterColorsId,
  quarterCheckboxTreeId,
}
