import React from 'react'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import styled from '@emotion/styled/macro'
import { Label } from '@jsluna/react'
import { Tags } from '@oneplan/common/src/types'

const SelectStyled = styled(Select)`
  .react-select__control {
    border-color: ${({ theme }) => theme.color.greySmoke};
    &:hover {
      border-color: ${({ theme }) => theme.color.darkGrey};
      box-shadow: 0 0 0 1px ${({ theme }) => theme.color.darkGrey};
    }
  }
  .react-select__option {
    font-size: 15px;
    line-height: 24px;
    cursor: pointer;
    color: ${({ theme }) => theme.color.darkGrey};
    &--is-focused {
      background-color: ${({ theme }) => theme.color.lightGrey};
    }
    &:hover {
      background-color: ${({ theme }) => theme.color.lightGrey};
    }
  }
`

interface TagInputProps {
  id: string
  name: string
  label?: string
  testId?: string
  options?: Tags[]
  value?: Tags[]
  isLoading?: boolean
  isDisabled?: boolean
  onChange?: () => void
}

const animatedComponents = makeAnimated()

const TagInput: React.FC<TagInputProps> = ({
  id,
  name,
  label = '',
  testId,
  options = [],
  value = [],
  isLoading = false,
  isDisabled = false,
  onChange,
}) => (
  <>
    {label !== '' && <Label htmlFor={id}>{label}</Label>}
    <SelectStyled
      id={testId}
      inputId={id}
      name={name}
      value={value}
      options={options}
      isMulti
      components={animatedComponents}
      classNamePrefix="react-select"
      isLoading={isLoading}
      isDisabled={isDisabled}
      onChange={onChange}
    />
  </>
)

export { TagInput, SelectStyled }
