import React, { useState } from 'react'
import { buttonToggle } from '@oneplan/common/src/testids'
import { ArrowLeft } from '@jsluna/icons'
import { Wrapper, Toggle, ArrowWrapper, Content } from './Sidebar.styles'
import FilterForm from '../../components/Filter'

const Sidebar = () => {
  const [isOpen, toggle] = useState(true)
  return (
    <Wrapper
      animate={{
        width: isOpen ? '100%' : '0',
      }}
    >
      <Content
        animate={{
          opacity: isOpen ? 1 : 0,
        }}
      >
        {isOpen && <FilterForm />}
      </Content>
      <Toggle
        data-testid={buttonToggle}
        onClick={() => toggle(!isOpen)}
        title={isOpen ? 'Close the sidebar' : 'Open the sidebar'}
        isOpen={isOpen}
      >
        <ArrowWrapper
          animate={{
            rotate: isOpen ? 0 : 180,
          }}
          title={isOpen ? 'Close the sidebar' : 'Open the sidebar'}
        >
          <ArrowLeft
            role="img"
            aria-label={isOpen ? 'Close the sidebar' : 'Open the sidebar'}
          />
        </ArrowWrapper>
      </Toggle>
    </Wrapper>
  )
}
export default Sidebar
