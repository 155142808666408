import React from 'react'
import { Tags } from '@oneplan/common/src/types'
import {
  ErrorCircleIcon,
  FilterResponse,
  FilterResponseMessage,
  FilterTags,
} from './Filter.styles'

interface FilterErrorMessageProps {
  testId: string
  message: string
  tagIds?: Tags[]
}

const FilterErrorMessage: React.FC<FilterErrorMessageProps> = ({
  testId,
  message,
  tagIds = [],
}) => {
  return (
    <FilterResponse>
      <ErrorCircleIcon />
      <FilterResponseMessage data-testid={testId}>
        {message}
      </FilterResponseMessage>
      {tagIds.length > 0 && (
        <FilterTags>
          {tagIds.map((tag, i) => [i > 0 && ', ', tag.label])}
        </FilterTags>
      )}
    </FilterResponse>
  )
}

export default FilterErrorMessage
