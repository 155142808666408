import { useQuery } from 'react-query'
import { request } from 'graphql-request'
import { Tags, ColorFilter } from '@oneplan/common/src/types'
import { SubsectionActivities } from '../../../types'
import GET_SUBSECTION_ACTIVITIES from '../../graphql/GET_SUBSECTION_ACTIVITIES'
import { endpoint, config } from './query.config'
import useFilterStore from '../client/filterStore'
import useYearStore from '../client/yearStore'
import { queryClient } from '../../providers'

interface SubsectionActivitiesOptions {
  enabled: boolean
}

const KEY = 'subsectionActivities'

const useSubsectionActivitiesData = (
  subsectionId: string,
  options: SubsectionActivitiesOptions
) => {
  const selectedTags = useFilterStore(store => store.selectedTags)
  const tagIds =
    selectedTags?.length > 0 ? selectedTags.map((tag: Tags) => tag.value) : []
  const financialYearId = useYearStore(store => store.selectedFinancialYear)
  const selectedColors = useFilterStore(store => store.selectedColors)
  const colorIds =
    selectedColors?.length > 0
      ? selectedColors.map((color: ColorFilter) => color.value)
      : []
  return useQuery<SubsectionActivities, Error>(
    [KEY, { subsectionId, financialYearId, tagIds }],
    async () =>
      request(endpoint, GET_SUBSECTION_ACTIVITIES, {
        subsectionId,
        financialYearId,
        tagIds,
        brandIds: [],
        colorIds,
      }),
    {
      ...config,
      ...options,
    }
  )
}

const invalidateSubsectionActivitiesData = (subsectionId?: string) => {
  if (subsectionId) {
    return queryClient.invalidateQueries([KEY, { subsectionId }])
  }

  return queryClient.invalidateQueries(KEY)
}

export { useSubsectionActivitiesData, invalidateSubsectionActivitiesData }
