import { gql } from 'graphql-request'

const GET_SECTIONS = gql`
  query Sections(
    $financialYearId: String!
    $tagIds: [String]
    $sectionIds: [String]
    $subSectionIds: [String]
    $brandIds: [String]
    $colorIds: [String]
  ) {
    sections(
      financialYearId: $financialYearId
      tagIds: $tagIds
      sectionIds: $sectionIds
      subsectionIds: $subSectionIds
      brandIds: $brandIds
      colorIds: $colorIds
    ) {
      id
      title
      description
      groupId
      meta {
        canDelete
        canEdit
        canCreateChild
      }
      subsections {
        id
        title
        private
        description
        brandId
        meta {
          canDelete
          canEdit
          canCreateChild
        }
        activityRows {
          id
        }
      }
    }
  }
`

export default GET_SECTIONS
