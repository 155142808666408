import { css } from '@emotion/react/macro'
import emotionNormalize from 'emotion-normalize'

const fixedBodyClass = 'st-fixed'

const GlobalStyle = css`
  ${emotionNormalize}

  *,
  *:after,
  *:before {
    box-sizing: border-box;
  }

  html,
  body {
    min-height: 100%;
  }

  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
      'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
      'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &.${fixedBodyClass} {
      overflow: hidden;
    }
  }

  p a,
  li a {
    color: currentColor;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  #root {
    height: 100%;
    min-height: 100vh;
    max-height: 100vh;
    display: flex;
    flex-direction: column;
  }

  button {
    margin: initial;
    padding: initial;
    border: 0;
    font-weight: inherit;
    font-size: inherit;
    font-family: inherit;
    line-height: inherit;
    letter-spacing: inherit;
    text-align: inherit;
    text-transform: inherit;
    background-color: transparent;
  }
`
export { fixedBodyClass }
export default GlobalStyle
