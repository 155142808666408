import dayjs from 'dayjs'

const formatReadableDate = (isoDate: string): string => {
  const dayjsDate = dayjs(isoDate, 'YYYY-MM-DD')
  const formattedDate = dayjs(dayjsDate).format('ddd DD MMM YYYY')
  if (formattedDate === 'Invalid Date') {
    return `Invalid Input: ${isoDate}`
  }
  return formattedDate
}

export default formatReadableDate
