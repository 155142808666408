import Brands from '@oneplan/common/src/brands'
import { ErrorResponse } from '../../../types'
import { genericErrorMsg } from '../../i18n/copy'

export const defaultValues = {
  publishedActivity: false,
  isKeyActivity: false,
  isImpActivity: false,
  date: { from: new Date(), to: new Date() },
  tags: [],
  description: '',
  brands: [Brands.Group.id],
  contacts: {
    commercial: [],
    digital: [],
    external: [],
    internal: [],
    marketingCampaign: [],
    marketingProposition: [],
  },
  changeLog: {
    titleHistory: {},
    datesHistory: {},
    descriptionHistory: {},
  },
}

export const throwFormError = (error: ErrorResponse) => {
  const graphqlErrorMessage = error.response?.errors[0]?.message

  if (graphqlErrorMessage) {
    throw new Error(graphqlErrorMessage)
  } else {
    throw new Error(genericErrorMsg)
  }
}

export const colorList = [
  { label: 'Default', value: '#ffffff' },
  { label: 'Range Review (Non SSPOG)', value: '#f06c00' },
  { label: 'Range Review (SSPOG)', value: '#7f0442' },
  { label: 'Christmas', value: '#d42114' },
  { label: 'Range Refresh (Non SSPOG)', value: '#ffd900' },
  { label: 'Seasonal Range Review', value: '#008844' },
  { label: 'Range Refresh (SSPOG)', value: '#375ea9' },
  { label: 'Seasonal Range Refresh', value: '#39134c' },
  { label: 'Macro Space Rebalance', value: '#000000' },
  { label: 'Range Review Placeholder', value: '#912385' },
]
