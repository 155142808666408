import React from 'react'
import { buttonClose, buttonTryAgain } from '@oneplan/common/src/testids'
import { CustomErrorViewProps } from '../../../types'
import {
  ButtonWrapper,
  OutlinedPrimaryButton,
  PrimaryButton,
} from '../Buttons/Buttons.styles'
import { formErrorTitle, genericErrorMsg } from '../../i18n/copy'
import { FormErrorWrapper, FormErrorTitle, FormErrorBody } from './Error.styles'

const FormError: React.FC<CustomErrorViewProps> = ({
  error,
  errorProps = {},
  resetError,
}) => {
  const { handleClose } = errorProps
  return (
    <FormErrorWrapper>
      <div role="alert">
        <FormErrorTitle>{formErrorTitle}</FormErrorTitle>
        <FormErrorBody>{error?.message || genericErrorMsg}</FormErrorBody>
      </div>
      <ButtonWrapper>
        {handleClose && (
          <OutlinedPrimaryButton
            type="button"
            color="dark"
            fullWidth
            data-testid={buttonClose}
            onClick={handleClose}
          >
            Close
          </OutlinedPrimaryButton>
        )}
        {resetError && (
          <PrimaryButton
            type="button"
            color="dark"
            fullWidth
            data-testid={buttonTryAgain}
            onClick={resetError}
          >
            Try Again
          </PrimaryButton>
        )}
      </ButtonWrapper>
    </FormErrorWrapper>
  )
}

export default FormError
