import React, { useRef, useState } from 'react'
import {
  contextMenu,
  managerAddSection,
  reorderButton,
  reorderApplyButton,
  reorderCancelButton,
  contextMenuTrigger,
  filterExportButton,
} from '@oneplan/common/src/testids'
import { useOnClickOutside } from 'use-hooks'
import { Tags, ColorFilter } from '@oneplan/common/src/types'
import useYearStore from '../../state/client/yearStore'
import useFilterStore from '../../state/client/filterStore'
import useFilterExport from '../../state/server/getFilterExport'
import {
  ApplyIcon,
  CancelIcon,
  ContextMenuTrigger,
  ContextMenuWrapperDiv,
  PlusIcon,
  DownloadIcon,
} from './Sections.style'
import {
  ContextMenuItem,
  ContextMenuItemButton,
} from '../../elements/ContextMenu/ContextMenu.styles'
import ContextMenu from '../../elements/ContextMenu'
import ReorderIcon from '../../icons/ReorderIcon'
import UseFeature from '../../components/UseFeature'
import userInfoStore from '../../state/client/userInfo'
import useSubsectionReorderStore from '../../state/client/subsectionReorder'
import VisuallyHidden from '../../components/VisuallyHidden'
import { IconButton } from '../../components/Buttons/Buttons.styles'
import { GenericError } from '../../components/Error'

const SectionContextMenu: React.FC<{
  onAddSectionClick: Function
}> = ({ onAddSectionClick }) => {
  const {
    isReorderMode,
    toggleReorderMode,
    setApplyReorder,
  } = useSubsectionReorderStore(store => ({
    isReorderMode: store.isReorderMode,
    toggleReorderMode: store.toggleReorderMode,
    setApplyReorder: store.setApplyReorder,
  }))
  const financialYearId = useYearStore(state => state.selectedFinancialYear)
  const selectedTags = useFilterStore(store => store.selectedTags)
  const selectedColors = useFilterStore(store => store.selectedColors)
  const sectionIds = useFilterStore(store => store.sectionIds)
  const subSectionIds = useFilterStore(store => store.subSectionIds)
  const brandIds = useFilterStore(store => store.subSectionBrandIds)

  const [canCreateSections, canReorderSections] = userInfoStore(store => [
    store.permissions.canCreateSections,
    store.permissions.canReorderSections,
  ])
  const noPermissions = !canCreateSections && !canReorderSections

  const [isOpenContextMenu, setContextMenu] = useState(false)
  const ref = useRef() as React.MutableRefObject<HTMLInputElement>
  useOnClickOutside(ref, () => setContextMenu(false))

  const handleClick = () => onAddSectionClick(true)

  const handleReorder = () => {
    setContextMenu(!isOpenContextMenu)
    toggleReorderMode()
  }

  const cancelReorder = () => {
    setContextMenu(false)
    toggleReorderMode()
  }
  const { mutate, isError, isLoading } = useFilterExport({
    onSuccess: data => {
      window.open(data.exportFilterView.url, '_parent')
    },
  })
  if (isError) {
    return <GenericError />
  }
  const exportFilterView = () => {
    setContextMenu(false)
    mutate({
      financialYearId,
      tagIds:
        selectedTags?.length > 0
          ? selectedTags.map((tag: Tags) => tag.value)
          : [],
      sectionIds,
      subSectionIds,
      brandIds,
      colorIds:
        selectedColors?.length > 0
          ? selectedColors.map((color: ColorFilter) => color.value)
          : [],
    })
  }
  const handleApplyReorder = () => {
    setContextMenu(!isOpenContextMenu)
    setApplyReorder(true)
  }

  return (
    <ContextMenuWrapperDiv ref={ref}>
      <IconButton
        onClick={() => setContextMenu(!isOpenContextMenu)}
        data-testid={contextMenuTrigger}
        disabled={noPermissions}
      >
        <ContextMenuTrigger aria-hidden />
        <VisuallyHidden>Section menu</VisuallyHidden>
      </IconButton>

      <ContextMenu open={isOpenContextMenu} testId={contextMenu}>
        <ContextMenuItem isDivider>
          <UseFeature when={canCreateSections}>
            <ContextMenuItemButton
              onClick={handleClick}
              data-testid={managerAddSection}
            >
              <PlusIcon aria-hidden />
              Add Section
            </ContextMenuItemButton>
          </UseFeature>
        </ContextMenuItem>
        <ContextMenuItem isDivider>
          <UseFeature when={canCreateSections}>
            <ContextMenuItemButton
              onClick={exportFilterView}
              data-testid={filterExportButton}
              disabled={isLoading}
            >
              <DownloadIcon aria-hidden />
              Export filter activities
            </ContextMenuItemButton>
            {isLoading ? (
              'Downloading...'
            ) : (
              <>
                <VisuallyHidden>Downloaded</VisuallyHidden>
              </>
            )}
          </UseFeature>
        </ContextMenuItem>

        <UseFeature when={canReorderSections}>
          <ContextMenuItem>
            <ContextMenuItemButton
              onClick={handleReorder}
              disabled={isReorderMode}
              data-testid={reorderButton}
            >
              <ReorderIcon />
              Reorder
            </ContextMenuItemButton>
          </ContextMenuItem>
          <ContextMenuItem>
            <ContextMenuItemButton
              onClick={handleApplyReorder}
              disabled={!isReorderMode}
              data-testid={reorderApplyButton}
            >
              <ApplyIcon aria-hidden />
              Apply reorder
            </ContextMenuItemButton>
          </ContextMenuItem>
          <ContextMenuItem>
            <ContextMenuItemButton
              onClick={cancelReorder}
              disabled={!isReorderMode}
              data-testid={reorderCancelButton}
            >
              <CancelIcon aria-hidden />
              Cancel reorder
            </ContextMenuItemButton>
          </ContextMenuItem>
        </UseFeature>
      </ContextMenu>
    </ContextMenuWrapperDiv>
  )
}

export default SectionContextMenu
