import Brands from '@oneplan/common/src/brands'
import theme from '../styles/theme'

const colorPerBrand = {
  [Brands.Argos.id]: theme.brands.argos,
  [Brands.Tu.id]: theme.brands.tu,
  [Brands.Sainsburys.id]: theme.brands.sainsburysHome,
  [Brands.SainsburysBank.id]: theme.brands.sainsburysBank,
  [Brands.SainsburysEnergy.id]: theme.brands.sainsburysEnergy,
  [Brands.Habitat.id]: theme.brands.habitat,
  [Brands.Nectar.id]: theme.brands.nectar,
  [Brands.Group.id]: theme.brands.group,
  [Brands.Other.id]: theme.brands.other,
}

const getBrandColor = (brandId = ''): string =>
  colorPerBrand[brandId] || colorPerBrand[Brands.Group.id]

export default getBrandColor
