import React from 'react'
import { ScrollSyncPane } from 'react-scroll-sync'
import { activitiesWrapper } from '@oneplan/common/src/testids'
import { ActivitiesWrapper, ScrollableRow } from './Section.styles'
import { ActivitiesProps, ActivityRow } from '../../../types'
import ActivitiesRow from '../Activity/ActivitiesRow'
import useCalendarStore from '../../state/client/calendarStore'
import useYearStore from '../../state/client/yearStore'

const Activities: React.FC<ActivitiesProps> = ({
  activityRows,
  subsectionBrand,
  subsectionTitle,
  subsectionId,
  sectionTitle,
}) => {
  const weekView = useCalendarStore(state => state.weekView)
  const financialYearDateRange = useYearStore(state =>
    state.getFinancialYearDateRange()
  )

  if (!activityRows) return null
  return (
    <ScrollSyncPane>
      <ActivitiesWrapper
        weekView={weekView}
        data-testid={activitiesWrapper}
        data-activities-name={`${subsectionTitle}-${activitiesWrapper}`}
      >
        {activityRows.length > 0 &&
          activityRows.map((row: ActivityRow) => (
            <ActivitiesRow
              key={row.id}
              row={row}
              weekView={weekView}
              subsectionBrand={subsectionBrand}
              subsectionTitle={subsectionTitle}
              subsectionId={subsectionId}
              sectionTitle={sectionTitle}
              financialYearDateRange={financialYearDateRange}
            />
          ))}
        {activityRows.length === 0 && <ScrollableRow />}
      </ActivitiesWrapper>
    </ScrollSyncPane>
  )
}

export default Activities
