import styled from '@emotion/styled/macro'
import { keyframes } from '@emotion/react/macro'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`
const LoaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Loader = styled.span`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: 6px solid ${({ theme }) => theme.color.smokePrimary};
  border-top-color: ${({ theme }) => theme.color.primary};
  animation: ${rotate} 1s linear infinite;
`

export { LoaderWrapper, Loader }
