import React from 'react'
import { Plinths, PlinthDataProps } from '@oneplan/common/src/types'
import { CalendarPlinthDays } from '../../components/Calendar'

const PlinthData: React.FC<PlinthDataProps> = ({ elements, isHidden }) => {
  return elements?.map((plinth: Plinths) => {
    return (
      <CalendarPlinthDays
        key={plinth.id}
        name={plinth.name}
        dates={plinth.dates}
        isHidden={isHidden}
      />
    )
  })
}

export default React.memo(PlinthData)
