import styled from '@emotion/styled/macro'
import { motion } from 'framer-motion'
import Lock from 'react-focus-lock'

const SidepanelBackground = styled(motion.button)`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 9000;
`

const SidepanelContainer = styled(motion.div)`
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  max-width: 600px;
  z-index: 9000;
  padding: 20px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.35);
  background-color: white;
  overflow-y: auto;
  padding-bottom: ${(props: { withPadding: boolean }) =>
    props.withPadding ? '80px' : '20px'};
`

const FocusLock = styled(Lock)`
  height: ${(props: { withPadding: boolean }) =>
    props.withPadding ? 'auto' : '100%'};
`

export { SidepanelBackground, SidepanelContainer, FocusLock }
