import React from 'react'
import { Label } from '@jsluna/react'
import 'react-quill/dist/quill.snow.css'
import Loader from '../Loader'

const Quill = React.lazy(() => import('react-quill'))

const Modules = {
  keyboard: { bindings: { tab: false } },
  clipboard: {
    matchVisual: false,
  },
  toolbar: [
    ['bold', 'italic', 'underline'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['link'],
  ],
}
const Formats = ['bold', 'italic', 'underline', 'list', 'link']

interface DescriptionInputProps {
  id: string
  label: string
  value?: string
  isDisabled?: boolean
  onChange?: Function
  testId: string
  defaultValue?: string
  className?: string
}

const DescriptionInput: React.FC<DescriptionInputProps> = ({
  id,
  label,
  onChange,
  testId,
  value,
  isDisabled,
  className,
}) => (
  <div className={className}>
    <Label htmlFor={id}>{label}</Label>
    <React.Suspense fallback={<Loader />}>
      <Quill
        modules={Modules}
        formats={Formats}
        id={id}
        data-testid={testId}
        onChange={editor => (onChange ? onChange(editor) : undefined)}
        style={{
          height: '150px',
          display: 'inline-block',
          width: '100%',
        }}
        value={onChange ? value : undefined}
        defaultValue={onChange ? undefined : value}
        readOnly={isDisabled}
      />
    </React.Suspense>
  </div>
)

export default DescriptionInput
