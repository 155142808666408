import styled from '@emotion/styled/macro'
import { ToastContainer } from 'react-toastify'
import { Tick } from '@jsluna/icons'

const StyledToastContainer = styled(ToastContainer)`
  .Toastify__toast {
    background-color: ${({ theme }) => theme.color.whiteSmoke};
    box-shadow: -1px 1px 4px 1px ${({ theme }) => theme.color.greySmoke};
    height: 84px;
    width: 320px;
  }
  .Toastify__progress-bar {
    background: ${({ theme }) => theme.color.success};
  }
`

const NotificationContainer = styled.div`
  color: ${({ theme }) => theme.color.blackSmoke};
  background-color: ${({ theme }) => theme.color.whiteSmoke};
`

const NotificationConfirmationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const NotificationStatus = styled.div`
  font-family: 'MaryAnn';
  font-size: 20px;
  font-weight: bold;
  margin-left: 10px;
`

const NotificationMsg = styled.div`
  margin: 5px 0 5px 40px;
`

const NotificationConfirmationSpan = styled.span`
  width: 30px;
  height: 30px;
  background-color: ${({ theme }) => theme.color.success};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
`

const NotificationConfirmedIcon = styled(Tick)`
  width: 24px;
  height: 22px;
  color: ${({ theme }) => theme.color.white};
`

export {
  StyledToastContainer,
  NotificationContainer,
  NotificationConfirmationWrapper,
  NotificationMsg,
  NotificationStatus,
  NotificationConfirmationSpan,
  NotificationConfirmedIcon,
}
