import React, { useState } from 'react'
import {
  managerEditSection,
  managerAddSubsection,
  sectionForm,
} from '@oneplan/common/src/testids'
import { ManagerWrapper, ManagerButton, ButtonManager } from './Section.styles'
import Sidepanel from '../Sidepanel'
import { ManagerSectionProps } from '../../../types'
import Loader from '../Loader'
import VisuallyHidden from '../VisuallyHidden'
import { ErrorBoundary, FormError } from '../Error'

export const lazyEditSectionForm = () => import('../Forms/EditSectionForm')
export const lazyAddSubsectionForm = () => import('../Forms/AddSubsectionForm')

const EditSectionForm = React.lazy(lazyEditSectionForm)
const AddSubsectionForm = React.lazy(lazyAddSubsectionForm)

// TODO: Refactor this two components with an reusable Button+Sidepanel component
const ManagerSection: React.FC<ManagerSectionProps> = ({
  id,
  canEdit,
  canCreate,
  subsectionLength,
  sectionTitle,
}) => {
  const [editIsOpen, toggleEdit] = useState(false)
  const [addIsOpen, toggleAdd] = useState(false)

  const handleAddSubsectionClose = () => toggleAdd(false)
  const handleEditSubsectionClose = () => toggleEdit(false)

  if (!canEdit && !canCreate) return null

  return (
    <ManagerWrapper>
      {canEdit && (
        <>
          <ButtonManager
            data-testid={managerEditSection}
            data-edit-section-title={`edit-${sectionTitle}`}
            onClick={() => toggleEdit(true)}
            onMouseEnter={lazyEditSectionForm}
          >
            Edit<VisuallyHidden>{` Section - ${sectionTitle}`}</VisuallyHidden>
          </ButtonManager>

          <Sidepanel
            isOpen={editIsOpen}
            handleClose={handleEditSubsectionClose}
          >
            <ErrorBoundary
              CustomErrorView={FormError}
              errorProps={{ handleClose: handleEditSubsectionClose }}
            >
              <React.Suspense fallback={<Loader />}>
                <EditSectionForm
                  data-testid={sectionForm}
                  handleClose={handleEditSubsectionClose}
                  sectionId={id}
                  subsectionLength={subsectionLength}
                />
              </React.Suspense>
            </ErrorBoundary>
          </Sidepanel>
        </>
      )}
      {canCreate && (
        <>
          <ManagerButton
            data-testid={`${managerAddSubsection}-${sectionTitle}`}
            onClick={() => toggleAdd(true)}
          >
            Add subsection
            <VisuallyHidden>{` - ${sectionTitle}`}</VisuallyHidden>
          </ManagerButton>
          <Sidepanel isOpen={addIsOpen} handleClose={handleAddSubsectionClose}>
            <ErrorBoundary
              CustomErrorView={FormError}
              errorProps={{ handleClose: handleAddSubsectionClose }}
            >
              <React.Suspense fallback={<Loader />}>
                <AddSubsectionForm
                  handleClose={handleAddSubsectionClose}
                  sectionId={id}
                />
              </React.Suspense>
            </ErrorBoundary>
          </Sidepanel>
        </>
      )}
    </ManagerWrapper>
  )
}

export default ManagerSection
