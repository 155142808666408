import React from 'react'
import { buttonClose, genericErrorMessage } from '@oneplan/common/src/testids'
import { ButtonWrapper, OutlinedPrimaryButton } from '../Buttons/Buttons.styles'
import { genericErrorMsg } from '../../i18n/copy'

interface ErrorProps {
  message?: string
  handleClose?: Function
}

const GenericError: React.FC<ErrorProps> = ({ message, handleClose }) => {
  return (
    <>
      <p data-testid={genericErrorMessage}>{message || genericErrorMsg}</p>
      {handleClose && (
        <ButtonWrapper>
          <OutlinedPrimaryButton
            type="button"
            color="dark"
            fullWidth
            data-testid={buttonClose}
            onClick={handleClose ? () => handleClose() : undefined}
          >
            Close
          </OutlinedPrimaryButton>
        </ButtonWrapper>
      )}
    </>
  )
}

export default GenericError
