import React, { useState } from 'react'
import { Brand } from '@oneplan/common/src/types'
import Brands from '@oneplan/common/src/brands'
import { CheckBoxTreeNode } from '../../../types'
import CheckBoxTree from '../CheckBoxTree'
import useFilterStore from '../../state/client/filterStore'

const value = 'whatBrands'

let brandsList = Object.values(Brands).map((brand: Brand) => ({
  label: brand.title,
  value: brand.id,
}))

const hideBrands = [
  'brand_argos_financial_services',
  'brand_sainsburys_bank',
  'brand_sainsburys_energy',
]

brandsList = brandsList.filter(brand => {
  if (!hideBrands.includes(brand.value)) {
    return true
  }
  return false
})

const nodes: CheckBoxTreeNode[] = [
  {
    value,
    label: 'What brand(s) do you want to see?',
    showCheckbox: false,
    children: brandsList,
  },
]

const FilterBrands: React.FC = () => {
  const subSectionBrandIds = useFilterStore(store => store.subSectionBrandIds)
  const setSubSectionBrandIds = useFilterStore(
    store => store.setSubSectionBrandIds
  )
  const [expandedList, setExpandedList] = useState<string[]>([value])

  const handleCheckedListChange = (checked: string[]) => {
    // don't send fake "whatBrands" value to the backend
    const brandIds = checked.filter(string => string !== value)
    setSubSectionBrandIds(brandIds)
  }

  return (
    <CheckBoxTree
      id="subsection-brand-checkbox-tree"
      nodes={nodes}
      checkedList={subSectionBrandIds}
      handleCheckedListChange={handleCheckedListChange}
      expandedList={expandedList}
      handleExpandedListChange={setExpandedList}
    />
  )
}

export default FilterBrands
