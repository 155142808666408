import React from 'react'
import { sectionTitle, sectionWrapper } from '@oneplan/common/src/testids'
import ManagerSection from './Manager'
import { SectionWrapper, SectionTitle, SectionHeader } from './Section.styles'
import UseFeature from '../UseFeature'
import Tooltip from '../Tooltip'
import { SectionProp } from '../../../types'

const Section: React.FC<SectionProp> = ({
  id,
  title,
  description,
  children,
  canEdit,
  canCreate,
  reorderMode,
  subsectionLength,
  isDraggingOver,
}) => {
  return (
    /* Key activities section will not be displayed in reorder mode */
    <SectionWrapper
      data-testid={sectionWrapper}
      data-section-name={title}
      isHidden={reorderMode && id.includes('KEYACT')}
      isDraggingOver={isDraggingOver}
    >
      <SectionHeader>
        <Tooltip label={description || 'No Description'}>
          <SectionTitle data-testid={sectionTitle}>{title}</SectionTitle>
        </Tooltip>
        <UseFeature when={!reorderMode}>
          <ManagerSection
            id={id}
            canCreate={canCreate}
            canEdit={canEdit}
            sectionTitle={title}
            subsectionLength={subsectionLength}
          />
        </UseFeature>
      </SectionHeader>
      <>{children}</>
    </SectionWrapper>
  )
}

export default Section
