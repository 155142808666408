import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { Dashboard, Login } from './pages'
import routes from './routes'
import Auth from './components/Auth'

const Router: React.FC = (): React.ReactElement => {
  return (
    <Auth>
      <Switch>
        <Route exact path={routes.home}>
          <Login />
        </Route>
        <Route exact path={routes.dashboard}>
          <Dashboard />
        </Route>
      </Switch>
    </Auth>
  )
}

export default Router
