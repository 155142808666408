const newFeatures = [
  {
    id: 'feature5',
    isChecked: false,
    title: 'Important Calendar Dates',
    date: 'Thursday 03 September 2020',
    description: `If you're looking for information such as pay days, school holidays and calendar dates - please make sure you've selected the 'Important Calendar Dates' box in the brand filter section. You'll then be able to find this information under "Other" in the filter pane.`,
  },
  {
    id: 'feature4',
    isChecked: false,
    title: 'Contacts',
    date: 'Friday 28 August 2020',
    description:
      'Activity from Q3 onwards now has key contact information, so if you want to find out more or have any questions about something specific on the plan, just click into an activity and you’ll be able to see who the Proposition and Campaign Managers are.',
  },
  {
    id: 'feature3',
    isChecked: false,
    title: 'Linked activity',
    date: 'Friday 28 August 2020',
    description:
      'Relevant activity is now linked in OnePlan. This means that if you click into one specific activity in the core plan section, you can easily see if there are related activities elsewhere on the plan (e.g. when there are relevant plinths live).',
  },
  {
    id: 'feature2',
    isChecked: false,
    title: 'A change to filters',
    date: 'Friday 28 August 2020',
    description:
      'We’ve changed the way information is laid out in OnePlan and updated the way filters work, to make it easier for you find what you’re looking for. The first time you login to OnePlan after Thursday 27th August, the filter panel on the left hand side will be open and you’ll only see the core view of the plan open. You can then change which brands you want to see and add or remove different sections of the plan. This view will be saved and automatically open next time you login to OnePlan, but you can use the clear filters button to start to again to create a bespoke view.',
  },
  {
    id: 'feature1',
    isChecked: false,
    title: 'Notifications in OnePlan',
    date: 'Friday 28 August 2020',
    description:
      'When we add exciting new features to OnePlan, you’ll see a notification in the top right hand corner by your initials. We’ll add a short description to explain what the new feature is and how it could help you use OnePlan… just like this!',
  },
]

export default newFeatures
