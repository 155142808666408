import React, { useEffect } from 'react'
import dayjs from 'dayjs'
import { calendarCellDate } from '@oneplan/common/src/testids'
import { CalendarDaysProps } from '../../../types'
import {
  CalendarRow,
  CalendarCellDayWrapper,
  CalendarCellDay,
} from './Calendar.styles'
import scrollCalendar from '../../utils/scrollCalendar'
import useYearStore from '../../state/client/yearStore'

const CalendarDates: React.FC<CalendarDaysProps> = ({
  days,
  isHidden,
  weekView,
}) => {
  const financialYearDateRange = useYearStore(state =>
    state.getFinancialYearDateRange()
  )
  const currentFinancialYear = useYearStore(state => state.currentFinancialYear)
  const selectedFinancialYear = useYearStore(
    state => state.selectedFinancialYear
  )

  useEffect(() => {
    const element =
      selectedFinancialYear === currentFinancialYear
        ? dayjs().format('YYYY-MM-DD')
        : dayjs(financialYearDateRange?.startDate).format('D MMMM')
    scrollCalendar(`calendar-cell-date-${element}`)
  }, [currentFinancialYear, selectedFinancialYear, financialYearDateRange])

  return (
    <>
      <CalendarRow isHidden={isHidden} weekView={weekView}>
        {days.map(day => {
          const { dayOfMonth, isoDate } = day
          const isToday = dayjs(isoDate).isSame(dayjs().format('YYYY-MM-DD'))
          const formattedFullDay = dayjs(isoDate).format('DD MMM YYYY')
          let highlightName = 'dayOfMonthDefault'
          if (isToday) {
            highlightName = 'today'
          }
          return (
            <CalendarCellDayWrapper
              key={isoDate}
              id={`calendar-cell-date-wrapper-${isoDate}`}
              data-testid={calendarCellDate}
              weekView={weekView}
            >
              <CalendarCellDay
                id={`calendar-cell-date-${isoDate}`}
                highlight={highlightName}
                data-testid={weekView ? formattedFullDay : isoDate}
              >
                {weekView ? formattedFullDay : dayOfMonth}
              </CalendarCellDay>
            </CalendarCellDayWrapper>
          )
        })}
      </CalendarRow>
    </>
  )
}

export default React.memo(CalendarDates)
