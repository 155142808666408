import React, { useRef, useState } from 'react'
import {
  contextMenu,
  managerEditSubsection,
  managerAddActivity,
  reorderButton,
  reorderApplyButton,
  reorderCancelButton,
  contextSubsectionMenuTrigger,
} from '@oneplan/common/src/testids'
import { ACTIVITY_REORDER } from '@oneplan/config/src/featureFlags'
import { useOnClickOutside } from 'use-hooks'
import {
  ApplyIcon,
  CancelIcon,
  ContextMenuTrigger,
  ContextMenuTriggerWrapper,
  ContextMenuWrapperDiv,
  PlusIcon,
  EditIcon,
} from './Subsection.styles'
import {
  ContextMenuItem,
  ContextMenuItemButton,
} from '../../elements/ContextMenu/ContextMenu.styles'
import ContextMenu from '../../elements/ContextMenu'
import ReorderIcon from '../../icons/ReorderIcon'
import UseFeature from '../UseFeature'
import userInfoStore from '../../state/client/userInfo'
import VisuallyHidden from '../VisuallyHidden'

const SubsectionContextMenu: React.FC<{
  onEditSubsectionClick: Function
  onAddActivityClick: Function
  subsectionTitle: string
}> = ({ onEditSubsectionClick, onAddActivityClick, subsectionTitle }) => {
  const [isOpenContextMenu, setContextMenu] = useState(false)
  const [isReorderApplied, setAppliedReorder] = useState(false)
  const ref = useRef() as React.MutableRefObject<HTMLInputElement>
  useOnClickOutside(ref, () => setContextMenu(false))

  const handleAddActivityClick = () => {
    onAddActivityClick(true)
  }

  const handleEditSubsectionClick = () => {
    onEditSubsectionClick(true)
  }
  const { canReorderActivities } = userInfoStore(state => state.permissions)

  const handleReorder = () => {
    setAppliedReorder(!isReorderApplied)
    setContextMenu(!isOpenContextMenu)
  }

  // TODO : Need to find a way to refresh data in react-beautiful-dnd
  const cancelReorder = () => {
    window.location.reload(false)
  }

  const handleApplyReorder = () => {
    setContextMenu(!isOpenContextMenu)
  }
  return (
    <>
      <ContextMenuWrapperDiv ref={ref}>
        <ContextMenuTriggerWrapper
          onClick={() => setContextMenu(!isOpenContextMenu)}
          data-testid={`${contextSubsectionMenuTrigger}-${subsectionTitle}`}
        >
          <ContextMenuTrigger aria-hidden />
          <VisuallyHidden>{`Subsection menu - ${subsectionTitle}`}</VisuallyHidden>
        </ContextMenuTriggerWrapper>

        <ContextMenu open={isOpenContextMenu} testId={contextMenu}>
          <ContextMenuItem>
            <ContextMenuItemButton
              onClick={handleEditSubsectionClick}
              data-testid={managerEditSubsection}
            >
              <EditIcon aria-hidden />
              Edit Subsection
            </ContextMenuItemButton>
          </ContextMenuItem>
          <ContextMenuItem isDivider>
            <ContextMenuItemButton
              onClick={handleAddActivityClick}
              data-testid={managerAddActivity}
            >
              <PlusIcon aria-hidden />
              Add Activity
            </ContextMenuItemButton>
          </ContextMenuItem>
          <UseFeature when={canReorderActivities && ACTIVITY_REORDER}>
            <ContextMenuItem>
              <ContextMenuItemButton
                onClick={handleReorder}
                disabled
                data-testid={reorderButton}
              >
                <ReorderIcon />
                Reorder Activities
              </ContextMenuItemButton>
            </ContextMenuItem>
            <ContextMenuItem>
              <ContextMenuItemButton
                onClick={handleApplyReorder}
                disabled
                data-testid={reorderApplyButton}
              >
                <ApplyIcon aria-hidden />
                Apply reorder
              </ContextMenuItemButton>
            </ContextMenuItem>
            <ContextMenuItem>
              <ContextMenuItemButton
                onClick={cancelReorder}
                disabled
                data-testid={reorderCancelButton}
              >
                <CancelIcon aria-hidden />
                Cancel reorder
              </ContextMenuItemButton>
            </ContextMenuItem>
          </UseFeature>
        </ContextMenu>
      </ContextMenuWrapperDiv>
    </>
  )
}

export default SubsectionContextMenu
