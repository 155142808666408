import styled from '@emotion/styled/macro'
import { darken } from 'polished'
import { FilledButton, OutlinedButton } from '@jsluna/button'

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 10px;
`

const SectionButtonWrapper = styled.div`
  margin-bottom: 20px;
  max-width: 185px;
  width: 100%;
`

const FixedButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  bottom: 0px;
  position: fixed;
  width: 100%;
  max-width: 600px;
  background-color: ${({ theme }) => theme.color.white};
  right: 0px;
  padding: 15px 20px;
  z-index: 99;
  box-shadow: 0px 0px 8px 0px ${({ theme }) => theme.color.greySmoke};
`

const PrimaryButton = styled(FilledButton)`
  margin: 0 5px;
`
const OutlinedPrimaryButton = styled(OutlinedButton)`
  margin: 0 5px;
`

const DangerButton = styled(PrimaryButton)`
  background-color: ${({ theme }) => darken('0.02', `${theme.color.error}`)};
  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.color.error} !important;
  }
`

const IconButton = styled.button`
  background-color: transparent;
  border-color: transparent;
  border-style: solid;
  border-width: 0;
  font-size: 20px;
  height: 36px;
  width: 36px;
  cursor: pointer;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  float: right;

  &:hover {
    color: ${({ theme }) => theme.color.primary};
    background-color: ${({ theme }) => theme.color.grey};
  }
`

export {
  ButtonWrapper,
  DangerButton,
  FixedButtonWrapper,
  IconButton,
  PrimaryButton,
  OutlinedPrimaryButton,
  SectionButtonWrapper,
}
