import styled from '@emotion/styled/macro'
import { Download, VoiceSearch } from '@jsluna/icons'

const AccountWrapper = styled.div`
  position: relative;
`

const AccountButton = styled.button`
  background: ${({ theme }) => theme.color.greySmoke};
  border-width: 0;
  font-family: 'MaryAnn';
  font-size: 16px;
  font-weight: bold;
  padding: 5px;
  cursor: pointer;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: ${({ theme }) => theme.color.primary};
  }

  &:after {
    content: ${(props: { isChecked: boolean; newFeaturesNumber: number }) =>
      props.newFeaturesNumber === 0 ? '' : `'${props.newFeaturesNumber}'`};
    color: ${({ theme }) => theme.color.white};
    font-size: 11px;
    background: ${props => !props.isChecked && props.theme.color.primary};
    border-radius: 50px;
    width: 14px;
    height: 14px;
    position: absolute;
    right: 0px;
    top: 0px;
    text-align: center;
  }
`

const DownloadIcon = styled(Download)`
  height: 20px;
  margin-right: 4px;
`

const NewFeaturesIcon = styled(VoiceSearch)`
  height: ${(props: { newfeaturestyle: boolean }) =>
    props.newfeaturestyle ? '30px' : '20px'};
  margin-right: 4px;
`

export { AccountWrapper, AccountButton, DownloadIcon, NewFeaturesIcon }
