import styled from '@emotion/styled/macro'
import Accordion from '../Accordion'

const ActivityButton = styled.button<{
  draft: boolean
  endDateAcrossYear: boolean
  startDateAcrossYear: boolean
  diffDays: boolean
  weekView: boolean
  days: number
  startsAt: number
  color: string
}>`
  position: absolute;
  height: 34px;
  width: ${props => (props.weekView ? props.days * 164 : props.days * 20)}px;
  left: ${props =>
    props.weekView ? props.startsAt * 164 : props.startsAt * 20}px;
  background-color: ${({ theme }) => theme.color.white};
  border: 2px solid ${props => (props.color ? props.color : '#e1e1e1')};
  opacity: ${props => (props.draft ? 0.7 : 1)};
  cursor: pointer;
  border-top-left-radius: ${props => (props.startDateAcrossYear ? '0' : '4px')};
  border-bottom-left-radius: ${props =>
    props.startDateAcrossYear ? '0' : '4px'};
  border-top-right-radius: ${props => (props.endDateAcrossYear ? '0' : '4px')};
  border-bottom-right-radius: ${props =>
    props.endDateAcrossYear ? '0' : '4px'};
`

const ActivityDataWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  left: 0;
  position: sticky;
  padding: 4px 5px;
  max-width: 100%;
  & > *:not(:last-child) {
    margin-right: 5px;
  }
`

const ActivityTitle = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  font-size: 13px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.darkGrey};
  white-space: nowrap;
  width: 100%;
  text-overflow: ellipsis;
`

const ActivityDots = styled(ActivityTitle)`
  display: flex;
  writing-mode: vertical-rl;
  font-size: 18px;
  font-weight: 900;
  align-items: center;
  justify-content: center;
  margin-left: 1px;
`

const ActivityDate = styled.span`
  font-size: 10px;
  font-weight: normal;
  color: ${({ theme }) => theme.color.darkGrey};
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
`

const ActivityDetailsWrapper = styled.div`
  padding: 0 0 15px 45px;
  &:first-of-type {
    padding-left: 0;
  }
`

const ActivityDetailsHorizontalLine = styled.div`
  border: 1px solid ${({ theme }) => theme.color.grey};
  width: 100%;
  display: inline-block;
`

const ActivityDetailsTitle = styled.div`
  font-family: MaryAnn;
  font-size: 20px;
  font-weight: bold;
  color: ${({ theme }) => theme.color.black};
  margin: 5px 0;
  line-height: 1.23;
`
const ActivityDetailsSubTitle = styled.span`
  font-family: MaryAnn;
  font-size: 16px;
  font-weight: bold;
  color: ${({ theme }) => theme.color.darkGrey};
  margin-bottom: 10px;
  display: block;
  line-height: 1.33;
`

const ActivityDetailsDescription = styled.div`
  width: 480px;
  white-space: pre-wrap;
  margin: 0;
  height: auto;
  line-height: 1.3;

  ol,
  ul {
    padding-left: 18px;
  }
`

const ActivityDetailsDate = styled.div`
  font-size: 15px;
  color: ${({ theme }) => theme.color.darkGrey};
  padding-left: 45px;
`

const ActivityDetailsBrand = styled.span`
  font-size: 14px;
  color: ${({ theme }) => theme.color.white};
  display: inline-flex;
  flex-direction: row;
  align-items: baseline;
  border-radius: 15px;
  padding: 5px 15px;
  margin: 5px 5px 0 0;
  background-color: ${(props: { color: string }) => `${props.color}`};
  height: 27px;
`

const ActivityDetailsTagsWrapper = styled.div`
  margin-bottom: 10px;
`

const ActivityDetailsTag = styled.span`
  font-size: 14px;
  color: ${({ theme }) => theme.color.darkGrey};
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme }) => theme.color.greySmoke};
  border-radius: 15px;
  padding: 5px 15px;
  margin: 5px 5px 0 0;
  min-height: 27px;
`
const ActivityDetailsContactWrapper = styled.div`
  margin-bottom: 15px;
`

const ActivityContactHeading = styled.span`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 3px;
  color: ${({ theme }) => theme.color.darkGrey};
  display: block;
`

const ActivityContactEmail = styled(ActivityContactHeading)`
  display: inline-block;
  font-weight: normal;
  margin-right: 5px;
  a {
    color: ${({ theme }) => theme.color.darkGrey};
    text-decoration: none;
  }
`

const ActivityBrandTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

const ActivityContactEmailLink = styled.a`
  &:hover {
    text-decoration: underline;
  }
`

const ActivityDetailsBreadCrumb = styled(ActivityDetailsDate)``

const ActivityDetailsVerticalLine = styled.div`
  left: 0;
  background-color: ${(props: { color?: string }) => `${props.color}`};
  position: absolute;
  top: 0;
  width: 5px;
  height: 100%;
  z-index: 100;
`

const StyledAccordion = styled(Accordion)`
  .accordion_content {
    display: block;
  }
`
export {
  ActivityContactEmail,
  ActivityContactHeading,
  ActivityDate,
  ActivityDots,
  ActivityDetailsBrand,
  ActivityDetailsDate,
  ActivityDetailsDescription,
  ActivityDetailsSubTitle,
  ActivityDetailsTag,
  ActivityDetailsTagsWrapper,
  ActivityDetailsTitle,
  ActivityDetailsWrapper,
  ActivityTitle,
  ActivityButton,
  ActivityDetailsContactWrapper,
  ActivityBrandTitleWrapper,
  ActivityContactEmailLink,
  ActivityDetailsBreadCrumb,
  ActivityDetailsVerticalLine,
  ActivityDetailsHorizontalLine,
  StyledAccordion,
  ActivityDataWrapper,
}
