import React from 'react'
import { ReactQueryDevtools } from 'react-query/devtools'
import { QueryClient, QueryClientProvider, QueryCache } from 'react-query'
import { ScrollSync } from 'react-scroll-sync'
import RouterProvider from './RouterProvider'
import ThemeProvider from './ThemeProvider'

const queryCache = new QueryCache()
const queryClient = new QueryClient({ queryCache })

interface ProvidersProps {
  children: React.ReactNode
  includeDevTools?: boolean
}

const Providers: React.FC<ProvidersProps> = ({
  children,
  includeDevTools = true,
}) => {
  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider>
        <ThemeProvider>
          <ScrollSync vertical={false}>
            <>{children}</>
          </ScrollSync>
        </ThemeProvider>
      </RouterProvider>
      {includeDevTools && <ReactQueryDevtools initialIsOpen={false} />}
    </QueryClientProvider>
  )
}

export { RouterProvider, ThemeProvider, queryClient }

export default Providers
