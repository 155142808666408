import React, { useMemo } from 'react'
import { useInView } from 'react-intersection-observer'
import {
  sectionContainer,
  subsectionContainer,
  subsectionTitle,
} from '@oneplan/common/src/testids'
import { Activities } from '../Section'
import { useSubsectionActivitiesData } from '../../state/server/subsectionActivities'
import UseFeature from '../UseFeature'
import SkeletonLoading from '../SkeletonLoading'
import Tooltip from '../Tooltip'
import {
  SubsectionWrapper,
  SubsectionHeader,
  SubsectionTitle,
  SubsectionSymbol,
  SubsectionTitleWrapper,
  SubsectionPrivateIcon,
  PrivateIconWrapper,
} from './Subsection.styles'
import getBrandColor from '../../utils/getBrandColor'
import getBrandIcon from '../../utils/getBrandIcon'
import lockIcon from '../../assets/lockIcon.svg'
import ManagerSubsection from './ManagerSubsection'

interface SubsectionProp {
  id: string
  title: string
  isPrivate: boolean
  brand: string
  brandName: string
  canEdit: boolean
  canCreate: boolean
  description: string
  numberOfRows: number
}

const Subsection: React.FC<SubsectionProp> = ({
  id,
  title,
  description,
  isPrivate,
  brand,
  brandName,
  canEdit,
  canCreate,
  numberOfRows,
}) => {
  const brandColor = useMemo(() => getBrandColor(brand), [brand])
  const brandIcon = useMemo(() => getBrandIcon(brand), [brand])

  const { ref, inView } = useInView({
    triggerOnce: true,
    root: document.getElementById(`${sectionContainer}`),
    rootMargin: '0px 0px 2000px 0px',
  })

  const { data, isError } = useSubsectionActivitiesData(id, {
    enabled: !!inView,
  })

  if (isError) {
    return <div>Error</div>
  }

  return (
    <SubsectionWrapper color={brandColor} isPrivate={isPrivate} ref={ref}>
      <SubsectionHeader>
        <Tooltip label={brandName}>
          <SubsectionSymbol
            color={brandColor}
            icon={brandIcon}
            isPrivate={isPrivate}
          >
            {isPrivate && (
              <PrivateIconWrapper>
                <SubsectionPrivateIcon icon={lockIcon} />
              </PrivateIconWrapper>
            )}
          </SubsectionSymbol>
        </Tooltip>
        <SubsectionTitleWrapper data-testid={subsectionContainer}>
          <Tooltip label={description || 'No Description'}>
            <SubsectionTitle
              data-testid={subsectionTitle}
              data-subsection-name={title}
            >
              {title}
            </SubsectionTitle>
          </Tooltip>
        </SubsectionTitleWrapper>
        <ManagerSubsection
          id={id}
          canCreate={canCreate}
          canEdit={canEdit}
          subsectionTitle={title}
          brandName={brand}
        />
      </SubsectionHeader>
      <UseFeature when={!data && !isError}>
        <SkeletonLoading numberOfRows={numberOfRows} />
      </UseFeature>
      <Activities
        brand={brand}
        activityRows={data?.activityRows}
        subsectionBrand={brand}
        subsectionTitle={title}
        subsectionId={id}
        sectionTitle={title}
      />
    </SubsectionWrapper>
  )
}

export default Subsection
