import React, { useState } from 'react'
import 'react-checkbox-tree/lib/react-checkbox-tree.css'
import CheckboxTree from 'react-checkbox-tree'
import {
  checkedIcon,
  expandOpenIcon,
  expandCloseIcon,
} from '@oneplan/common/src/testids'
import {
  ArrowRightIcon,
  TickIcon,
  ArrowDownIcon,
  MinusIcon,
  UnCheckIcon,
  CheckBoxTreeWrapper,
} from './CheckBoxTree.styles'
import { CheckBoxTreeViewProps } from '../../../types'

const CheckBoxTree: React.FC<CheckBoxTreeViewProps> = ({
  id,
  nodes,
  checkedList,
  expandedList,
  handleCheckedListChange,
  handleExpandedListChange,
  isDisabled = false,
  className,
}) => {
  // local values used when the component is uncontrolled
  const [localExpandedList, setLocalExpandedList] = useState<string[]>([])
  const [localCheckedList, setLocalCheckedList] = useState<string[]>([])

  if (
    (checkedList && !handleCheckedListChange) ||
    (expandedList && !handleExpandedListChange)
  )
    throw new Error(
      'When using CheckBoxTree as a controlled component you must provide an appropriate change handler'
    )

  const onCheck = (checked: string[]) => {
    if (handleCheckedListChange) handleCheckedListChange(checked)
    if (checkedList === undefined) setLocalCheckedList(checked)
  }

  const onExpand = (expanded: string[]) => {
    if (handleExpandedListChange) handleExpandedListChange(expanded)
    if (expandedList === undefined) setLocalExpandedList(expanded)
  }
  return (
    <CheckBoxTreeWrapper className={className}>
      <CheckboxTree
        nodes={nodes}
        checked={checkedList || localCheckedList}
        expanded={expandedList || localExpandedList}
        showNodeIcon={false}
        checkModel="all"
        id={id}
        expandOnClick
        onCheck={onCheck}
        onExpand={onExpand}
        icons={{
          check: <TickIcon data-testid={checkedIcon} />,
          expandClose: <ArrowRightIcon data-testid={expandCloseIcon} />,
          expandOpen: <ArrowDownIcon data-testid={expandOpenIcon} />,
          halfCheck: <MinusIcon />,
          uncheck: <UnCheckIcon />,
        }}
        disabled={isDisabled}
      />
    </CheckBoxTreeWrapper>
  )
}

export default CheckBoxTree
