import React from 'react'
import { ContextMenuArrow, ContextMenuDiv } from './ContextMenu.styles'

const ContextMenu: React.FC<{ open: boolean; testId: string }> = ({
  open = false,
  testId,
  children,
}) => {
  if (!open) return null
  return (
    <ContextMenuDiv data-testid={testId}>
      <ContextMenuArrow />
      {children}
    </ContextMenuDiv>
  )
}

export default ContextMenu
