import styled from '@emotion/styled/macro'
import { theme } from '../../styles'

const Container = styled.div`
  position: absolute;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30%;
  padding: 100px;
  max-width: 720px;
`

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`

const Title = styled.h1`
  font-family: MaryAnn;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${theme.color.blackSmoke};
  padding-left: 55px;
`

const Subtitle = styled(Title)`
  font-size: 18px;
  font-weight: normal;
`

const FormErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

const FormErrorTitle = styled.h2`
  font-family: MaryAnn;
  font-size: 20px;
  font-weight: bold;
  color: ${theme.color.blackSmoke};
`

const FormErrorBody = styled.p`
  font-size: 16px;
`

export {
  Container,
  LogoWrapper,
  Subtitle,
  Title,
  FormErrorWrapper,
  FormErrorTitle,
  FormErrorBody,
}
