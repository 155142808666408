import Brands from '@oneplan/common/src/brands'
import Sainsburys from '../assets/brandsIcon/sainsburys.svg'
import Argos from '../assets/brandsIcon/argos.svg'
import Bank from '../assets/brandsIcon/bank.svg'
import Group from '../assets/brandsIcon/group.svg'
import Habitat from '../assets/brandsIcon/habitat.svg'
import Nectar from '../assets/brandsIcon/nectar.svg'
import Other from '../assets/brandsIcon/other.svg'
import Tu from '../assets/brandsIcon/tu.svg'
import Energy from '../assets/brandsIcon/energy.svg'

const iconPerBrand = {
  [Brands.Argos.id]: Argos,
  [Brands.Tu.id]: Tu,
  [Brands.Sainsburys.id]: Sainsburys,
  [Brands.SainsburysBank.id]: Bank,
  [Brands.SainsburysEnergy.id]: Energy,
  [Brands.Other.id]: Other,
  [Brands.Habitat.id]: Habitat,
  [Brands.Nectar.id]: Nectar,
  [Brands.Group.id]: Group,
}

const getBrandIcon = (brandId = ''): string =>
  iconPerBrand[brandId] || iconPerBrand[Brands.Group.id]

export default getBrandIcon
