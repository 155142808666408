import React from 'react'
import {
  CalendarRow,
  CalendarCell,
  CalendarCellPeriod,
} from './Calendar.styles'
import { CalendarTempProps } from '../../../types'

const CalendarTemp: React.FC<CalendarTempProps> = ({
  elements,
  isHidden,
  weekView,
}) => {
  return (
    <>
      <CalendarRow isHidden={isHidden} weekView={weekView}>
        {elements.map(({ avgTemp }, index) => {
          const key = `${avgTemp} ${index}`
          return (
            <CalendarCellPeriod
              key={key}
              weekView={weekView}
              id={`calendar-cell-week-${avgTemp}`}
              data-testid={avgTemp}
              weekCount={1}
            >
              <CalendarCell id={`calendar-cell-${avgTemp}`} avgTempStyle>
                {avgTemp}
              </CalendarCell>
            </CalendarCellPeriod>
          )
        })}
      </CalendarRow>
    </>
  )
}

export default React.memo(CalendarTemp)
