import React from 'react'
import { clearFilterButton } from '@oneplan/common/src/testids'
import FilterTags from './FilterTags'
import FilterColor from './FilterColor'
import FilterBrands from './FilterBrands'
import { GenericError } from '../Error'
import UseFeature from '../UseFeature/UseFeature'
import CheckBoxTree from '../CheckBoxTree'
import useFilterForm from '../../hooks/useFilterForm'
import { NoDataMessage, PrimaryButton } from './Filter.styles'
import Loader from '../Loader'
import * as copy from '../../i18n/copy'
import { colorList } from '../Forms/FormsUtils'

const FilterForm: React.FC = () => {
  const {
    data,
    isLoading,
    isError,
    isFetching,
    tags,
    sectionIds,
    subSectionIds,
    sectionExpandedList,
    sectionNodeList,
    setSectionExpandedList,
    handleSectionSubsectionList,
    clearFilters,
  } = useFilterForm()

  if (isLoading) {
    return <Loader />
  }

  if (isError) {
    return <GenericError />
  }

  return (
    <>
      <FilterBrands />
      <UseFeature when={data?.sections.length === 0}>
        <NoDataMessage>{copy.noDataFound}</NoDataMessage>
      </UseFeature>
      <CheckBoxTree
        id="section-checkbox-tree"
        nodes={sectionNodeList}
        checkedList={[...subSectionIds, ...sectionIds]}
        handleCheckedListChange={handleSectionSubsectionList}
        expandedList={sectionExpandedList}
        handleExpandedListChange={setSectionExpandedList}
        isDisabled={isFetching}
      />
      <UseFeature when={Boolean(tags.length)}>
        <FilterTags tags={tags} isLoading={isLoading} />
        <FilterColor colors={colorList} isLoading={isLoading} />
      </UseFeature>
      <PrimaryButton
        type="button"
        fullWidth
        data-testid={clearFilterButton}
        disabled={isFetching}
        onClick={clearFilters}
      >
        Clear Filters
      </PrimaryButton>
    </>
  )
}

export default FilterForm
