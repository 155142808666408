import React, { useEffect } from 'react'
import dayjs from 'dayjs'
import { calendarCellDay } from '@oneplan/common/src/testids'
import { CalendarDaysProps } from '../../../types'
import {
  CalendarRow,
  CalendarCellDayWrapper,
  CalendarCellDay,
} from './Calendar.styles'
import Tooltip from '../Tooltip'
import scrollCalendar from '../../utils/scrollCalendar'
import useYearStore from '../../state/client/yearStore'

const CalendarDays: React.FC<CalendarDaysProps> = ({
  days,
  isHidden,
  weekView,
}) => {
  const financialYearDateRange = useYearStore(state =>
    state.getFinancialYearDateRange()
  )
  const currentFinancialYear = useYearStore(state => state.currentFinancialYear)
  const selectedFinancialYear = useYearStore(
    state => state.selectedFinancialYear
  )

  useEffect(() => {
    const element =
      selectedFinancialYear === currentFinancialYear
        ? dayjs().format('YYYY-MM-DD')
        : dayjs(financialYearDateRange?.startDate).format('D MMMM')
    scrollCalendar(`calendar-cell-day-${element}`)
  }, [currentFinancialYear, selectedFinancialYear, financialYearDateRange])

  return (
    <>
      <CalendarRow isHidden={isHidden} weekView={weekView}>
        {days.map(day => {
          const { dayOfWeek, dayOfWeekInitial, isoDate } = day
          const isSunday = dayOfWeekInitial === 'S' && dayOfWeek === 'Sun'
          const isToday = isoDate === dayjs().format('YYYY-MM-DD')
          const formattedDay = dayjs(isoDate).format('dddd')
          const formattedFullDay = dayjs(isoDate).format('ddd DD MMM YYYY')

          let highlightName = 'default'
          if (isToday) {
            highlightName = 'today'
          } else if (isSunday) {
            highlightName = 'sunday'
          }
          return (
            <Tooltip label={formattedFullDay} key={isoDate}>
              <CalendarCellDayWrapper
                id={`calendar-cell-day-wrapper-${isoDate}`}
                data-testid={calendarCellDay}
                weekView={weekView}
              >
                <CalendarCellDay
                  id={`calendar-cell-day-${isoDate}`}
                  highlight={highlightName}
                  data-testid={weekView ? formattedDay : isoDate}
                >
                  {weekView ? formattedDay : dayOfWeekInitial}
                </CalendarCellDay>
              </CalendarCellDayWrapper>
            </Tooltip>
          )
        })}
      </CalendarRow>
    </>
  )
}

export default React.memo(CalendarDays)
