import { CalendarRow } from './types'

const Periods: CalendarRow = {
  id: 'periods',
  value: 'Periods',
}

const Weeks: CalendarRow = {
  id: 'weeks',
  value: 'Week No. W/C Sun / Wed',
}

const WeekDays: CalendarRow = { id: 'weekDays', value: 'Week Days' }

const Date: CalendarRow = { id: 'date', value: 'Date' }

const AvgTemp: CalendarRow = { id: 'avgTemp', value: 'Avg Temp (LY)' }

const Clothing: CalendarRow = { id: 'clothing', value: 'Clothing POS Cycles' }

const GM: CalendarRow = { id: 'GM', value: 'GM Plinth Cycles' }

const FFP: CalendarRow = { id: 'FFP', value: 'Group 1 Cycles' }

const IPSBC: CalendarRow = {
  id: 'IPSBC',
  value: 'Group 2 Cycles',
}

const BHBP: CalendarRow = {
  id: 'BHBP',
  value: 'Group 3 Cycles',
}

const HBC: CalendarRow = { id: 'HBC', value: 'Group 3 H&B Cycles' }

export default {
  Periods,
  Weeks,
  WeekDays,
  Date,
  AvgTemp,
  FFP,
  IPSBC,
  BHBP,
  HBC,
  Clothing,
  GM,
}
