import React, { useState, useEffect } from 'react'
import { AnimatePresence } from 'framer-motion'
import {
  sidepanelContainer,
  sidepanelBackground,
} from '@oneplan/common/src/testids'
import {
  SidepanelBackground,
  SidepanelContainer,
  FocusLock,
} from './Sidepanel.styles'
import useFixedBody from '../../hooks/useFixedBody'
import VisuallyHidden from '../VisuallyHidden'

interface SidepanelProps {
  isOpen: boolean
  handleClose: React.MouseEventHandler<HTMLButtonElement>
  withPadding?: boolean
}

const Sidepanel: React.FC<SidepanelProps> = ({
  children,
  isOpen,
  handleClose,
  withPadding = true,
}) => {
  useFixedBody(isOpen)
  const stopPropagation = (e: React.MouseEvent) => e.stopPropagation()

  const [disabled, setDisabled] = useState(true)

  useEffect(() => {
    setDisabled(false)
  }, [isOpen])

  return (
    <AnimatePresence>
      {isOpen && (
        <>
          <SidepanelBackground
            key="Sidepanel-background"
            initial={{ opacity: 0, backdropFilter: 'blur(0px)' }}
            animate={{ opacity: 1, backdropFilter: 'blur(4px)' }}
            exit={{ opacity: 0, backdropFilter: 'blur(0px)' }}
            transition={{ duration: 0.5, delayChildren: 2 }}
            data-testid={sidepanelBackground}
            onClick={handleClose}
          >
            <VisuallyHidden>Close sidepanel</VisuallyHidden>
          </SidepanelBackground>
          <SidepanelContainer
            key="Sidepanel-container"
            initial={{ x: 100 }}
            animate={{ x: 0 }}
            exit={{ x: 100 }}
            transition={{ type: 'tween' }}
            data-testid={sidepanelContainer}
            onClick={stopPropagation}
            withPadding={withPadding}
          >
            <FocusLock disabled={disabled} withPadding={withPadding}>
              {children}
            </FocusLock>
          </SidepanelContainer>
        </>
      )}
    </AnimatePresence>
  )
}

export default Sidepanel
