import React, { ReactElement } from 'react'
import Providers from './providers'
import Router from './Router'
import { ErrorBoundary, PageError } from './components/Error'

const App: React.FC = (): ReactElement => {
  return (
    <ErrorBoundary CustomErrorView={PageError}>
      <Providers>
        <Router />
      </Providers>
    </ErrorBoundary>
  )
}

export default App
