import Brands from '@oneplan/common/src/brands'
import { Brand } from '@oneplan/common/src/types'

interface BrandsById {
  [key: string]: Brand
}

const brandsById = Object.values(Brands).reduce(
  (acc: BrandsById, brand: Brand) => {
    acc[brand.id] = brand
    return acc
  },
  {}
)

const getBrandById = (id: string) => brandsById[id] || {}

export default getBrandById
