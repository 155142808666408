import React, { memo } from 'react'
import { loadingState } from '@oneplan/common/src/testids'
import { LoaderWrapper, Loader as LoaderIcon } from './Loader.styles'

interface LoaderProps {
  testid?: string
  className?: string
}

const Loader: React.FC<LoaderProps> = ({
  testid = loadingState,
  className,
}) => {
  return (
    <LoaderWrapper className={className}>
      <LoaderIcon data-testid={testid} />
    </LoaderWrapper>
  )
}

export default memo(Loader)
