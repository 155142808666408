import { useQuery } from 'react-query'
import { request } from 'graphql-request'
import { TagsQueryResponse } from '../../../types'
import GET_TAGS from '../../graphql/GET_TAGS'
import { endpoint, config } from './query.config'
import useYearStore from '../client/yearStore'

const KEY = 'tags'

export interface TagsVariables {
  financialYearId: string
}

const useTagsData = () => {
  const financialYearId = useYearStore(state => state.selectedFinancialYear)
  return useQuery<TagsQueryResponse, Error>(
    [KEY, { financialYearId }],
    async () => request(endpoint, GET_TAGS, { financialYearId }),
    {
      ...config,
      enabled: !!financialYearId,
    }
  )
}

export { useTagsData }
