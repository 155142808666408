import { useQuery } from 'react-query'
import { FinancialYearsResponse } from '@oneplan/common/src/types'
import { request } from 'graphql-request'
import { UserInfoResponse } from '../../../types'
import GET_USER_AND_YEARS from '../../graphql/GET_USER_AND_YEARS'
import userInfoStore from '../client/userInfo'
import useYearStore from '../client/yearStore'
import { endpoint, config } from './query.config'

interface UserYearsRespnose {
  userInfo: UserInfoResponse
  financialYears: FinancialYearsResponse
}

const KEY = 'user-years'

const useUserAndYears = () => {
  const setUserInfo = userInfoStore(store => store.setUserInfo)
  const setCurrentFinancialYear = useYearStore(
    store => store.setCurrentFinancialYear
  )
  const setSelectedFinancialYear = useYearStore(
    store => store.setSelectedFinancialYear
  )
  const setFinancialYearsList = useYearStore(
    store => store.setFinancialYearsList
  )

  return useQuery<UserYearsRespnose, Error>(
    KEY,
    async () => request(endpoint, GET_USER_AND_YEARS),
    {
      ...config,
      onSuccess: data => {
        setUserInfo(data.userInfo)
        setSelectedFinancialYear(data.financialYears.currentFinancialYear.id)
        setCurrentFinancialYear(data.financialYears.currentFinancialYear.id)
        setFinancialYearsList(data.financialYears.financialYears)
      },
    }
  )
}

export default useUserAndYears
