import styled from '@emotion/styled/macro'
import { ErrorCircle } from '@jsluna/icons'
import { FilledButton } from '@jsluna/button'
import Accordion from '../Accordion'

const FilterResponse = styled.div`
  background-color: ${({ theme }) => theme.color.whiteSmoke};
  padding: 15px;
  display: flex;
  margin: 0 auto;
  font-family: MaryAnn;
  font-size: 18px;
  font-weight: 800;
`

const FilterResponseMessage = styled.div`
  color: ${({ theme }) => theme.color.darkGrey};
  display: inline-block;
`

const FilterTags = styled(FilterResponseMessage)`
  padding-left: 5px;
  color: ${({ theme }) => theme.color.darkPrimary};
`

const ErrorCircleIcon = styled(ErrorCircle)`
  height: 20px;
  padding-right: 7px;
`

const FilterGroupSubTitle = styled.div`
  font-family: 'MaryAnn';
  font-size: 15px;
  font-weight: bold;
  margin: 10px 0 10px 15px;
`
const NoDataMessage = styled.span`
  font-family: MaryAnn;
  font-size: 16px;
`
const PrimaryButton = styled(FilledButton)``
const TagWrapper = styled.div`
  margin-top: 10px;
`

const FilterAccordion = styled(Accordion)`
  .filter-accordion_title {
    font-size: 15px;
    font-weight: bold;
  }
`

export {
  FilterResponse,
  FilterResponseMessage,
  FilterTags,
  ErrorCircleIcon,
  FilterGroupSubTitle,
  NoDataMessage,
  PrimaryButton,
  TagWrapper,
  FilterAccordion,
}
