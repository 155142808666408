import React from 'react'
import { iconFrontArrow, iconBackArrow } from '@oneplan/common/src/testids'
import { ButtonArrow, Arrow } from './NavMenu.styles'
import useScrollCalendar from '../../hooks/useScrollCalendar'
import { weekViewWidth, nonWeekViewWidth } from '../Calendar/Calendar.styles'
import useCalendarStore from '../../state/client/calendarStore'
import VisuallyHidden from '../VisuallyHidden'

interface ArrowButtonProps {
  reverse?: boolean
}

const ArrowButton: React.FC<ArrowButtonProps> = ({ reverse = false }) => {
  const weekView = useCalendarStore(state => state.weekView)
  const searchId = 'quarters'
  const fixedWidth: number = weekView
    ? weekViewWidth + 10
    : nonWeekViewWidth * 4
  const offset = reverse ? 10 * 2 - fixedWidth : fixedWidth
  const handleClick = useScrollCalendar({
    searchId,
    offset,
    type: 'scrollLeft',
  })

  return (
    <ButtonArrow
      onClick={() => handleClick()}
      type="button"
      data-testid={reverse ? iconBackArrow : iconFrontArrow}
    >
      <Arrow aria-hidden rotate={reverse ? 'true' : ''} />
      <VisuallyHidden>
        {reverse ? 'Scroll left' : 'Scroll right'}
      </VisuallyHidden>
    </ButtonArrow>
  )
}

export default ArrowButton
