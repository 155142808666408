import { gql } from 'graphql-request'

const GET_FILTER = gql`
  query FilterData($financialYearId: String!, $brandIds: [String]) {
    sections(financialYearId: $financialYearId, brandIds: $brandIds) {
      value: id
      label: title
      groupId
      children: subsections {
        value: id
        label: title
        brandId
      }
    }
  }
`

export default GET_FILTER
