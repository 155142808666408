import create from 'zustand'
import { FinancialYear } from '@oneplan/common/src/types'

type YearsList = FinancialYear[]

type State = {
  selectedFinancialYear: string
  currentFinancialYear: string
  financialYearsList: YearsList
  setSelectedFinancialYear: (year: string) => void
  setCurrentFinancialYear: (year: string) => void
  setFinancialYearsList: (years: YearsList) => void
  getFinancialYearDateRange: () => FinancialYear
  getPrevNextFinancialYear: () => { startDate: Date; endDate: Date }
}

const useYearStore = create<State>((set, get) => ({
  selectedFinancialYear: '',
  currentFinancialYear: '',
  financialYearsList: [],
  setSelectedFinancialYear: (year: string) =>
    set(() => ({ selectedFinancialYear: year })),
  setCurrentFinancialYear: (year: string) =>
    set(() => ({ currentFinancialYear: year })),
  setFinancialYearsList: (years: YearsList) =>
    set(() => ({ financialYearsList: years })),
  getFinancialYearDateRange: () =>
    get().financialYearsList.filter(
      year => year.id === get().selectedFinancialYear
    )[0],
  getPrevNextFinancialYear: () => {
    const selectedYearIndex = get().financialYearsList.findIndex(
      year => year.id === get().selectedFinancialYear
    )
    const startYearIndex = Math.max(selectedYearIndex - 1, 0)
    const endYearIndex = Math.min(
      selectedYearIndex + 1,
      get().financialYearsList.length - 1
    )
    return {
      startDate: get().financialYearsList[startYearIndex].startDate,
      endDate: get().financialYearsList[endYearIndex].endDate,
    }
  },
}))

export default useYearStore
