import React, { useState, useEffect, useCallback } from 'react'
import CalendarRowData from '@oneplan/common/src/calendar'
import { CalendarRow } from '@oneplan/common/src/types'
import {
  calendarSection,
  calendarRowHeader,
  calendarHeadWrapper,
} from '@oneplan/common/src/testids'
import { HeaderQuarters, BodyScroll } from '../../components/Calendar'
import {
  Wrapper,
  HeadWrapper,
  RowHeader,
  RowHeaderTitle,
  PinButton,
  Calendar,
} from './Calendar.styles'
import CalendarData from './CalendarData'
import pinIcon from '../../assets/pinIcon.svg'
import Tooltip from '../../components/Tooltip'
import useYearStore from '../../state/client/yearStore'
import useCalendarStore from '../../state/client/calendarStore'
import VisuallyHidden from '../../components/VisuallyHidden'

const CalendarHeader: React.FC = () => {
  const [checkedItems, setCheckedItems]: any = useState({})
  const financialYearId = useYearStore(state => state.selectedFinancialYear)
  const fixedCalendarRows = useCalendarStore(state => state.fixedCalendarRows)
  const isFixedValuesList: CalendarRow[] = [...fixedCalendarRows]
  const isCalendarCollapse = useCalendarStore(
    state => state.isCalendarCollapsed
  )
  const setFixedCalendarRows = useCalendarStore(
    state => state.setFixedCalendarRows
  )
  const toggleIsCalendarCollapsed = useCalendarStore(
    state => state.toggleIsCalendarCollapsed
  )

  const getLocalStorageData = useCallback(() => {
    const localStorageList: CalendarRow[] = []
    const getLocalStorage = JSON.parse(
      localStorage.getItem('isFixedValues') || '[]'
    )
    if (getLocalStorage.length > 0) {
      getLocalStorage.forEach((x: CalendarRow) => {
        if (!localStorageList.includes(x)) {
          localStorageList.push({
            id: x.id,
            value: x.value,
          })
          setCheckedItems((state: {}) => ({ ...state, [x.id]: true }))
        }
        return localStorageList
      })
      setFixedCalendarRows(localStorageList)
      toggleIsCalendarCollapsed()
    }
  }, [setFixedCalendarRows, toggleIsCalendarCollapsed])

  useEffect(() => {
    getLocalStorageData()
  }, [getLocalStorageData])

  const setLocalStorage = (
    event: React.MouseEvent,
    value: string,
    isChecked: boolean
  ) => {
    const { id } = event.currentTarget
    const eventObj: CalendarRow = {
      id,
      value,
    }
    setCheckedItems({
      ...checkedItems,
      [id]: !isChecked,
    })
    if (!isChecked) {
      isFixedValuesList.push(eventObj)
    } else {
      const itemIndex = isFixedValuesList.findIndex(
        (x: CalendarRow) => x.id === id
      )
      if (itemIndex > -1) {
        isFixedValuesList.splice(itemIndex, 1)
      }
    }
    if (isFixedValuesList.length > 0) {
      localStorage.setItem('isFixedValues', JSON.stringify(isFixedValuesList))
    } else {
      localStorage.removeItem('isFixedValues')
    }

    setFixedCalendarRows(isFixedValuesList)
  }

  return (
    <Calendar data-testid={calendarSection}>
      <HeaderQuarters title="Calendar" year={financialYearId} />
      <Wrapper>
        <HeadWrapper data-testid={calendarHeadWrapper}>
          {Object.values(CalendarRowData).map((row: CalendarRow) => {
            const pinText = checkedItems[row.id] ? 'Unpin' : 'Pin'

            return (
              <RowHeader
                key={row.id}
                isHidden={isCalendarCollapse && !checkedItems[row.id]}
                data-testid={calendarRowHeader}
              >
                <RowHeaderTitle>{row.value}</RowHeaderTitle>
                <Tooltip label={`${pinText} ${row.value}`}>
                  <PinButton
                    data-testid={`calendar-${row.id}-pin`}
                    id={row.id}
                    checked={!!checkedItems[row.id]}
                    onClick={e =>
                      setLocalStorage(e, row.value, !!checkedItems[row.id])
                    }
                  >
                    <img src={pinIcon} alt="" />
                    <VisuallyHidden>{`${pinText} calendar row - ${row.value}`}</VisuallyHidden>
                  </PinButton>
                </Tooltip>
              </RowHeader>
            )
          })}
        </HeadWrapper>
        <BodyScroll visibleScroll>
          <CalendarData visibleRows={checkedItems} />
        </BodyScroll>
      </Wrapper>
    </Calendar>
  )
}
export default React.memo(CalendarHeader)
