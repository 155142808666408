import styled from '@emotion/styled/macro'

const FormTitle = styled.p`
  font-size: 1.125rem;
  line-height: 1.33333;
  font-weight: 700;
  font-family: 'MaryAnn';
  color: ${({ theme }) => theme.color.lunaGrey};
  display: inline-block;
  margin: 0 0 0.5rem 0;
  padding-top: 20px;
`

const AuthorContactDiv = styled.div`
  padding-bottom: 20px;
`

export { AuthorContactDiv, FormTitle }
