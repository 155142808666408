import React from 'react'
import dayjs from 'dayjs'
import { Button } from './NavMenu.styles'
import useScrollCalendar from '../../hooks/useScrollCalendar'
import VisuallyHidden from '../VisuallyHidden'

const TodayButton: React.FC = () => {
  const currentDate = dayjs(new Date()).format('YYYY-MM-DD')
  const handleClick = useScrollCalendar({
    searchId: `calendar-cell-day-${currentDate}`,
  })

  return (
    <Button type="button" onClick={() => handleClick()}>
      <VisuallyHidden>Redirect to </VisuallyHidden>
      Today
    </Button>
  )
}

export default TodayButton
