import { Brand } from './types'

const Other: Brand = {
  id: 'brand_other',
  initials: 'O',
  title: 'Important Calendar Dates',
}

const Argos: Brand = {
  id: 'brand_argos',
  initials: 'A',
  title: 'Argos',
}

const ArgosFinancialServices: Brand = {
  id: 'brand_argos_financial_services',
  initials: 'AFS',
  title: 'Argos Financial Services',
}

const Tu: Brand = {
  id: 'brand_tu',
  initials: 'T',
  title: 'Tu',
}

const Sainsburys: Brand = {
  id: 'brand_sainsburys',
  initials: 'S',
  title: "Sainsbury's",
}

const SainsburysBank: Brand = {
  id: 'brand_sainsburys_bank',
  initials: 'SB',
  title: "Sainsbury's Bank",
}

const SainsburysEnergy: Brand = {
  id: 'brand_sainsburys_energy',
  initials: 'SE',
  title: "Sainsbury's Energy",
}

const Habitat: Brand = {
  id: 'brand_habitat',
  initials: 'H',
  title: 'Habitat',
}

const Nectar: Brand = {
  id: 'brand_nectar',
  initials: 'N',
  title: 'Nectar',
}

const Group: Brand = {
  id: 'brand_group',
  initials: 'G',
  title: 'Group',
}

export default {
  Sainsburys,
  Argos,
  ArgosFinancialServices,
  Tu,
  Habitat,
  Nectar,
  SainsburysBank,
  SainsburysEnergy,
  Group,
  Other,
}
