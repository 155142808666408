import { gql } from 'graphql-request'

const GET_TAGS = gql`
  query GetTags($financialYearId: String!) {
    tags(financialYearId: $financialYearId) {
      value: id
      label: title
    }
  }
`

export default GET_TAGS
