import React, { useEffect, useRef } from 'react'
import { ScrollSyncPane } from 'react-scroll-sync'
import { buttonCollapse } from '@oneplan/common/src/testids'
import {
  Wrapper,
  HeadWrapper,
  Title,
  BodyWrapperOuter,
  Quarters,
  Quarter,
  QuarterText,
  ButtonArrowCollapseCalendar,
  Arrow,
} from './Calendar.styles'
import quarters from '../../data/quarters'
import { HeaderQuarterProps } from '../../../types'
import useCalendarStore from '../../state/client/calendarStore'
import VisuallyHidden from '../VisuallyHidden'

const HeaderQuarters: React.FC<HeaderQuarterProps> = ({ title, year }) => {
  let isCalendarCollapse = false
  const ref = useRef<HTMLDivElement | null>(null)
  const scrollPos = useCalendarStore(state => state.scrollPos)
  isCalendarCollapse = useCalendarStore(state => state.isCalendarCollapsed)
  const weekView = useCalendarStore(state => state.weekView)
  const setScrollPos = useCalendarStore(state => state.setScrollPos)
  const toggleIsCalendarCollapsed = useCalendarStore(
    state => state.toggleIsCalendarCollapsed
  )

  useEffect(() => {
    if (scrollPos !== null) {
      setScrollPos(null)
      if (ref.current) {
        ref.current.scrollLeft = scrollPos
      }
    }
  }, [scrollPos, setScrollPos])

  return (
    <>
      <Wrapper>
        <HeadWrapper>
          <Title>{title}</Title>
          <ButtonArrowCollapseCalendar
            onClick={() => toggleIsCalendarCollapsed()}
            data-testid={buttonCollapse}
          >
            <Arrow rotate={isCalendarCollapse ? 'true' : ''} aria-hidden />
            <VisuallyHidden>
              {isCalendarCollapse ? 'Expand calendar' : 'Collapse calendar'}
            </VisuallyHidden>
          </ButtonArrowCollapseCalendar>
        </HeadWrapper>
        <BodyWrapperOuter>
          <ScrollSyncPane>
            <Quarters ref={ref} id="quarters">
              {quarters.map(({ title: quarterTitle, searchId, weeks }) => (
                <Quarter
                  id={searchId}
                  key={searchId}
                  weeks={weeks}
                  weekView={weekView}
                >
                  <QuarterText>{`${year} ${quarterTitle}`}</QuarterText>
                </Quarter>
              ))}
            </Quarters>
          </ScrollSyncPane>
        </BodyWrapperOuter>
      </Wrapper>
    </>
  )
}

export default React.memo(HeaderQuarters)
