import styled from '@emotion/styled/macro'
import { VoiceSearch } from '@jsluna/icons'

const NewFeaturesHeader = styled.div`
  font-size: 26px;
  font-weight: 800;
  line-height: 1.23;
  color: ${({ theme }) => theme.color.black};
  margin-left: 10px;
`

const NewFeaturesHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
`

const NewFeaturesTitle = styled.div<{ isChecked?: boolean }>`
  font-size: 17px;
  line-height: 1.33333;
  font-family: MaryAnn;
  color: ${({ theme }) => theme.color.black};
  font-weight: 800;
  &:after {
    content: '';
    background: ${props => !props.isChecked && props.theme.color.primary};
    border-radius: 50px;
    width: 10px;
    height: 10px;
    position: absolute;
  }
`

const NewFeaturesDate = styled.div`
  font-size: 14px;
  line-height: 1.71;
  margin: 5px 0;
  color: ${({ theme }) => theme.color.darkGrey};
`

const NewFeaturesDescription = styled.div`
  width: 508px;
  font-size: 16px;
  line-height: 1.5;
  color: ${({ theme }) => theme.color.darkGrey};
  margin-top: 20px;
`

const NewFeatureWrapper = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.color.lightGrey};
  margin-left: 50px;
  padding: 25px 0;
  position: relative;
`

const NewFeaturesIcon = styled(VoiceSearch)`
  height: ${(props: { newfeaturestyle: boolean }) =>
    props.newfeaturestyle ? '30px' : '20px'};
  margin-right: 4px;
`

const NewFeatureNotificationDot = styled.div`
  &:after {
    content: ${(props: { isChecked: boolean; newFeaturesNumber: number }) =>
      props.newFeaturesNumber === 0 ? '' : `'${props.newFeaturesNumber}'`};
    color: ${({ theme }) => theme.color.white};
    font-size: 11px;
    font-weight: 600;
    background: ${props => !props.isChecked && props.theme.color.primary};
    border-radius: 50px;
    width: 14px;
    height: 14px;
    position: absolute;
    top: 15px;
    right: 0px;
    text-align: center;
  }
`

export {
  NewFeaturesHeader,
  NewFeaturesHeaderWrapper,
  NewFeaturesTitle,
  NewFeaturesDate,
  NewFeaturesDescription,
  NewFeatureWrapper,
  NewFeaturesIcon,
  NewFeatureNotificationDot,
}
