import React from 'react'
import {
  CalendarRow,
  CalendarCell,
  CalendarCellPeriod,
} from './Calendar.styles'
import { CalendarPeriodsProps } from '../../../types'

const CalendarPeriods: React.FC<CalendarPeriodsProps> = ({
  elements,
  isHidden,
  weekView,
}) => {
  return (
    <>
      <CalendarRow isHidden={isHidden} weekView={weekView}>
        {elements.map((element, index) => {
          const { name, weekCount } = element
          const key = `${name} ${index}`
          return (
            <CalendarCellPeriod
              key={key}
              weekView={weekView}
              id={`calendar-cell-periods-${name}`}
              data-testid={name}
              weekCount={weekCount}
            >
              <CalendarCell id={`calendar-cell-${name}`}>{name}</CalendarCell>
            </CalendarCellPeriod>
          )
        })}
      </CalendarRow>
    </>
  )
}

export default React.memo(CalendarPeriods)
