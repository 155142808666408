import styled from '@emotion/styled/macro'
import { motion } from 'framer-motion'

const Wrapper = styled(motion.div)`
  min-height: 100%;
  position: relative;
  margin-right: 30px;
  position: relative;
  width: 100%;
  max-width: 280px;
  flex-shrink: 0;
`

const Toggle = styled.div`
  position: absolute;
  height: 100%;
  width: ${(props: { isOpen: boolean }) => (props.isOpen ? '30px' : '40px')};
  left: 100%;
  top: 0;
  text-align: center;
  overflow: hidden;
  padding: 10px 0;
  cursor: pointer;

  &:after {
    position: absolute;
    content: '';
    top: 40px;
    border-right-color: ${({ theme }) => theme.color.greySmoke};
    border-right-style: dashed;
    border-right-width: 1px;
    height: 100%;
    right: 50%;
  }
`

const ArrowWrapper = styled(motion.div)`
  background-color: transparent;
  transition: background-color 0.2s ease-in-out;
  width: 30px;
  height: 30px;
  padding: 5px;
  border-radius: 50%;
  margin: 0 auto;
  transform: rotate(180deg);

  &:hover {
    background-color: ${({ theme }) => theme.color.grey};
  }
`

const Content = styled(motion.div)`
  padding: 10px;
  overflow: hidden;
  width: 100%;
  height: 100%;
  white-space: nowrap;
  overflow: auto;
  max-height: 100%;
`

export { Wrapper, Toggle, ArrowWrapper, Content }
