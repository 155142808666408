import styled from '@emotion/styled/macro'

const DashboardContainer = styled.main`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100vw;
  overflow: hidden;
  flex: 1 1 auto;
`

const DashboardContent = styled.section`
  flex: 1 1 auto;
  overflow: hidden;
  padding: 5px 10px 0;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
`

export { DashboardContainer, DashboardContent }
