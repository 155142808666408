import React from 'react'
import { tagColorInput, filterAccordion } from '@oneplan/common/src/testids'
import { filterColorsId } from '@oneplan/common/src/formIds'
import { ColorFilter } from '@oneplan/common/src/types'
import { FilterColorProps } from '../../../types'
import { TagInput } from '../Form'
import { TagWrapper, FilterAccordion } from './Filter.styles'
import useFilterStore from '../../state/client/filterStore'

const FilterColor: React.FC<FilterColorProps> = ({ colors, isLoading }) => {
  const selectedColors = useFilterStore(store => store.selectedColors)
  const setSelectedColors = useFilterStore(store => store.setSelectedColors)

  const handleColorChange = (colorsSelection?: ColorFilter[]) =>
    setSelectedColors(colorsSelection || [])

  return (
    <FilterAccordion
      id={filterColorsId}
      title="Activity Colours"
      className={filterAccordion}
      testId={filterAccordion}
      titleAsLabel
    >
      <TagWrapper>
        <TagInput
          id={filterColorsId}
          name={filterColorsId}
          options={colors}
          onChange={handleColorChange}
          isLoading={isLoading}
          value={selectedColors}
          testId={tagColorInput}
        />
      </TagWrapper>
    </FilterAccordion>
  )
}

export default React.memo(FilterColor)
