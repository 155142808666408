import React from 'react'

interface UseFeatureProps {
  when: boolean
}

const UseFeature: React.FC<UseFeatureProps> = ({ when, children }) =>
  when ? <>{children}</> : null

export default UseFeature
