import useCalendarStore from '../state/client/calendarStore'

interface UseScrollCalendarParams {
  searchId: string
  offset?: number
  type?: 'offsetLeft' | 'scrollLeft'
}

const useScrollCalendar = ({
  searchId,
  offset = 0,
  type = 'offsetLeft',
}: UseScrollCalendarParams) => {
  const setScrollPos = useCalendarStore(state => state.setScrollPos)

  const handleClick = () => {
    const getElementScroll = (id: string) => document.getElementById(id)
    const scrolled = getElementScroll(searchId)
    if (scrolled) {
      setScrollPos(Math.max(0, scrolled[type] + offset - 10))
    }
  }
  return handleClick
}

export default useScrollCalendar
