import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import './luna.scss'

if (process.env.REACT_APP_MOCKS) {
  import(`./mocks/browser.js`).then(module => {
    module.worker.start()
  })
}

ReactDOM.render(<App />, document.getElementById('root'))
