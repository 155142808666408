import React from 'react'
import TodayButton from './TodayButton'
import ArrowButton from './ArrowButton'
import QuarterButton from './QuarterButton'
import YearDropdown from './YearDropdown'
import useYearStore from '../../state/client/yearStore'
import { ZoomIns, ZoomOuts } from './NavMenu.styles'

interface NavMenuProps {
  quarters: { title: string; searchId: string }[]
}

const NavMenu: React.FC<NavMenuProps> = ({ quarters }) => {
  const currentFinancialYear = useYearStore(state => state.currentFinancialYear)
  const selectedFinancialYear = useYearStore(
    state => state.selectedFinancialYear
  )
  let zoomlevel = 100
  const handleZoomIn = () => {
    zoomlevel += 10
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore: use zoom.
    document.getElementsByTagName('main')[0].style.zoom = `${zoomlevel}%`
  }
  const handleZoomOut = () => {
    zoomlevel -= 10
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore: use zoom.
    document.getElementsByTagName('main')[0].style.zoom = `${zoomlevel}%`
  }
  return (
    <>
      <YearDropdown />
      {selectedFinancialYear === currentFinancialYear && <TodayButton />}
      {quarters.map(({ title, searchId }) => (
        <QuarterButton key={searchId} searchId={searchId} title={title} />
      ))}
      <ArrowButton reverse />
      <ArrowButton />
      <ZoomIns onClick={handleZoomIn} aria-hidden />
      <ZoomOuts onClick={handleZoomOut} aria-hidden />
    </>
  )
}

export { TodayButton, QuarterButton }
export default React.memo(NavMenu)
