import React, { useState } from 'react'
import { accordion, iconPlus } from '@oneplan/common/src/testids'
import {
  Heading,
  Title,
  IconWrapper,
  Arrow,
  Content,
  PlusIcon,
  MinusIcon,
  HeadingWihtoutTitle,
} from './Accordion.styles'
import { AccordionProps } from '../../../types'

const Accordion: React.FC<AccordionProps> = ({
  children,
  id,
  title = '',
  iconName = 'ArrowUp',
  className = accordion,
  testId = accordion,
  isContentVisible = true,
  sidepanelStyle = false,
  nonKeyActivityStyle = false,
  titleAsLabel = false,
}) => {
  const [isOpen, toggle] = useState(isContentVisible)

  const handleIcon = (icon: string) => {
    switch (icon) {
      case 'plus':
        return (
          <IconWrapper>
            {isOpen ? (
              <MinusIcon aria-label="collapse" />
            ) : (
              <PlusIcon aria-label="expand" data-testid={iconPlus} />
            )}
          </IconWrapper>
        )
      default:
        return (
          <IconWrapper
            animate={{ rotate: isOpen ? 0 : 180 }}
            nonKeyActivityStyle={nonKeyActivityStyle}
          >
            <Arrow aria-label="collapse" className={`${className}_arrow`} />
          </IconWrapper>
        )
    }
  }
  return (
    <div className={className} data-testid={testId}>
      {title !== '' ? (
        <Heading
          className={`${className}_heading`}
          data-testid={`${testId}_heading`}
          onClick={() => toggle(!isOpen)}
          sidepanelStyle={sidepanelStyle}
        >
          <Title
            className={`${testId}_title`}
            data-testid={`${testId}_title-${title}`}
            as={titleAsLabel ? 'label' : undefined}
            htmlFor={titleAsLabel ? id : undefined}
          >
            {title}
          </Title>
          {handleIcon(iconName.toLowerCase())}
        </Heading>
      ) : (
        <HeadingWihtoutTitle
          className={`${className}_heading`}
          data-testid={`${testId}_heading`}
          onClick={() => toggle(!isOpen)}
        >
          {handleIcon(iconName.toLowerCase())}
        </HeadingWihtoutTitle>
      )}

      <Content
        data-testid={`${testId}_content`}
        className={`${testId}_content`}
        animate={{
          height: isOpen ? 'auto' : 0,
          opacity: isOpen ? 1 : 0,
        }}
        transition={{ duration: 0.2 }}
      >
        {children}
      </Content>
    </div>
  )
}

export default Accordion
