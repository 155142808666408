import { useQuery } from 'react-query'
import { request } from 'graphql-request'
import { SectionsQueryResponse } from '../../../types'
import GET_SECTIONS from '../../graphql/GET_SECTIONS'
import { endpoint, config } from './query.config'
import { queryClient } from '../../providers'

const KEY = 'sections'
export interface SectionVariables {
  financialYearId: string
  tagIds: string[]
  sectionIds: string[]
  subSectionIds: string[]
  brandIds: string[]
  colorIds: string[]
}

const useSectionsData = (variables: SectionVariables) => {
  return useQuery<SectionsQueryResponse, Error>(
    [KEY, { ...variables }],
    async () => request(endpoint, GET_SECTIONS, variables),
    {
      ...config,
      enabled: !!variables.financialYearId,
    }
  )
}

const invalidateSectionsData = () => queryClient.invalidateQueries(KEY)

export { useSectionsData, invalidateSectionsData }
