import styled from '@emotion/styled/macro'

const ContextMenuDiv = styled.div`
  box-shadow: 0 2px 4px 0 ${({ theme }) => theme.color.greySmoke};
  border: solid 1px ${({ theme }) => theme.color.whiteSmoke};
  background-color: ${({ theme }) => theme.color.white};
  position: absolute;
  right: 0;
  margin-top: 10px;
  width: 175px;
  z-index: 99;
  padding: 5px;
  top: 22px;
`

const ContextMenuArrow = styled.div`
  position: absolute;
  top: 0;
  left: 6px;
  height: 100%;
  width: 100%;
  &:before,
  &:after {
    content: '';
    position: absolute;
    background: ${({ theme }) => theme.color.white};
    top: 0;
    right: 0;
  }

  &:before {
    height: 20px;
    width: 20px;
    border: solid 1px ${({ theme }) => theme.color.whiteSmoke};
    box-shadow: 0 2px 4px 0 ${({ theme }) => theme.color.greySmoke};
    transform: translate(-10px, -25%) rotate(45deg);
  }
  &:after {
    height: 100%;
    width: 100%;
  }
`
const ContextMenuItem = styled.span<{ isDivider?: boolean }>`
  border-bottom: ${props => (props.isDivider ? '1px' : '0px')} solid
    ${({ theme }) => theme.color.lightGrey};
  position: relative;
  font-size: 15px;
  color: ${({ theme }) => theme.color.darkGrey};
  font-weight: normal;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 10px 0;
  button {
    &:disabled {
      opacity: 1.35;
    }
    &:hover {
      color: ${({ theme }) => theme.color.primary};
    }

    &:hover path {
      fill: ${({ theme }) => theme.color.primary};
    }
  }
`

const ContextMenuItemButton = styled.button`
  background: no-repeat;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
`

export {
  ContextMenuDiv,
  ContextMenuArrow,
  ContextMenuItem,
  ContextMenuItemButton,
}
