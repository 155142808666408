import React from 'react'
import { loginEndpoint, serviceNowUrl } from '@oneplan/config/src/uris'
import { Container, Title, Subtitle, Note, LoginButton } from './Login.styles'
import onePlanLogo from '../../assets/onePlanLogo.svg'
import * as copy from '../../i18n/copy'

const Login: React.FC = (): React.ReactElement => (
  <Container>
    <div>
      <Title>
        <img src={onePlanLogo} alt={copy.oneplan} title={copy.oneplan} />
      </Title>
      <Subtitle>{copy.loginsubtitle}</Subtitle>
      <LoginButton element="a" href={loginEndpoint}>
        Login
      </LoginButton>
      <Note>
        <>Having problems accessing OnePlan? </>
        <a href={serviceNowUrl}>Raise an issue with the Service Desk</a>
        <br />© J Sainsbury plc {new Date().getFullYear()}
      </Note>
    </div>
  </Container>
)

export default Login
