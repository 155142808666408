import styled from '@emotion/styled/macro'
import Skeleton from 'react-loading-skeleton'

const calculateSkeletonHeight = (numberOfRows: number) => {
  let skeletonHeight
  const defaultRowHeight = 48
  const activityHeight = 34
  const activityContainerMargin = 10
  const activityRowMargin = 5
  const activityRowHeight =
    activityHeight * numberOfRows +
    activityContainerMargin * 2 +
    (numberOfRows - 1) * activityRowMargin

  if (numberOfRows === 0) {
    skeletonHeight = defaultRowHeight
  } else {
    skeletonHeight = activityRowHeight
  }
  return skeletonHeight
}

const SkeletonWrapper = styled.div<{ height: number }>`
  width: 100%;
  height: ${props => `${props.height}px`};
`

const StyledSkelton = styled(Skeleton)`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.color.grey};
`

export { calculateSkeletonHeight, SkeletonWrapper, StyledSkelton }
