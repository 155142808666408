import { useEffect } from 'react'
import { fixedBodyClass } from '../styles/GlobalStyle'

const useFixedBody = (isFixed: boolean): void => {
  const { body } = document

  useEffect(() => {
    if (isFixed) body.classList.add(fixedBodyClass)
    return () => body.classList.remove(fixedBodyClass)
  }, [isFixed, body.classList])
}

export default useFixedBody
