import React from 'react'
import { ButtonQuarter } from './NavMenu.styles'
import useScrollCalendar from '../../hooks/useScrollCalendar'

interface QuarterButtonProps {
  title: string
  searchId: string
}

const QuarterButton: React.FC<QuarterButtonProps> = ({ title, searchId }) => {
  const handleClick = useScrollCalendar({ searchId })

  return (
    <ButtonQuarter onClick={() => handleClick()} type="button">
      {title}
    </ButtonQuarter>
  )
}

export default QuarterButton
