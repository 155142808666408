import React from 'react'
import { Switch } from '@jsluna/react'

interface SwitchInputProps {
  label: string
  name?: string
  testid?: string
  onChange?: Function
  checked?: boolean
  disabled?: boolean
}

const SwitchInput: React.FC<SwitchInputProps> = ({
  label,
  name,
  testid,
  onChange,
  checked = false,
  disabled = false,
}) => {
  return (
    <>
      <Switch
        name={name}
        onChange={onChange}
        checked={onChange ? checked : undefined}
        defaultChecked={onChange ? undefined : checked}
        data-testid={testid}
        disabled={disabled}
        label={label}
        hideLabel
      />
    </>
  )
}

export default SwitchInput
